
export async function instantiate(imports={}, runInitializer=true) {
    const cachedJsObjects = new WeakMap();
    // ref must be non-null
    function getCachedJsObject(ref, ifNotCached) {
        if (typeof ref !== 'object' && typeof ref !== 'function') return ifNotCached;
        const cached = cachedJsObjects.get(ref);
        if (cached !== void 0) return cached;
        cachedJsObjects.set(ref, ifNotCached);
        return ifNotCached;
    }

    const _ref_Li9za2lrby5tanM_ = imports['./skiko.mjs'];
    
    const js_code = {
        'kotlin.captureStackTrace' : () => new Error().stack,
        'kotlin.wasm.internal.throwJsError' : (message, wasmTypeName, stack) => { 
            const error = new Error();
            error.message = message;
            error.name = wasmTypeName;
            error.stack = stack;
            throw error;
             },
        'kotlin.wasm.internal.stringLength' : (x) => x.length,
        'kotlin.wasm.internal.jsExportStringToWasm' : (src, srcOffset, srcLength, dstAddr) => { 
            const mem16 = new Uint16Array(wasmExports.memory.buffer, dstAddr, srcLength);
            let arrayIndex = 0;
            let srcIndex = srcOffset;
            while (arrayIndex < srcLength) {
                mem16.set([src.charCodeAt(srcIndex)], arrayIndex);
                srcIndex++;
                arrayIndex++;
            }     
             },
        'kotlin.wasm.internal.externrefToString' : (ref) => String(ref),
        'kotlin.wasm.internal.externrefEquals' : (lhs, rhs) => lhs === rhs,
        'kotlin.wasm.internal.externrefHashCode' : 
        (() => {
        const dataView = new DataView(new ArrayBuffer(8));
        function numberHashCode(obj) {
            if ((obj | 0) === obj) {
                return obj | 0;
            } else {
                dataView.setFloat64(0, obj, true);
                return (dataView.getInt32(0, true) * 31 | 0) + dataView.getInt32(4, true) | 0;
            }
        }
        
        const hashCodes = new WeakMap();
        function getObjectHashCode(obj) {
            const res = hashCodes.get(obj);
            if (res === undefined) {
                const POW_2_32 = 4294967296;
                const hash = (Math.random() * POW_2_32) | 0;
                hashCodes.set(obj, hash);
                return hash;
            }
            return res;
        }
        
        function getStringHashCode(str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                var code  = str.charCodeAt(i);
                hash  = (hash * 31 + code) | 0;
            }
            return hash;
        }
        
        return (obj) => {
            if (obj == null) {
                return 0;
            }
            switch (typeof obj) {
                case "object":
                case "function":
                    return getObjectHashCode(obj);
                case "number":
                    return numberHashCode(obj);
                case "boolean":
                    return obj ? 1231 : 1237;
                default:
                    return getStringHashCode(String(obj)); 
            }
        }
        })(),
        'kotlin.wasm.internal.importStringFromWasm' : (address, length, prefix) => { 
            const mem16 = new Uint16Array(wasmExports.memory.buffer, address, length);
            const str = String.fromCharCode.apply(null, mem16);
            return (prefix == null) ? str : prefix + str;
             },
        'kotlin.wasm.internal.getJsEmptyString' : () => '',
        'kotlin.wasm.internal.isNullish' : (ref) => ref == null,
        'kotlin.wasm.internal.externrefToInt' : (ref) => Number(ref),
        'kotlin.wasm.internal.externrefToBoolean' : (ref) => Boolean(ref),
        'kotlin.wasm.internal.externrefToLong' : (ref) => BigInt(ref),
        'kotlin.wasm.internal.externrefToFloat' : (ref) => Number(ref),
        'kotlin.wasm.internal.externrefToDouble' : (ref) => Number(ref),
        'kotlin.wasm.internal.externrefToUByte' : (ref) => Number(ref),
        'kotlin.wasm.internal.externrefToUShort' : (ref) => Number(ref),
        'kotlin.wasm.internal.externrefToUInt' : (ref) => Number(ref),
        'kotlin.wasm.internal.externrefToULong' : (ref) => BigInt(ref),
        'kotlin.wasm.internal.intToExternref' : (x) => x,
        'kotlin.wasm.internal.getJsTrue' : () => true,
        'kotlin.wasm.internal.getJsFalse' : () => false,
        'kotlin.wasm.internal.longToExternref' : (x) => x,
        'kotlin.wasm.internal.floatToExternref' : (x) => x,
        'kotlin.wasm.internal.doubleToExternref' : (x) => x,
        'kotlin.wasm.internal.kotlinUByteToJsNumberUnsafe' : (x) => x & 0xFF,
        'kotlin.wasm.internal.kotlinUShortToJsNumberUnsafe' : (x) => x & 0xFFFF,
        'kotlin.wasm.internal.kotlinUIntToJsNumberUnsafe' : (x) => x >>> 0,
        'kotlin.wasm.internal.kotlinULongToJsBigIntUnsafe' : (x) => x & 0xFFFFFFFFFFFFFFFFn,
        'kotlin.wasm.internal.newJsArray' : () => [],
        'kotlin.wasm.internal.jsArrayPush' : (array, element) => { array.push(element); },
        'kotlin.wasm.internal.getCachedJsObject_$external_fun' : (p0, p1) => getCachedJsObject(p0, p1),
        'kotlin.js.jsCatch' : (f) => { 
            let result = null;
            try { 
                f();
            } catch (e) {
               result = e;
            }
            return result;
             },
        'kotlin.js.__convertKotlinClosureToJsClosure_(()->Unit)' : (f) => getCachedJsObject(f, () => wasmExports['__callFunction_(()->Unit)'](f, )),
        'kotlin.js.jsThrow' : (e) => { throw e; },
        'kotlin.io.printError' : (error) => console.error(error),
        'kotlin.io.printlnImpl' : (message) => console.log(message),
        'kotlin.js.jsArrayGet' : (array, index) => array[index],
        'kotlin.js.JsArray_$external_fun' : () => new Array(),
        'kotlin.js.length_$external_prop_getter' : (_this) => _this.length,
        'kotlin.js.JsArray_$external_class_instanceof' : (x) => x instanceof Array,
        'kotlin.js.JsArray_$external_class_get' : () => Array,
        'kotlin.js.JsBigInt_$external_fun' : () => new JsBigInt(),
        'kotlin.js.JsBigInt_$external_class_instanceof' : (x) => typeof x === 'bigint',
        'kotlin.js.JsBigInt_$external_class_get' : () => JsBigInt,
        'kotlin.js.JsBoolean_$external_fun' : () => new JsBoolean(),
        'kotlin.js.JsBoolean_$external_class_instanceof' : (x) => typeof x === 'boolean',
        'kotlin.js.JsBoolean_$external_class_get' : () => JsBoolean,
        'kotlin.js.stackPlaceHolder_js_code' : () => (''),
        'kotlin.js.JsError_$external_fun' : () => new Error(),
        'kotlin.js.message_$external_prop_getter' : (_this) => _this.message,
        'kotlin.js.stack_$external_prop_getter' : (_this) => _this.stack,
        'kotlin.js.JsError_$external_class_instanceof' : (x) => x instanceof Error,
        'kotlin.js.JsError_$external_class_get' : () => Error,
        'kotlin.js.JsNumber_$external_fun' : () => new JsNumber(),
        'kotlin.js.JsNumber_$external_class_instanceof' : (x) => typeof x === 'number',
        'kotlin.js.JsNumber_$external_class_get' : () => JsNumber,
        'kotlin.js.JsString_$external_fun' : () => new JsString(),
        'kotlin.js.JsString_$external_class_instanceof' : (x) => typeof x === 'string',
        'kotlin.js.JsString_$external_class_get' : () => JsString,
        'kotlin.js.Promise_$external_fun' : (p0) => new Promise(p0),
        'kotlin.js.__callJsClosure_((Js?)->Unit)' : (f, p0) => f(p0),
        'kotlin.js.__callJsClosure_((Js)->Unit)' : (f, p0) => f(p0),
        'kotlin.js.__convertKotlinClosureToJsClosure_((((Js?)->Unit),((Js)->Unit))->Unit)' : (f) => getCachedJsObject(f, (p0, p1) => wasmExports['__callFunction_((((Js?)->Unit),((Js)->Unit))->Unit)'](f, p0, p1)),
        'kotlin.js.then_$external_fun' : (_this, p0) => _this.then(p0),
        'kotlin.js.__convertKotlinClosureToJsClosure_((Js?)->Js?)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js?)->Js?)'](f, p0)),
        'kotlin.js.then_$external_fun_1' : (_this, p0, p1) => _this.then(p0, p1),
        'kotlin.js.__convertKotlinClosureToJsClosure_((Js)->Js?)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js)->Js?)'](f, p0)),
        'kotlin.js.catch_$external_fun' : (_this, p0) => _this.catch(p0),
        'kotlin.js.finally_$external_fun' : (_this, p0) => _this.finally(p0),
        'kotlin.js.Companion_$external_fun' : () => new Promise(),
        'kotlin.js.all_$external_fun' : (_this, p0) => _this.all(p0),
        'kotlin.js.race_$external_fun' : (_this, p0) => _this.race(p0),
        'kotlin.js.reject_$external_fun' : (_this, p0) => _this.reject(p0),
        'kotlin.js.resolve_$external_fun' : (_this, p0) => _this.resolve(p0),
        'kotlin.js.resolve_$external_fun_1' : (_this, p0) => _this.resolve(p0),
        'kotlin.js.Companion_$external_object_getInstance' : () => Promise,
        'kotlin.js.Companion_$external_class_instanceof' : (x) => x instanceof Promise,
        'kotlin.js.Companion_$external_class_get' : () => Promise,
        'kotlin.js.Promise_$external_class_instanceof' : (x) => x instanceof Promise,
        'kotlin.js.Promise_$external_class_get' : () => Promise,
        'kotlin.random.initialSeed' : () => ((Math.random() * Math.pow(2, 32)) | 0),
        'kotlin.wasm.internal.getJsClassName' : (jsKlass) => jsKlass.name,
        'kotlin.wasm.internal.instanceOf' : (ref, jsKlass) => ref instanceof jsKlass,
        'kotlin.wasm.internal.getConstructor' : (obj) => obj.constructor,
        'kotlin.time.toFixed' : (value, decimals) => value.toFixed(decimals),
        'kotlin.time.toPrecision' : (value, decimals) => value.toPrecision(decimals),
        'kotlin.time.tryGetPerformance' : () => typeof globalThis !== 'undefined' && typeof globalThis.performance !== 'undefined' ? globalThis.performance : null,
        'kotlin.time.getPerformanceNow' : (performance) => performance.now(),
        'kotlin.time.dateNow' : () => Date.now(),
        'kotlinx.coroutines.tryGetProcess' : () => (typeof(process) !== 'undefined' && typeof(process.nextTick) === 'function') ? process : null,
        'kotlinx.coroutines.tryGetWindow' : () => (typeof(window) !== 'undefined' && window != null && typeof(window.addEventListener) === 'function') ? window : null,
        'kotlinx.coroutines.nextTick_$external_fun' : (_this, p0) => _this.nextTick(p0),
        'kotlinx.coroutines.error_$external_fun' : (_this, p0) => _this.error(p0),
        'kotlinx.coroutines.console_$external_prop_getter' : () => console,
        'kotlinx.coroutines.createScheduleMessagePoster' : (process) => () => Promise.resolve(0).then(process),
        'kotlinx.coroutines.__callJsClosure_(()->Unit)' : (f, ) => f(),
        'kotlinx.coroutines.createRescheduleMessagePoster' : (window) => () => window.postMessage('dispatchCoroutine', '*'),
        'kotlinx.coroutines.subscribeToWindowMessages' : (window, process) => {
            const handler = (event) => {
                if (event.source == window && event.data == 'dispatchCoroutine') {
                    event.stopPropagation();
                    process();
                }
            }
            window.addEventListener('message', handler, true);
        },
        'kotlinx.coroutines.setTimeout' : (window, handler, timeout) => window.setTimeout(handler, timeout),
        'kotlinx.coroutines.clearTimeout' : (handle) => { if (typeof clearTimeout !== 'undefined') clearTimeout(handle); },
        'kotlinx.coroutines.setTimeout_$external_fun' : (p0, p1) => setTimeout(p0, p1),
        'kotlinx.browser.window_$external_prop_getter' : () => window,
        'kotlinx.browser.document_$external_prop_getter' : () => document,
        'org.w3c.dom.length_$external_prop_getter' : (_this) => _this.length,
        'org.w3c.dom.item_$external_fun' : (_this, p0) => _this.item(p0),
        'org.khronos.webgl.Float32Array_$external_fun' : (p0) => new Float32Array(p0),
        'org.khronos.webgl.Float32Array_$external_fun_1' : (p0) => new Float32Array(p0),
        'org.khronos.webgl.Float32Array_$external_fun_2' : (p0) => new Float32Array(p0),
        'org.khronos.webgl.Float32Array_$external_fun_3' : (p0, p1, p2, isDefault0, isDefault1) => new Float32Array(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.khronos.webgl.length_$external_prop_getter' : (_this) => _this.length,
        'org.khronos.webgl.buffer_$external_prop_getter' : (_this) => _this.buffer,
        'org.khronos.webgl.byteOffset_$external_prop_getter' : (_this) => _this.byteOffset,
        'org.khronos.webgl.byteLength_$external_prop_getter' : (_this) => _this.byteLength,
        'org.khronos.webgl.set_$external_fun' : (_this, p0, p1, isDefault0) => _this.set(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.set_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.set(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.subarray_$external_fun' : (_this, p0, p1) => _this.subarray(p0, p1),
        'org.khronos.webgl.Companion_$external_fun' : () => new Float32Array(),
        'org.khronos.webgl.BYTES_PER_ELEMENT_$external_prop_getter' : (_this) => _this.BYTES_PER_ELEMENT,
        'org.khronos.webgl.Companion_$external_object_getInstance' : () => Float32Array,
        'org.khronos.webgl.Companion_$external_class_instanceof' : (x) => x instanceof Float32Array,
        'org.khronos.webgl.Companion_$external_class_get' : () => Float32Array,
        'org.khronos.webgl.Float32Array_$external_fun_4' : () => new Float32Array(),
        'org.khronos.webgl.Float32Array_$external_class_instanceof' : (x) => x instanceof Float32Array,
        'org.khronos.webgl.Float32Array_$external_class_get' : () => Float32Array,
        'org.khronos.webgl.Float64Array_$external_fun' : (p0) => new Float64Array(p0),
        'org.khronos.webgl.Float64Array_$external_fun_1' : (p0) => new Float64Array(p0),
        'org.khronos.webgl.Float64Array_$external_fun_2' : (p0) => new Float64Array(p0),
        'org.khronos.webgl.Float64Array_$external_fun_3' : (p0, p1, p2, isDefault0, isDefault1) => new Float64Array(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.khronos.webgl.length_$external_prop_getter_1' : (_this) => _this.length,
        'org.khronos.webgl.buffer_$external_prop_getter_1' : (_this) => _this.buffer,
        'org.khronos.webgl.byteOffset_$external_prop_getter_1' : (_this) => _this.byteOffset,
        'org.khronos.webgl.byteLength_$external_prop_getter_1' : (_this) => _this.byteLength,
        'org.khronos.webgl.set_$external_fun_2' : (_this, p0, p1, isDefault0) => _this.set(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.set_$external_fun_3' : (_this, p0, p1, isDefault0) => _this.set(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.subarray_$external_fun_1' : (_this, p0, p1) => _this.subarray(p0, p1),
        'org.khronos.webgl.Companion_$external_fun_1' : () => new Float64Array(),
        'org.khronos.webgl.BYTES_PER_ELEMENT_$external_prop_getter_1' : (_this) => _this.BYTES_PER_ELEMENT,
        'org.khronos.webgl.Companion_$external_object_getInstance_1' : () => Float64Array,
        'org.khronos.webgl.Companion_$external_class_instanceof_1' : (x) => x instanceof Float64Array,
        'org.khronos.webgl.Companion_$external_class_get_1' : () => Float64Array,
        'org.khronos.webgl.Float64Array_$external_fun_4' : () => new Float64Array(),
        'org.khronos.webgl.Float64Array_$external_class_instanceof' : (x) => x instanceof Float64Array,
        'org.khronos.webgl.Float64Array_$external_class_get' : () => Float64Array,
        'org.khronos.webgl.ArrayBuffer_$external_fun' : (p0) => new ArrayBuffer(p0),
        'org.khronos.webgl.byteLength_$external_prop_getter_2' : (_this) => _this.byteLength,
        'org.khronos.webgl.slice_$external_fun' : (_this, p0, p1, isDefault0) => _this.slice(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.Companion_$external_fun_2' : () => new ArrayBuffer(),
        'org.khronos.webgl.isView_$external_fun' : (_this, p0) => _this.isView(p0),
        'org.khronos.webgl.Companion_$external_object_getInstance_2' : () => ArrayBuffer,
        'org.khronos.webgl.Companion_$external_class_instanceof_2' : (x) => x instanceof ArrayBuffer,
        'org.khronos.webgl.Companion_$external_class_get_2' : () => ArrayBuffer,
        'org.khronos.webgl.ArrayBuffer_$external_class_instanceof' : (x) => x instanceof ArrayBuffer,
        'org.khronos.webgl.ArrayBuffer_$external_class_get' : () => ArrayBuffer,
        'org.khronos.webgl.buffer_$external_prop_getter_2' : (_this) => _this.buffer,
        'org.khronos.webgl.byteOffset_$external_prop_getter_2' : (_this) => _this.byteOffset,
        'org.khronos.webgl.byteLength_$external_prop_getter_3' : (_this) => _this.byteLength,
        'org.khronos.webgl.Int8Array_$external_fun' : (p0) => new Int8Array(p0),
        'org.khronos.webgl.Int8Array_$external_fun_1' : (p0) => new Int8Array(p0),
        'org.khronos.webgl.Int8Array_$external_fun_2' : (p0) => new Int8Array(p0),
        'org.khronos.webgl.Int8Array_$external_fun_3' : (p0, p1, p2, isDefault0, isDefault1) => new Int8Array(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.khronos.webgl.length_$external_prop_getter_2' : (_this) => _this.length,
        'org.khronos.webgl.buffer_$external_prop_getter_3' : (_this) => _this.buffer,
        'org.khronos.webgl.byteOffset_$external_prop_getter_3' : (_this) => _this.byteOffset,
        'org.khronos.webgl.byteLength_$external_prop_getter_4' : (_this) => _this.byteLength,
        'org.khronos.webgl.set_$external_fun_4' : (_this, p0, p1, isDefault0) => _this.set(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.set_$external_fun_5' : (_this, p0, p1, isDefault0) => _this.set(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.subarray_$external_fun_2' : (_this, p0, p1) => _this.subarray(p0, p1),
        'org.khronos.webgl.Companion_$external_fun_3' : () => new Int8Array(),
        'org.khronos.webgl.BYTES_PER_ELEMENT_$external_prop_getter_2' : (_this) => _this.BYTES_PER_ELEMENT,
        'org.khronos.webgl.Companion_$external_object_getInstance_3' : () => Int8Array,
        'org.khronos.webgl.Companion_$external_class_instanceof_3' : (x) => x instanceof Int8Array,
        'org.khronos.webgl.Companion_$external_class_get_3' : () => Int8Array,
        'org.khronos.webgl.Int8Array_$external_fun_4' : () => new Int8Array(),
        'org.khronos.webgl.Int8Array_$external_class_instanceof' : (x) => x instanceof Int8Array,
        'org.khronos.webgl.Int8Array_$external_class_get' : () => Int8Array,
        'org.w3c.dom.clipboard.ClipboardEvent_$external_fun' : (p0, p1, isDefault0) => new ClipboardEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.clipboard.clipboardData_$external_prop_getter' : (_this) => _this.clipboardData,
        'org.w3c.dom.clipboard.Companion_$external_fun' : () => new ClipboardEvent(),
        'org.w3c.dom.clipboard.NONE_$external_prop_getter' : (_this) => _this.NONE,
        'org.w3c.dom.clipboard.CAPTURING_PHASE_$external_prop_getter' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.clipboard.AT_TARGET_$external_prop_getter' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.clipboard.BUBBLING_PHASE_$external_prop_getter' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.clipboard.Companion_$external_object_getInstance' : () => ClipboardEvent,
        'org.w3c.dom.clipboard.Companion_$external_class_instanceof' : (x) => x instanceof ClipboardEvent,
        'org.w3c.dom.clipboard.Companion_$external_class_get' : () => ClipboardEvent,
        'org.w3c.dom.clipboard.ClipboardEvent_$external_class_instanceof' : (x) => x instanceof ClipboardEvent,
        'org.w3c.dom.clipboard.ClipboardEvent_$external_class_get' : () => ClipboardEvent,
        'org.w3c.dom.clipboard.Clipboard_$external_fun' : () => new Clipboard(),
        'org.w3c.dom.clipboard.read_$external_fun' : (_this, ) => _this.read(),
        'org.w3c.dom.clipboard.readText_$external_fun' : (_this, ) => _this.readText(),
        'org.w3c.dom.clipboard.write_$external_fun' : (_this, p0) => _this.write(p0),
        'org.w3c.dom.clipboard.writeText_$external_fun' : (_this, p0) => _this.writeText(p0),
        'org.w3c.dom.clipboard.Clipboard_$external_class_instanceof' : (x) => x instanceof Clipboard,
        'org.w3c.dom.clipboard.Clipboard_$external_class_get' : () => Clipboard,
        'org.w3c.dom.clipboard.clipboardData_$external_prop_getter_1' : (_this) => _this.clipboardData,
        'org.w3c.dom.clipboard.clipboardData_$external_prop_setter' : (_this, v) => _this.clipboardData = v,
        'org.w3c.dom.css.CSSStyleDeclaration_$external_fun' : () => new CSSStyleDeclaration(),
        'org.w3c.dom.css.cssText_$external_prop_getter' : (_this) => _this.cssText,
        'org.w3c.dom.css.cssText_$external_prop_setter' : (_this, v) => _this.cssText = v,
        'org.w3c.dom.css.parentRule_$external_prop_getter' : (_this) => _this.parentRule,
        'org.w3c.dom.css.cssFloat_$external_prop_getter' : (_this) => _this.cssFloat,
        'org.w3c.dom.css.cssFloat_$external_prop_setter' : (_this, v) => _this.cssFloat = v,
        'org.w3c.dom.css.alignContent_$external_prop_getter' : (_this) => _this.alignContent,
        'org.w3c.dom.css.alignContent_$external_prop_setter' : (_this, v) => _this.alignContent = v,
        'org.w3c.dom.css.alignItems_$external_prop_getter' : (_this) => _this.alignItems,
        'org.w3c.dom.css.alignItems_$external_prop_setter' : (_this, v) => _this.alignItems = v,
        'org.w3c.dom.css.alignSelf_$external_prop_getter' : (_this) => _this.alignSelf,
        'org.w3c.dom.css.alignSelf_$external_prop_setter' : (_this, v) => _this.alignSelf = v,
        'org.w3c.dom.css.animation_$external_prop_getter' : (_this) => _this.animation,
        'org.w3c.dom.css.animation_$external_prop_setter' : (_this, v) => _this.animation = v,
        'org.w3c.dom.css.animationDelay_$external_prop_getter' : (_this) => _this.animationDelay,
        'org.w3c.dom.css.animationDelay_$external_prop_setter' : (_this, v) => _this.animationDelay = v,
        'org.w3c.dom.css.animationDirection_$external_prop_getter' : (_this) => _this.animationDirection,
        'org.w3c.dom.css.animationDirection_$external_prop_setter' : (_this, v) => _this.animationDirection = v,
        'org.w3c.dom.css.animationDuration_$external_prop_getter' : (_this) => _this.animationDuration,
        'org.w3c.dom.css.animationDuration_$external_prop_setter' : (_this, v) => _this.animationDuration = v,
        'org.w3c.dom.css.animationFillMode_$external_prop_getter' : (_this) => _this.animationFillMode,
        'org.w3c.dom.css.animationFillMode_$external_prop_setter' : (_this, v) => _this.animationFillMode = v,
        'org.w3c.dom.css.animationIterationCount_$external_prop_getter' : (_this) => _this.animationIterationCount,
        'org.w3c.dom.css.animationIterationCount_$external_prop_setter' : (_this, v) => _this.animationIterationCount = v,
        'org.w3c.dom.css.animationName_$external_prop_getter' : (_this) => _this.animationName,
        'org.w3c.dom.css.animationName_$external_prop_setter' : (_this, v) => _this.animationName = v,
        'org.w3c.dom.css.animationPlayState_$external_prop_getter' : (_this) => _this.animationPlayState,
        'org.w3c.dom.css.animationPlayState_$external_prop_setter' : (_this, v) => _this.animationPlayState = v,
        'org.w3c.dom.css.animationTimingFunction_$external_prop_getter' : (_this) => _this.animationTimingFunction,
        'org.w3c.dom.css.animationTimingFunction_$external_prop_setter' : (_this, v) => _this.animationTimingFunction = v,
        'org.w3c.dom.css.backfaceVisibility_$external_prop_getter' : (_this) => _this.backfaceVisibility,
        'org.w3c.dom.css.backfaceVisibility_$external_prop_setter' : (_this, v) => _this.backfaceVisibility = v,
        'org.w3c.dom.css.background_$external_prop_getter' : (_this) => _this.background,
        'org.w3c.dom.css.background_$external_prop_setter' : (_this, v) => _this.background = v,
        'org.w3c.dom.css.backgroundAttachment_$external_prop_getter' : (_this) => _this.backgroundAttachment,
        'org.w3c.dom.css.backgroundAttachment_$external_prop_setter' : (_this, v) => _this.backgroundAttachment = v,
        'org.w3c.dom.css.backgroundClip_$external_prop_getter' : (_this) => _this.backgroundClip,
        'org.w3c.dom.css.backgroundClip_$external_prop_setter' : (_this, v) => _this.backgroundClip = v,
        'org.w3c.dom.css.backgroundColor_$external_prop_getter' : (_this) => _this.backgroundColor,
        'org.w3c.dom.css.backgroundColor_$external_prop_setter' : (_this, v) => _this.backgroundColor = v,
        'org.w3c.dom.css.backgroundImage_$external_prop_getter' : (_this) => _this.backgroundImage,
        'org.w3c.dom.css.backgroundImage_$external_prop_setter' : (_this, v) => _this.backgroundImage = v,
        'org.w3c.dom.css.backgroundOrigin_$external_prop_getter' : (_this) => _this.backgroundOrigin,
        'org.w3c.dom.css.backgroundOrigin_$external_prop_setter' : (_this, v) => _this.backgroundOrigin = v,
        'org.w3c.dom.css.backgroundPosition_$external_prop_getter' : (_this) => _this.backgroundPosition,
        'org.w3c.dom.css.backgroundPosition_$external_prop_setter' : (_this, v) => _this.backgroundPosition = v,
        'org.w3c.dom.css.backgroundRepeat_$external_prop_getter' : (_this) => _this.backgroundRepeat,
        'org.w3c.dom.css.backgroundRepeat_$external_prop_setter' : (_this, v) => _this.backgroundRepeat = v,
        'org.w3c.dom.css.backgroundSize_$external_prop_getter' : (_this) => _this.backgroundSize,
        'org.w3c.dom.css.backgroundSize_$external_prop_setter' : (_this, v) => _this.backgroundSize = v,
        'org.w3c.dom.css.border_$external_prop_getter' : (_this) => _this.border,
        'org.w3c.dom.css.border_$external_prop_setter' : (_this, v) => _this.border = v,
        'org.w3c.dom.css.borderBottom_$external_prop_getter' : (_this) => _this.borderBottom,
        'org.w3c.dom.css.borderBottom_$external_prop_setter' : (_this, v) => _this.borderBottom = v,
        'org.w3c.dom.css.borderBottomColor_$external_prop_getter' : (_this) => _this.borderBottomColor,
        'org.w3c.dom.css.borderBottomColor_$external_prop_setter' : (_this, v) => _this.borderBottomColor = v,
        'org.w3c.dom.css.borderBottomLeftRadius_$external_prop_getter' : (_this) => _this.borderBottomLeftRadius,
        'org.w3c.dom.css.borderBottomLeftRadius_$external_prop_setter' : (_this, v) => _this.borderBottomLeftRadius = v,
        'org.w3c.dom.css.borderBottomRightRadius_$external_prop_getter' : (_this) => _this.borderBottomRightRadius,
        'org.w3c.dom.css.borderBottomRightRadius_$external_prop_setter' : (_this, v) => _this.borderBottomRightRadius = v,
        'org.w3c.dom.css.borderBottomStyle_$external_prop_getter' : (_this) => _this.borderBottomStyle,
        'org.w3c.dom.css.borderBottomStyle_$external_prop_setter' : (_this, v) => _this.borderBottomStyle = v,
        'org.w3c.dom.css.borderBottomWidth_$external_prop_getter' : (_this) => _this.borderBottomWidth,
        'org.w3c.dom.css.borderBottomWidth_$external_prop_setter' : (_this, v) => _this.borderBottomWidth = v,
        'org.w3c.dom.css.borderCollapse_$external_prop_getter' : (_this) => _this.borderCollapse,
        'org.w3c.dom.css.borderCollapse_$external_prop_setter' : (_this, v) => _this.borderCollapse = v,
        'org.w3c.dom.css.borderColor_$external_prop_getter' : (_this) => _this.borderColor,
        'org.w3c.dom.css.borderColor_$external_prop_setter' : (_this, v) => _this.borderColor = v,
        'org.w3c.dom.css.borderImage_$external_prop_getter' : (_this) => _this.borderImage,
        'org.w3c.dom.css.borderImage_$external_prop_setter' : (_this, v) => _this.borderImage = v,
        'org.w3c.dom.css.borderImageOutset_$external_prop_getter' : (_this) => _this.borderImageOutset,
        'org.w3c.dom.css.borderImageOutset_$external_prop_setter' : (_this, v) => _this.borderImageOutset = v,
        'org.w3c.dom.css.borderImageRepeat_$external_prop_getter' : (_this) => _this.borderImageRepeat,
        'org.w3c.dom.css.borderImageRepeat_$external_prop_setter' : (_this, v) => _this.borderImageRepeat = v,
        'org.w3c.dom.css.borderImageSlice_$external_prop_getter' : (_this) => _this.borderImageSlice,
        'org.w3c.dom.css.borderImageSlice_$external_prop_setter' : (_this, v) => _this.borderImageSlice = v,
        'org.w3c.dom.css.borderImageSource_$external_prop_getter' : (_this) => _this.borderImageSource,
        'org.w3c.dom.css.borderImageSource_$external_prop_setter' : (_this, v) => _this.borderImageSource = v,
        'org.w3c.dom.css.borderImageWidth_$external_prop_getter' : (_this) => _this.borderImageWidth,
        'org.w3c.dom.css.borderImageWidth_$external_prop_setter' : (_this, v) => _this.borderImageWidth = v,
        'org.w3c.dom.css.borderLeft_$external_prop_getter' : (_this) => _this.borderLeft,
        'org.w3c.dom.css.borderLeft_$external_prop_setter' : (_this, v) => _this.borderLeft = v,
        'org.w3c.dom.css.borderLeftColor_$external_prop_getter' : (_this) => _this.borderLeftColor,
        'org.w3c.dom.css.borderLeftColor_$external_prop_setter' : (_this, v) => _this.borderLeftColor = v,
        'org.w3c.dom.css.borderLeftStyle_$external_prop_getter' : (_this) => _this.borderLeftStyle,
        'org.w3c.dom.css.borderLeftStyle_$external_prop_setter' : (_this, v) => _this.borderLeftStyle = v,
        'org.w3c.dom.css.borderLeftWidth_$external_prop_getter' : (_this) => _this.borderLeftWidth,
        'org.w3c.dom.css.borderLeftWidth_$external_prop_setter' : (_this, v) => _this.borderLeftWidth = v,
        'org.w3c.dom.css.borderRadius_$external_prop_getter' : (_this) => _this.borderRadius,
        'org.w3c.dom.css.borderRadius_$external_prop_setter' : (_this, v) => _this.borderRadius = v,
        'org.w3c.dom.css.borderRight_$external_prop_getter' : (_this) => _this.borderRight,
        'org.w3c.dom.css.borderRight_$external_prop_setter' : (_this, v) => _this.borderRight = v,
        'org.w3c.dom.css.borderRightColor_$external_prop_getter' : (_this) => _this.borderRightColor,
        'org.w3c.dom.css.borderRightColor_$external_prop_setter' : (_this, v) => _this.borderRightColor = v,
        'org.w3c.dom.css.borderRightStyle_$external_prop_getter' : (_this) => _this.borderRightStyle,
        'org.w3c.dom.css.borderRightStyle_$external_prop_setter' : (_this, v) => _this.borderRightStyle = v,
        'org.w3c.dom.css.borderRightWidth_$external_prop_getter' : (_this) => _this.borderRightWidth,
        'org.w3c.dom.css.borderRightWidth_$external_prop_setter' : (_this, v) => _this.borderRightWidth = v,
        'org.w3c.dom.css.borderSpacing_$external_prop_getter' : (_this) => _this.borderSpacing,
        'org.w3c.dom.css.borderSpacing_$external_prop_setter' : (_this, v) => _this.borderSpacing = v,
        'org.w3c.dom.css.borderStyle_$external_prop_getter' : (_this) => _this.borderStyle,
        'org.w3c.dom.css.borderStyle_$external_prop_setter' : (_this, v) => _this.borderStyle = v,
        'org.w3c.dom.css.borderTop_$external_prop_getter' : (_this) => _this.borderTop,
        'org.w3c.dom.css.borderTop_$external_prop_setter' : (_this, v) => _this.borderTop = v,
        'org.w3c.dom.css.borderTopColor_$external_prop_getter' : (_this) => _this.borderTopColor,
        'org.w3c.dom.css.borderTopColor_$external_prop_setter' : (_this, v) => _this.borderTopColor = v,
        'org.w3c.dom.css.borderTopLeftRadius_$external_prop_getter' : (_this) => _this.borderTopLeftRadius,
        'org.w3c.dom.css.borderTopLeftRadius_$external_prop_setter' : (_this, v) => _this.borderTopLeftRadius = v,
        'org.w3c.dom.css.borderTopRightRadius_$external_prop_getter' : (_this) => _this.borderTopRightRadius,
        'org.w3c.dom.css.borderTopRightRadius_$external_prop_setter' : (_this, v) => _this.borderTopRightRadius = v,
        'org.w3c.dom.css.borderTopStyle_$external_prop_getter' : (_this) => _this.borderTopStyle,
        'org.w3c.dom.css.borderTopStyle_$external_prop_setter' : (_this, v) => _this.borderTopStyle = v,
        'org.w3c.dom.css.borderTopWidth_$external_prop_getter' : (_this) => _this.borderTopWidth,
        'org.w3c.dom.css.borderTopWidth_$external_prop_setter' : (_this, v) => _this.borderTopWidth = v,
        'org.w3c.dom.css.borderWidth_$external_prop_getter' : (_this) => _this.borderWidth,
        'org.w3c.dom.css.borderWidth_$external_prop_setter' : (_this, v) => _this.borderWidth = v,
        'org.w3c.dom.css.bottom_$external_prop_getter' : (_this) => _this.bottom,
        'org.w3c.dom.css.bottom_$external_prop_setter' : (_this, v) => _this.bottom = v,
        'org.w3c.dom.css.boxDecorationBreak_$external_prop_getter' : (_this) => _this.boxDecorationBreak,
        'org.w3c.dom.css.boxDecorationBreak_$external_prop_setter' : (_this, v) => _this.boxDecorationBreak = v,
        'org.w3c.dom.css.boxShadow_$external_prop_getter' : (_this) => _this.boxShadow,
        'org.w3c.dom.css.boxShadow_$external_prop_setter' : (_this, v) => _this.boxShadow = v,
        'org.w3c.dom.css.boxSizing_$external_prop_getter' : (_this) => _this.boxSizing,
        'org.w3c.dom.css.boxSizing_$external_prop_setter' : (_this, v) => _this.boxSizing = v,
        'org.w3c.dom.css.breakAfter_$external_prop_getter' : (_this) => _this.breakAfter,
        'org.w3c.dom.css.breakAfter_$external_prop_setter' : (_this, v) => _this.breakAfter = v,
        'org.w3c.dom.css.breakBefore_$external_prop_getter' : (_this) => _this.breakBefore,
        'org.w3c.dom.css.breakBefore_$external_prop_setter' : (_this, v) => _this.breakBefore = v,
        'org.w3c.dom.css.breakInside_$external_prop_getter' : (_this) => _this.breakInside,
        'org.w3c.dom.css.breakInside_$external_prop_setter' : (_this, v) => _this.breakInside = v,
        'org.w3c.dom.css.captionSide_$external_prop_getter' : (_this) => _this.captionSide,
        'org.w3c.dom.css.captionSide_$external_prop_setter' : (_this, v) => _this.captionSide = v,
        'org.w3c.dom.css.clear_$external_prop_getter' : (_this) => _this.clear,
        'org.w3c.dom.css.clear_$external_prop_setter' : (_this, v) => _this.clear = v,
        'org.w3c.dom.css.clip_$external_prop_getter' : (_this) => _this.clip,
        'org.w3c.dom.css.clip_$external_prop_setter' : (_this, v) => _this.clip = v,
        'org.w3c.dom.css.color_$external_prop_getter' : (_this) => _this.color,
        'org.w3c.dom.css.color_$external_prop_setter' : (_this, v) => _this.color = v,
        'org.w3c.dom.css.columnCount_$external_prop_getter' : (_this) => _this.columnCount,
        'org.w3c.dom.css.columnCount_$external_prop_setter' : (_this, v) => _this.columnCount = v,
        'org.w3c.dom.css.columnFill_$external_prop_getter' : (_this) => _this.columnFill,
        'org.w3c.dom.css.columnFill_$external_prop_setter' : (_this, v) => _this.columnFill = v,
        'org.w3c.dom.css.columnGap_$external_prop_getter' : (_this) => _this.columnGap,
        'org.w3c.dom.css.columnGap_$external_prop_setter' : (_this, v) => _this.columnGap = v,
        'org.w3c.dom.css.columnRule_$external_prop_getter' : (_this) => _this.columnRule,
        'org.w3c.dom.css.columnRule_$external_prop_setter' : (_this, v) => _this.columnRule = v,
        'org.w3c.dom.css.columnRuleColor_$external_prop_getter' : (_this) => _this.columnRuleColor,
        'org.w3c.dom.css.columnRuleColor_$external_prop_setter' : (_this, v) => _this.columnRuleColor = v,
        'org.w3c.dom.css.columnRuleStyle_$external_prop_getter' : (_this) => _this.columnRuleStyle,
        'org.w3c.dom.css.columnRuleStyle_$external_prop_setter' : (_this, v) => _this.columnRuleStyle = v,
        'org.w3c.dom.css.columnRuleWidth_$external_prop_getter' : (_this) => _this.columnRuleWidth,
        'org.w3c.dom.css.columnRuleWidth_$external_prop_setter' : (_this, v) => _this.columnRuleWidth = v,
        'org.w3c.dom.css.columnSpan_$external_prop_getter' : (_this) => _this.columnSpan,
        'org.w3c.dom.css.columnSpan_$external_prop_setter' : (_this, v) => _this.columnSpan = v,
        'org.w3c.dom.css.columnWidth_$external_prop_getter' : (_this) => _this.columnWidth,
        'org.w3c.dom.css.columnWidth_$external_prop_setter' : (_this, v) => _this.columnWidth = v,
        'org.w3c.dom.css.columns_$external_prop_getter' : (_this) => _this.columns,
        'org.w3c.dom.css.columns_$external_prop_setter' : (_this, v) => _this.columns = v,
        'org.w3c.dom.css.content_$external_prop_getter' : (_this) => _this.content,
        'org.w3c.dom.css.content_$external_prop_setter' : (_this, v) => _this.content = v,
        'org.w3c.dom.css.counterIncrement_$external_prop_getter' : (_this) => _this.counterIncrement,
        'org.w3c.dom.css.counterIncrement_$external_prop_setter' : (_this, v) => _this.counterIncrement = v,
        'org.w3c.dom.css.counterReset_$external_prop_getter' : (_this) => _this.counterReset,
        'org.w3c.dom.css.counterReset_$external_prop_setter' : (_this, v) => _this.counterReset = v,
        'org.w3c.dom.css.cursor_$external_prop_getter' : (_this) => _this.cursor,
        'org.w3c.dom.css.cursor_$external_prop_setter' : (_this, v) => _this.cursor = v,
        'org.w3c.dom.css.direction_$external_prop_getter' : (_this) => _this.direction,
        'org.w3c.dom.css.direction_$external_prop_setter' : (_this, v) => _this.direction = v,
        'org.w3c.dom.css.display_$external_prop_getter' : (_this) => _this.display,
        'org.w3c.dom.css.display_$external_prop_setter' : (_this, v) => _this.display = v,
        'org.w3c.dom.css.emptyCells_$external_prop_getter' : (_this) => _this.emptyCells,
        'org.w3c.dom.css.emptyCells_$external_prop_setter' : (_this, v) => _this.emptyCells = v,
        'org.w3c.dom.css.filter_$external_prop_getter' : (_this) => _this.filter,
        'org.w3c.dom.css.filter_$external_prop_setter' : (_this, v) => _this.filter = v,
        'org.w3c.dom.css.flex_$external_prop_getter' : (_this) => _this.flex,
        'org.w3c.dom.css.flex_$external_prop_setter' : (_this, v) => _this.flex = v,
        'org.w3c.dom.css.flexBasis_$external_prop_getter' : (_this) => _this.flexBasis,
        'org.w3c.dom.css.flexBasis_$external_prop_setter' : (_this, v) => _this.flexBasis = v,
        'org.w3c.dom.css.flexDirection_$external_prop_getter' : (_this) => _this.flexDirection,
        'org.w3c.dom.css.flexDirection_$external_prop_setter' : (_this, v) => _this.flexDirection = v,
        'org.w3c.dom.css.flexFlow_$external_prop_getter' : (_this) => _this.flexFlow,
        'org.w3c.dom.css.flexFlow_$external_prop_setter' : (_this, v) => _this.flexFlow = v,
        'org.w3c.dom.css.flexGrow_$external_prop_getter' : (_this) => _this.flexGrow,
        'org.w3c.dom.css.flexGrow_$external_prop_setter' : (_this, v) => _this.flexGrow = v,
        'org.w3c.dom.css.flexShrink_$external_prop_getter' : (_this) => _this.flexShrink,
        'org.w3c.dom.css.flexShrink_$external_prop_setter' : (_this, v) => _this.flexShrink = v,
        'org.w3c.dom.css.flexWrap_$external_prop_getter' : (_this) => _this.flexWrap,
        'org.w3c.dom.css.flexWrap_$external_prop_setter' : (_this, v) => _this.flexWrap = v,
        'org.w3c.dom.css.font_$external_prop_getter' : (_this) => _this.font,
        'org.w3c.dom.css.font_$external_prop_setter' : (_this, v) => _this.font = v,
        'org.w3c.dom.css.fontFamily_$external_prop_getter' : (_this) => _this.fontFamily,
        'org.w3c.dom.css.fontFamily_$external_prop_setter' : (_this, v) => _this.fontFamily = v,
        'org.w3c.dom.css.fontFeatureSettings_$external_prop_getter' : (_this) => _this.fontFeatureSettings,
        'org.w3c.dom.css.fontFeatureSettings_$external_prop_setter' : (_this, v) => _this.fontFeatureSettings = v,
        'org.w3c.dom.css.fontKerning_$external_prop_getter' : (_this) => _this.fontKerning,
        'org.w3c.dom.css.fontKerning_$external_prop_setter' : (_this, v) => _this.fontKerning = v,
        'org.w3c.dom.css.fontLanguageOverride_$external_prop_getter' : (_this) => _this.fontLanguageOverride,
        'org.w3c.dom.css.fontLanguageOverride_$external_prop_setter' : (_this, v) => _this.fontLanguageOverride = v,
        'org.w3c.dom.css.fontSize_$external_prop_getter' : (_this) => _this.fontSize,
        'org.w3c.dom.css.fontSize_$external_prop_setter' : (_this, v) => _this.fontSize = v,
        'org.w3c.dom.css.fontSizeAdjust_$external_prop_getter' : (_this) => _this.fontSizeAdjust,
        'org.w3c.dom.css.fontSizeAdjust_$external_prop_setter' : (_this, v) => _this.fontSizeAdjust = v,
        'org.w3c.dom.css.fontStretch_$external_prop_getter' : (_this) => _this.fontStretch,
        'org.w3c.dom.css.fontStretch_$external_prop_setter' : (_this, v) => _this.fontStretch = v,
        'org.w3c.dom.css.fontStyle_$external_prop_getter' : (_this) => _this.fontStyle,
        'org.w3c.dom.css.fontStyle_$external_prop_setter' : (_this, v) => _this.fontStyle = v,
        'org.w3c.dom.css.fontSynthesis_$external_prop_getter' : (_this) => _this.fontSynthesis,
        'org.w3c.dom.css.fontSynthesis_$external_prop_setter' : (_this, v) => _this.fontSynthesis = v,
        'org.w3c.dom.css.fontVariant_$external_prop_getter' : (_this) => _this.fontVariant,
        'org.w3c.dom.css.fontVariant_$external_prop_setter' : (_this, v) => _this.fontVariant = v,
        'org.w3c.dom.css.fontVariantAlternates_$external_prop_getter' : (_this) => _this.fontVariantAlternates,
        'org.w3c.dom.css.fontVariantAlternates_$external_prop_setter' : (_this, v) => _this.fontVariantAlternates = v,
        'org.w3c.dom.css.fontVariantCaps_$external_prop_getter' : (_this) => _this.fontVariantCaps,
        'org.w3c.dom.css.fontVariantCaps_$external_prop_setter' : (_this, v) => _this.fontVariantCaps = v,
        'org.w3c.dom.css.fontVariantEastAsian_$external_prop_getter' : (_this) => _this.fontVariantEastAsian,
        'org.w3c.dom.css.fontVariantEastAsian_$external_prop_setter' : (_this, v) => _this.fontVariantEastAsian = v,
        'org.w3c.dom.css.fontVariantLigatures_$external_prop_getter' : (_this) => _this.fontVariantLigatures,
        'org.w3c.dom.css.fontVariantLigatures_$external_prop_setter' : (_this, v) => _this.fontVariantLigatures = v,
        'org.w3c.dom.css.fontVariantNumeric_$external_prop_getter' : (_this) => _this.fontVariantNumeric,
        'org.w3c.dom.css.fontVariantNumeric_$external_prop_setter' : (_this, v) => _this.fontVariantNumeric = v,
        'org.w3c.dom.css.fontVariantPosition_$external_prop_getter' : (_this) => _this.fontVariantPosition,
        'org.w3c.dom.css.fontVariantPosition_$external_prop_setter' : (_this, v) => _this.fontVariantPosition = v,
        'org.w3c.dom.css.fontWeight_$external_prop_getter' : (_this) => _this.fontWeight,
        'org.w3c.dom.css.fontWeight_$external_prop_setter' : (_this, v) => _this.fontWeight = v,
        'org.w3c.dom.css.hangingPunctuation_$external_prop_getter' : (_this) => _this.hangingPunctuation,
        'org.w3c.dom.css.hangingPunctuation_$external_prop_setter' : (_this, v) => _this.hangingPunctuation = v,
        'org.w3c.dom.css.height_$external_prop_getter' : (_this) => _this.height,
        'org.w3c.dom.css.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.w3c.dom.css.hyphens_$external_prop_getter' : (_this) => _this.hyphens,
        'org.w3c.dom.css.hyphens_$external_prop_setter' : (_this, v) => _this.hyphens = v,
        'org.w3c.dom.css.imageOrientation_$external_prop_getter' : (_this) => _this.imageOrientation,
        'org.w3c.dom.css.imageOrientation_$external_prop_setter' : (_this, v) => _this.imageOrientation = v,
        'org.w3c.dom.css.imageRendering_$external_prop_getter' : (_this) => _this.imageRendering,
        'org.w3c.dom.css.imageRendering_$external_prop_setter' : (_this, v) => _this.imageRendering = v,
        'org.w3c.dom.css.imageResolution_$external_prop_getter' : (_this) => _this.imageResolution,
        'org.w3c.dom.css.imageResolution_$external_prop_setter' : (_this, v) => _this.imageResolution = v,
        'org.w3c.dom.css.imeMode_$external_prop_getter' : (_this) => _this.imeMode,
        'org.w3c.dom.css.imeMode_$external_prop_setter' : (_this, v) => _this.imeMode = v,
        'org.w3c.dom.css.justifyContent_$external_prop_getter' : (_this) => _this.justifyContent,
        'org.w3c.dom.css.justifyContent_$external_prop_setter' : (_this, v) => _this.justifyContent = v,
        'org.w3c.dom.css.left_$external_prop_getter' : (_this) => _this.left,
        'org.w3c.dom.css.left_$external_prop_setter' : (_this, v) => _this.left = v,
        'org.w3c.dom.css.letterSpacing_$external_prop_getter' : (_this) => _this.letterSpacing,
        'org.w3c.dom.css.letterSpacing_$external_prop_setter' : (_this, v) => _this.letterSpacing = v,
        'org.w3c.dom.css.lineBreak_$external_prop_getter' : (_this) => _this.lineBreak,
        'org.w3c.dom.css.lineBreak_$external_prop_setter' : (_this, v) => _this.lineBreak = v,
        'org.w3c.dom.css.lineHeight_$external_prop_getter' : (_this) => _this.lineHeight,
        'org.w3c.dom.css.lineHeight_$external_prop_setter' : (_this, v) => _this.lineHeight = v,
        'org.w3c.dom.css.listStyle_$external_prop_getter' : (_this) => _this.listStyle,
        'org.w3c.dom.css.listStyle_$external_prop_setter' : (_this, v) => _this.listStyle = v,
        'org.w3c.dom.css.listStyleImage_$external_prop_getter' : (_this) => _this.listStyleImage,
        'org.w3c.dom.css.listStyleImage_$external_prop_setter' : (_this, v) => _this.listStyleImage = v,
        'org.w3c.dom.css.listStylePosition_$external_prop_getter' : (_this) => _this.listStylePosition,
        'org.w3c.dom.css.listStylePosition_$external_prop_setter' : (_this, v) => _this.listStylePosition = v,
        'org.w3c.dom.css.listStyleType_$external_prop_getter' : (_this) => _this.listStyleType,
        'org.w3c.dom.css.listStyleType_$external_prop_setter' : (_this, v) => _this.listStyleType = v,
        'org.w3c.dom.css.margin_$external_prop_getter' : (_this) => _this.margin,
        'org.w3c.dom.css.margin_$external_prop_setter' : (_this, v) => _this.margin = v,
        'org.w3c.dom.css.marginBottom_$external_prop_getter' : (_this) => _this.marginBottom,
        'org.w3c.dom.css.marginBottom_$external_prop_setter' : (_this, v) => _this.marginBottom = v,
        'org.w3c.dom.css.marginLeft_$external_prop_getter' : (_this) => _this.marginLeft,
        'org.w3c.dom.css.marginLeft_$external_prop_setter' : (_this, v) => _this.marginLeft = v,
        'org.w3c.dom.css.marginRight_$external_prop_getter' : (_this) => _this.marginRight,
        'org.w3c.dom.css.marginRight_$external_prop_setter' : (_this, v) => _this.marginRight = v,
        'org.w3c.dom.css.marginTop_$external_prop_getter' : (_this) => _this.marginTop,
        'org.w3c.dom.css.marginTop_$external_prop_setter' : (_this, v) => _this.marginTop = v,
        'org.w3c.dom.css.mark_$external_prop_getter' : (_this) => _this.mark,
        'org.w3c.dom.css.mark_$external_prop_setter' : (_this, v) => _this.mark = v,
        'org.w3c.dom.css.markAfter_$external_prop_getter' : (_this) => _this.markAfter,
        'org.w3c.dom.css.markAfter_$external_prop_setter' : (_this, v) => _this.markAfter = v,
        'org.w3c.dom.css.markBefore_$external_prop_getter' : (_this) => _this.markBefore,
        'org.w3c.dom.css.markBefore_$external_prop_setter' : (_this, v) => _this.markBefore = v,
        'org.w3c.dom.css.marks_$external_prop_getter' : (_this) => _this.marks,
        'org.w3c.dom.css.marks_$external_prop_setter' : (_this, v) => _this.marks = v,
        'org.w3c.dom.css.marqueeDirection_$external_prop_getter' : (_this) => _this.marqueeDirection,
        'org.w3c.dom.css.marqueeDirection_$external_prop_setter' : (_this, v) => _this.marqueeDirection = v,
        'org.w3c.dom.css.marqueePlayCount_$external_prop_getter' : (_this) => _this.marqueePlayCount,
        'org.w3c.dom.css.marqueePlayCount_$external_prop_setter' : (_this, v) => _this.marqueePlayCount = v,
        'org.w3c.dom.css.marqueeSpeed_$external_prop_getter' : (_this) => _this.marqueeSpeed,
        'org.w3c.dom.css.marqueeSpeed_$external_prop_setter' : (_this, v) => _this.marqueeSpeed = v,
        'org.w3c.dom.css.marqueeStyle_$external_prop_getter' : (_this) => _this.marqueeStyle,
        'org.w3c.dom.css.marqueeStyle_$external_prop_setter' : (_this, v) => _this.marqueeStyle = v,
        'org.w3c.dom.css.mask_$external_prop_getter' : (_this) => _this.mask,
        'org.w3c.dom.css.mask_$external_prop_setter' : (_this, v) => _this.mask = v,
        'org.w3c.dom.css.maskType_$external_prop_getter' : (_this) => _this.maskType,
        'org.w3c.dom.css.maskType_$external_prop_setter' : (_this, v) => _this.maskType = v,
        'org.w3c.dom.css.maxHeight_$external_prop_getter' : (_this) => _this.maxHeight,
        'org.w3c.dom.css.maxHeight_$external_prop_setter' : (_this, v) => _this.maxHeight = v,
        'org.w3c.dom.css.maxWidth_$external_prop_getter' : (_this) => _this.maxWidth,
        'org.w3c.dom.css.maxWidth_$external_prop_setter' : (_this, v) => _this.maxWidth = v,
        'org.w3c.dom.css.minHeight_$external_prop_getter' : (_this) => _this.minHeight,
        'org.w3c.dom.css.minHeight_$external_prop_setter' : (_this, v) => _this.minHeight = v,
        'org.w3c.dom.css.minWidth_$external_prop_getter' : (_this) => _this.minWidth,
        'org.w3c.dom.css.minWidth_$external_prop_setter' : (_this, v) => _this.minWidth = v,
        'org.w3c.dom.css.navDown_$external_prop_getter' : (_this) => _this.navDown,
        'org.w3c.dom.css.navDown_$external_prop_setter' : (_this, v) => _this.navDown = v,
        'org.w3c.dom.css.navIndex_$external_prop_getter' : (_this) => _this.navIndex,
        'org.w3c.dom.css.navIndex_$external_prop_setter' : (_this, v) => _this.navIndex = v,
        'org.w3c.dom.css.navLeft_$external_prop_getter' : (_this) => _this.navLeft,
        'org.w3c.dom.css.navLeft_$external_prop_setter' : (_this, v) => _this.navLeft = v,
        'org.w3c.dom.css.navRight_$external_prop_getter' : (_this) => _this.navRight,
        'org.w3c.dom.css.navRight_$external_prop_setter' : (_this, v) => _this.navRight = v,
        'org.w3c.dom.css.navUp_$external_prop_getter' : (_this) => _this.navUp,
        'org.w3c.dom.css.navUp_$external_prop_setter' : (_this, v) => _this.navUp = v,
        'org.w3c.dom.css.objectFit_$external_prop_getter' : (_this) => _this.objectFit,
        'org.w3c.dom.css.objectFit_$external_prop_setter' : (_this, v) => _this.objectFit = v,
        'org.w3c.dom.css.objectPosition_$external_prop_getter' : (_this) => _this.objectPosition,
        'org.w3c.dom.css.objectPosition_$external_prop_setter' : (_this, v) => _this.objectPosition = v,
        'org.w3c.dom.css.opacity_$external_prop_getter' : (_this) => _this.opacity,
        'org.w3c.dom.css.opacity_$external_prop_setter' : (_this, v) => _this.opacity = v,
        'org.w3c.dom.css.order_$external_prop_getter' : (_this) => _this.order,
        'org.w3c.dom.css.order_$external_prop_setter' : (_this, v) => _this.order = v,
        'org.w3c.dom.css.orphans_$external_prop_getter' : (_this) => _this.orphans,
        'org.w3c.dom.css.orphans_$external_prop_setter' : (_this, v) => _this.orphans = v,
        'org.w3c.dom.css.outline_$external_prop_getter' : (_this) => _this.outline,
        'org.w3c.dom.css.outline_$external_prop_setter' : (_this, v) => _this.outline = v,
        'org.w3c.dom.css.outlineColor_$external_prop_getter' : (_this) => _this.outlineColor,
        'org.w3c.dom.css.outlineColor_$external_prop_setter' : (_this, v) => _this.outlineColor = v,
        'org.w3c.dom.css.outlineOffset_$external_prop_getter' : (_this) => _this.outlineOffset,
        'org.w3c.dom.css.outlineOffset_$external_prop_setter' : (_this, v) => _this.outlineOffset = v,
        'org.w3c.dom.css.outlineStyle_$external_prop_getter' : (_this) => _this.outlineStyle,
        'org.w3c.dom.css.outlineStyle_$external_prop_setter' : (_this, v) => _this.outlineStyle = v,
        'org.w3c.dom.css.outlineWidth_$external_prop_getter' : (_this) => _this.outlineWidth,
        'org.w3c.dom.css.outlineWidth_$external_prop_setter' : (_this, v) => _this.outlineWidth = v,
        'org.w3c.dom.css.overflowWrap_$external_prop_getter' : (_this) => _this.overflowWrap,
        'org.w3c.dom.css.overflowWrap_$external_prop_setter' : (_this, v) => _this.overflowWrap = v,
        'org.w3c.dom.css.overflowX_$external_prop_getter' : (_this) => _this.overflowX,
        'org.w3c.dom.css.overflowX_$external_prop_setter' : (_this, v) => _this.overflowX = v,
        'org.w3c.dom.css.overflowY_$external_prop_getter' : (_this) => _this.overflowY,
        'org.w3c.dom.css.overflowY_$external_prop_setter' : (_this, v) => _this.overflowY = v,
        'org.w3c.dom.css.padding_$external_prop_getter' : (_this) => _this.padding,
        'org.w3c.dom.css.padding_$external_prop_setter' : (_this, v) => _this.padding = v,
        'org.w3c.dom.css.paddingBottom_$external_prop_getter' : (_this) => _this.paddingBottom,
        'org.w3c.dom.css.paddingBottom_$external_prop_setter' : (_this, v) => _this.paddingBottom = v,
        'org.w3c.dom.css.paddingLeft_$external_prop_getter' : (_this) => _this.paddingLeft,
        'org.w3c.dom.css.paddingLeft_$external_prop_setter' : (_this, v) => _this.paddingLeft = v,
        'org.w3c.dom.css.paddingRight_$external_prop_getter' : (_this) => _this.paddingRight,
        'org.w3c.dom.css.paddingRight_$external_prop_setter' : (_this, v) => _this.paddingRight = v,
        'org.w3c.dom.css.paddingTop_$external_prop_getter' : (_this) => _this.paddingTop,
        'org.w3c.dom.css.paddingTop_$external_prop_setter' : (_this, v) => _this.paddingTop = v,
        'org.w3c.dom.css.pageBreakAfter_$external_prop_getter' : (_this) => _this.pageBreakAfter,
        'org.w3c.dom.css.pageBreakAfter_$external_prop_setter' : (_this, v) => _this.pageBreakAfter = v,
        'org.w3c.dom.css.pageBreakBefore_$external_prop_getter' : (_this) => _this.pageBreakBefore,
        'org.w3c.dom.css.pageBreakBefore_$external_prop_setter' : (_this, v) => _this.pageBreakBefore = v,
        'org.w3c.dom.css.pageBreakInside_$external_prop_getter' : (_this) => _this.pageBreakInside,
        'org.w3c.dom.css.pageBreakInside_$external_prop_setter' : (_this, v) => _this.pageBreakInside = v,
        'org.w3c.dom.css.perspective_$external_prop_getter' : (_this) => _this.perspective,
        'org.w3c.dom.css.perspective_$external_prop_setter' : (_this, v) => _this.perspective = v,
        'org.w3c.dom.css.perspectiveOrigin_$external_prop_getter' : (_this) => _this.perspectiveOrigin,
        'org.w3c.dom.css.perspectiveOrigin_$external_prop_setter' : (_this, v) => _this.perspectiveOrigin = v,
        'org.w3c.dom.css.phonemes_$external_prop_getter' : (_this) => _this.phonemes,
        'org.w3c.dom.css.phonemes_$external_prop_setter' : (_this, v) => _this.phonemes = v,
        'org.w3c.dom.css.position_$external_prop_getter' : (_this) => _this.position,
        'org.w3c.dom.css.position_$external_prop_setter' : (_this, v) => _this.position = v,
        'org.w3c.dom.css.quotes_$external_prop_getter' : (_this) => _this.quotes,
        'org.w3c.dom.css.quotes_$external_prop_setter' : (_this, v) => _this.quotes = v,
        'org.w3c.dom.css.resize_$external_prop_getter' : (_this) => _this.resize,
        'org.w3c.dom.css.resize_$external_prop_setter' : (_this, v) => _this.resize = v,
        'org.w3c.dom.css.rest_$external_prop_getter' : (_this) => _this.rest,
        'org.w3c.dom.css.rest_$external_prop_setter' : (_this, v) => _this.rest = v,
        'org.w3c.dom.css.restAfter_$external_prop_getter' : (_this) => _this.restAfter,
        'org.w3c.dom.css.restAfter_$external_prop_setter' : (_this, v) => _this.restAfter = v,
        'org.w3c.dom.css.restBefore_$external_prop_getter' : (_this) => _this.restBefore,
        'org.w3c.dom.css.restBefore_$external_prop_setter' : (_this, v) => _this.restBefore = v,
        'org.w3c.dom.css.right_$external_prop_getter' : (_this) => _this.right,
        'org.w3c.dom.css.right_$external_prop_setter' : (_this, v) => _this.right = v,
        'org.w3c.dom.css.tabSize_$external_prop_getter' : (_this) => _this.tabSize,
        'org.w3c.dom.css.tabSize_$external_prop_setter' : (_this, v) => _this.tabSize = v,
        'org.w3c.dom.css.tableLayout_$external_prop_getter' : (_this) => _this.tableLayout,
        'org.w3c.dom.css.tableLayout_$external_prop_setter' : (_this, v) => _this.tableLayout = v,
        'org.w3c.dom.css.textAlign_$external_prop_getter' : (_this) => _this.textAlign,
        'org.w3c.dom.css.textAlign_$external_prop_setter' : (_this, v) => _this.textAlign = v,
        'org.w3c.dom.css.textAlignLast_$external_prop_getter' : (_this) => _this.textAlignLast,
        'org.w3c.dom.css.textAlignLast_$external_prop_setter' : (_this, v) => _this.textAlignLast = v,
        'org.w3c.dom.css.textCombineUpright_$external_prop_getter' : (_this) => _this.textCombineUpright,
        'org.w3c.dom.css.textCombineUpright_$external_prop_setter' : (_this, v) => _this.textCombineUpright = v,
        'org.w3c.dom.css.textDecoration_$external_prop_getter' : (_this) => _this.textDecoration,
        'org.w3c.dom.css.textDecoration_$external_prop_setter' : (_this, v) => _this.textDecoration = v,
        'org.w3c.dom.css.textDecorationColor_$external_prop_getter' : (_this) => _this.textDecorationColor,
        'org.w3c.dom.css.textDecorationColor_$external_prop_setter' : (_this, v) => _this.textDecorationColor = v,
        'org.w3c.dom.css.textDecorationLine_$external_prop_getter' : (_this) => _this.textDecorationLine,
        'org.w3c.dom.css.textDecorationLine_$external_prop_setter' : (_this, v) => _this.textDecorationLine = v,
        'org.w3c.dom.css.textDecorationStyle_$external_prop_getter' : (_this) => _this.textDecorationStyle,
        'org.w3c.dom.css.textDecorationStyle_$external_prop_setter' : (_this, v) => _this.textDecorationStyle = v,
        'org.w3c.dom.css.textIndent_$external_prop_getter' : (_this) => _this.textIndent,
        'org.w3c.dom.css.textIndent_$external_prop_setter' : (_this, v) => _this.textIndent = v,
        'org.w3c.dom.css.textJustify_$external_prop_getter' : (_this) => _this.textJustify,
        'org.w3c.dom.css.textJustify_$external_prop_setter' : (_this, v) => _this.textJustify = v,
        'org.w3c.dom.css.textOrientation_$external_prop_getter' : (_this) => _this.textOrientation,
        'org.w3c.dom.css.textOrientation_$external_prop_setter' : (_this, v) => _this.textOrientation = v,
        'org.w3c.dom.css.textOverflow_$external_prop_getter' : (_this) => _this.textOverflow,
        'org.w3c.dom.css.textOverflow_$external_prop_setter' : (_this, v) => _this.textOverflow = v,
        'org.w3c.dom.css.textShadow_$external_prop_getter' : (_this) => _this.textShadow,
        'org.w3c.dom.css.textShadow_$external_prop_setter' : (_this, v) => _this.textShadow = v,
        'org.w3c.dom.css.textTransform_$external_prop_getter' : (_this) => _this.textTransform,
        'org.w3c.dom.css.textTransform_$external_prop_setter' : (_this, v) => _this.textTransform = v,
        'org.w3c.dom.css.textUnderlinePosition_$external_prop_getter' : (_this) => _this.textUnderlinePosition,
        'org.w3c.dom.css.textUnderlinePosition_$external_prop_setter' : (_this, v) => _this.textUnderlinePosition = v,
        'org.w3c.dom.css.top_$external_prop_getter' : (_this) => _this.top,
        'org.w3c.dom.css.top_$external_prop_setter' : (_this, v) => _this.top = v,
        'org.w3c.dom.css.transform_$external_prop_getter' : (_this) => _this.transform,
        'org.w3c.dom.css.transform_$external_prop_setter' : (_this, v) => _this.transform = v,
        'org.w3c.dom.css.transformOrigin_$external_prop_getter' : (_this) => _this.transformOrigin,
        'org.w3c.dom.css.transformOrigin_$external_prop_setter' : (_this, v) => _this.transformOrigin = v,
        'org.w3c.dom.css.transformStyle_$external_prop_getter' : (_this) => _this.transformStyle,
        'org.w3c.dom.css.transformStyle_$external_prop_setter' : (_this, v) => _this.transformStyle = v,
        'org.w3c.dom.css.transition_$external_prop_getter' : (_this) => _this.transition,
        'org.w3c.dom.css.transition_$external_prop_setter' : (_this, v) => _this.transition = v,
        'org.w3c.dom.css.transitionDelay_$external_prop_getter' : (_this) => _this.transitionDelay,
        'org.w3c.dom.css.transitionDelay_$external_prop_setter' : (_this, v) => _this.transitionDelay = v,
        'org.w3c.dom.css.transitionDuration_$external_prop_getter' : (_this) => _this.transitionDuration,
        'org.w3c.dom.css.transitionDuration_$external_prop_setter' : (_this, v) => _this.transitionDuration = v,
        'org.w3c.dom.css.transitionProperty_$external_prop_getter' : (_this) => _this.transitionProperty,
        'org.w3c.dom.css.transitionProperty_$external_prop_setter' : (_this, v) => _this.transitionProperty = v,
        'org.w3c.dom.css.transitionTimingFunction_$external_prop_getter' : (_this) => _this.transitionTimingFunction,
        'org.w3c.dom.css.transitionTimingFunction_$external_prop_setter' : (_this, v) => _this.transitionTimingFunction = v,
        'org.w3c.dom.css.unicodeBidi_$external_prop_getter' : (_this) => _this.unicodeBidi,
        'org.w3c.dom.css.unicodeBidi_$external_prop_setter' : (_this, v) => _this.unicodeBidi = v,
        'org.w3c.dom.css.verticalAlign_$external_prop_getter' : (_this) => _this.verticalAlign,
        'org.w3c.dom.css.verticalAlign_$external_prop_setter' : (_this, v) => _this.verticalAlign = v,
        'org.w3c.dom.css.visibility_$external_prop_getter' : (_this) => _this.visibility,
        'org.w3c.dom.css.visibility_$external_prop_setter' : (_this, v) => _this.visibility = v,
        'org.w3c.dom.css.voiceBalance_$external_prop_getter' : (_this) => _this.voiceBalance,
        'org.w3c.dom.css.voiceBalance_$external_prop_setter' : (_this, v) => _this.voiceBalance = v,
        'org.w3c.dom.css.voiceDuration_$external_prop_getter' : (_this) => _this.voiceDuration,
        'org.w3c.dom.css.voiceDuration_$external_prop_setter' : (_this, v) => _this.voiceDuration = v,
        'org.w3c.dom.css.voicePitch_$external_prop_getter' : (_this) => _this.voicePitch,
        'org.w3c.dom.css.voicePitch_$external_prop_setter' : (_this, v) => _this.voicePitch = v,
        'org.w3c.dom.css.voicePitchRange_$external_prop_getter' : (_this) => _this.voicePitchRange,
        'org.w3c.dom.css.voicePitchRange_$external_prop_setter' : (_this, v) => _this.voicePitchRange = v,
        'org.w3c.dom.css.voiceRate_$external_prop_getter' : (_this) => _this.voiceRate,
        'org.w3c.dom.css.voiceRate_$external_prop_setter' : (_this, v) => _this.voiceRate = v,
        'org.w3c.dom.css.voiceStress_$external_prop_getter' : (_this) => _this.voiceStress,
        'org.w3c.dom.css.voiceStress_$external_prop_setter' : (_this, v) => _this.voiceStress = v,
        'org.w3c.dom.css.voiceVolume_$external_prop_getter' : (_this) => _this.voiceVolume,
        'org.w3c.dom.css.voiceVolume_$external_prop_setter' : (_this, v) => _this.voiceVolume = v,
        'org.w3c.dom.css.whiteSpace_$external_prop_getter' : (_this) => _this.whiteSpace,
        'org.w3c.dom.css.whiteSpace_$external_prop_setter' : (_this, v) => _this.whiteSpace = v,
        'org.w3c.dom.css.widows_$external_prop_getter' : (_this) => _this.widows,
        'org.w3c.dom.css.widows_$external_prop_setter' : (_this, v) => _this.widows = v,
        'org.w3c.dom.css.width_$external_prop_getter' : (_this) => _this.width,
        'org.w3c.dom.css.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.w3c.dom.css.wordBreak_$external_prop_getter' : (_this) => _this.wordBreak,
        'org.w3c.dom.css.wordBreak_$external_prop_setter' : (_this, v) => _this.wordBreak = v,
        'org.w3c.dom.css.wordSpacing_$external_prop_getter' : (_this) => _this.wordSpacing,
        'org.w3c.dom.css.wordSpacing_$external_prop_setter' : (_this, v) => _this.wordSpacing = v,
        'org.w3c.dom.css.wordWrap_$external_prop_getter' : (_this) => _this.wordWrap,
        'org.w3c.dom.css.wordWrap_$external_prop_setter' : (_this, v) => _this.wordWrap = v,
        'org.w3c.dom.css.writingMode_$external_prop_getter' : (_this) => _this.writingMode,
        'org.w3c.dom.css.writingMode_$external_prop_setter' : (_this, v) => _this.writingMode = v,
        'org.w3c.dom.css.zIndex_$external_prop_getter' : (_this) => _this.zIndex,
        'org.w3c.dom.css.zIndex_$external_prop_setter' : (_this, v) => _this.zIndex = v,
        'org.w3c.dom.css._dashed_attribute_$external_prop_getter' : (_this) => _this._dashed_attribute,
        'org.w3c.dom.css._dashed_attribute_$external_prop_setter' : (_this, v) => _this._dashed_attribute = v,
        'org.w3c.dom.css._camel_cased_attribute_$external_prop_getter' : (_this) => _this._camel_cased_attribute,
        'org.w3c.dom.css._camel_cased_attribute_$external_prop_setter' : (_this, v) => _this._camel_cased_attribute = v,
        'org.w3c.dom.css._webkit_cased_attribute_$external_prop_getter' : (_this) => _this._webkit_cased_attribute,
        'org.w3c.dom.css._webkit_cased_attribute_$external_prop_setter' : (_this, v) => _this._webkit_cased_attribute = v,
        'org.w3c.dom.css.getPropertyValue_$external_fun' : (_this, p0) => _this.getPropertyValue(p0),
        'org.w3c.dom.css.getPropertyPriority_$external_fun' : (_this, p0) => _this.getPropertyPriority(p0),
        'org.w3c.dom.css.setProperty_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.setProperty(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.css.setPropertyValue_$external_fun' : (_this, p0, p1) => _this.setPropertyValue(p0, p1),
        'org.w3c.dom.css.setPropertyPriority_$external_fun' : (_this, p0, p1) => _this.setPropertyPriority(p0, p1),
        'org.w3c.dom.css.removeProperty_$external_fun' : (_this, p0) => _this.removeProperty(p0),
        'org.w3c.dom.css.item_$external_fun' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.css.CSSStyleDeclaration_$external_class_instanceof' : (x) => x instanceof CSSStyleDeclaration,
        'org.w3c.dom.css.CSSStyleDeclaration_$external_class_get' : () => CSSStyleDeclaration,
        'org.w3c.dom.css.StyleSheetList_$external_fun' : () => new StyleSheetList(),
        'org.w3c.dom.css.item_$external_fun_1' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.css.StyleSheetList_$external_class_instanceof' : (x) => x instanceof StyleSheetList,
        'org.w3c.dom.css.StyleSheetList_$external_class_get' : () => StyleSheetList,
        'org.w3c.dom.css.style_$external_prop_getter' : (_this) => _this.style,
        'org.w3c.dom.css.sheet_$external_prop_getter' : (_this) => _this.sheet,
        'org.w3c.dom.css.CSSRule_$external_fun' : () => new CSSRule(),
        'org.w3c.dom.css.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.dom.css.cssText_$external_prop_getter_1' : (_this) => _this.cssText,
        'org.w3c.dom.css.cssText_$external_prop_setter_1' : (_this, v) => _this.cssText = v,
        'org.w3c.dom.css.parentRule_$external_prop_getter_1' : (_this) => _this.parentRule,
        'org.w3c.dom.css.parentStyleSheet_$external_prop_getter' : (_this) => _this.parentStyleSheet,
        'org.w3c.dom.css.Companion_$external_fun' : () => new CSSRule(),
        'org.w3c.dom.css.STYLE_RULE_$external_prop_getter' : (_this) => _this.STYLE_RULE,
        'org.w3c.dom.css.CHARSET_RULE_$external_prop_getter' : (_this) => _this.CHARSET_RULE,
        'org.w3c.dom.css.IMPORT_RULE_$external_prop_getter' : (_this) => _this.IMPORT_RULE,
        'org.w3c.dom.css.MEDIA_RULE_$external_prop_getter' : (_this) => _this.MEDIA_RULE,
        'org.w3c.dom.css.FONT_FACE_RULE_$external_prop_getter' : (_this) => _this.FONT_FACE_RULE,
        'org.w3c.dom.css.PAGE_RULE_$external_prop_getter' : (_this) => _this.PAGE_RULE,
        'org.w3c.dom.css.MARGIN_RULE_$external_prop_getter' : (_this) => _this.MARGIN_RULE,
        'org.w3c.dom.css.NAMESPACE_RULE_$external_prop_getter' : (_this) => _this.NAMESPACE_RULE,
        'org.w3c.dom.css.Companion_$external_object_getInstance' : () => CSSRule,
        'org.w3c.dom.css.Companion_$external_class_instanceof' : (x) => x instanceof CSSRule,
        'org.w3c.dom.css.Companion_$external_class_get' : () => CSSRule,
        'org.w3c.dom.css.CSSRule_$external_class_instanceof' : (x) => x instanceof CSSRule,
        'org.w3c.dom.css.CSSRule_$external_class_get' : () => CSSRule,
        'org.w3c.dom.css.StyleSheet_$external_fun' : () => new StyleSheet(),
        'org.w3c.dom.css.type_$external_prop_getter_1' : (_this) => _this.type,
        'org.w3c.dom.css.href_$external_prop_getter' : (_this) => _this.href,
        'org.w3c.dom.css.ownerNode_$external_prop_getter' : (_this) => _this.ownerNode,
        'org.w3c.dom.css.parentStyleSheet_$external_prop_getter_1' : (_this) => _this.parentStyleSheet,
        'org.w3c.dom.css.title_$external_prop_getter' : (_this) => _this.title,
        'org.w3c.dom.css.media_$external_prop_getter' : (_this) => _this.media,
        'org.w3c.dom.css.disabled_$external_prop_getter' : (_this) => _this.disabled,
        'org.w3c.dom.css.disabled_$external_prop_setter' : (_this, v) => _this.disabled = v,
        'org.w3c.dom.css.StyleSheet_$external_class_instanceof' : (x) => x instanceof StyleSheet,
        'org.w3c.dom.css.StyleSheet_$external_class_get' : () => StyleSheet,
        'org.w3c.dom.css.CSSStyleSheet_$external_fun' : () => new CSSStyleSheet(),
        'org.w3c.dom.css.ownerRule_$external_prop_getter' : (_this) => _this.ownerRule,
        'org.w3c.dom.css.cssRules_$external_prop_getter' : (_this) => _this.cssRules,
        'org.w3c.dom.css.insertRule_$external_fun' : (_this, p0, p1) => _this.insertRule(p0, p1),
        'org.w3c.dom.css.deleteRule_$external_fun' : (_this, p0) => _this.deleteRule(p0),
        'org.w3c.dom.css.CSSStyleSheet_$external_class_instanceof' : (x) => x instanceof CSSStyleSheet,
        'org.w3c.dom.css.CSSStyleSheet_$external_class_get' : () => CSSStyleSheet,
        'org.w3c.dom.css.MediaList_$external_fun' : () => new MediaList(),
        'org.w3c.dom.css.mediaText_$external_prop_getter' : (_this) => _this.mediaText,
        'org.w3c.dom.css.mediaText_$external_prop_setter' : (_this, v) => _this.mediaText = v,
        'org.w3c.dom.css.appendMedium_$external_fun' : (_this, p0) => _this.appendMedium(p0),
        'org.w3c.dom.css.deleteMedium_$external_fun' : (_this, p0) => _this.deleteMedium(p0),
        'org.w3c.dom.css.item_$external_fun_2' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.css.MediaList_$external_class_instanceof' : (x) => x instanceof MediaList,
        'org.w3c.dom.css.MediaList_$external_class_get' : () => MediaList,
        'org.w3c.dom.css.CSSRuleList_$external_fun' : () => new CSSRuleList(),
        'org.w3c.dom.css.item_$external_fun_3' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.css.CSSRuleList_$external_class_instanceof' : (x) => x instanceof CSSRuleList,
        'org.w3c.dom.css.CSSRuleList_$external_class_get' : () => CSSRuleList,
        'org.w3c.dom.encryptedmedia.MediaKeySystemAccess_$external_fun' : () => new MediaKeySystemAccess(),
        'org.w3c.dom.encryptedmedia.keySystem_$external_prop_getter' : (_this) => _this.keySystem,
        'org.w3c.dom.encryptedmedia.getConfiguration_$external_fun' : (_this, ) => _this.getConfiguration(),
        'org.w3c.dom.encryptedmedia.createMediaKeys_$external_fun' : (_this, ) => _this.createMediaKeys(),
        'org.w3c.dom.encryptedmedia.MediaKeySystemAccess_$external_class_instanceof' : (x) => x instanceof MediaKeySystemAccess,
        'org.w3c.dom.encryptedmedia.MediaKeySystemAccess_$external_class_get' : () => MediaKeySystemAccess,
        'org.w3c.dom.encryptedmedia.label_$external_prop_getter' : (_this) => _this.label,
        'org.w3c.dom.encryptedmedia.label_$external_prop_setter' : (_this, v) => _this.label = v,
        'org.w3c.dom.encryptedmedia.initDataTypes_$external_prop_getter' : (_this) => _this.initDataTypes,
        'org.w3c.dom.encryptedmedia.initDataTypes_$external_prop_setter' : (_this, v) => _this.initDataTypes = v,
        'org.w3c.dom.encryptedmedia.audioCapabilities_$external_prop_getter' : (_this) => _this.audioCapabilities,
        'org.w3c.dom.encryptedmedia.audioCapabilities_$external_prop_setter' : (_this, v) => _this.audioCapabilities = v,
        'org.w3c.dom.encryptedmedia.videoCapabilities_$external_prop_getter' : (_this) => _this.videoCapabilities,
        'org.w3c.dom.encryptedmedia.videoCapabilities_$external_prop_setter' : (_this, v) => _this.videoCapabilities = v,
        'org.w3c.dom.encryptedmedia.distinctiveIdentifier_$external_prop_getter' : (_this) => _this.distinctiveIdentifier,
        'org.w3c.dom.encryptedmedia.distinctiveIdentifier_$external_prop_setter' : (_this, v) => _this.distinctiveIdentifier = v,
        'org.w3c.dom.encryptedmedia.persistentState_$external_prop_getter' : (_this) => _this.persistentState,
        'org.w3c.dom.encryptedmedia.persistentState_$external_prop_setter' : (_this, v) => _this.persistentState = v,
        'org.w3c.dom.encryptedmedia.sessionTypes_$external_prop_getter' : (_this) => _this.sessionTypes,
        'org.w3c.dom.encryptedmedia.sessionTypes_$external_prop_setter' : (_this, v) => _this.sessionTypes = v,
        'org.w3c.dom.encryptedmedia.MediaKeys_$external_fun' : () => new MediaKeys(),
        'org.w3c.dom.encryptedmedia.createSession_$external_fun' : (_this, p0, isDefault0) => _this.createSession(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.encryptedmedia.setServerCertificate_$external_fun' : (_this, p0) => _this.setServerCertificate(p0),
        'org.w3c.dom.encryptedmedia.MediaKeys_$external_class_instanceof' : (x) => x instanceof MediaKeys,
        'org.w3c.dom.encryptedmedia.MediaKeys_$external_class_get' : () => MediaKeys,
        'org.w3c.dom.encryptedmedia.contentType_$external_prop_getter' : (_this) => _this.contentType,
        'org.w3c.dom.encryptedmedia.contentType_$external_prop_setter' : (_this, v) => _this.contentType = v,
        'org.w3c.dom.encryptedmedia.robustness_$external_prop_getter' : (_this) => _this.robustness,
        'org.w3c.dom.encryptedmedia.robustness_$external_prop_setter' : (_this, v) => _this.robustness = v,
        'org.w3c.dom.encryptedmedia.Companion_$external_fun' : () => new ({})(),
        'org.w3c.dom.encryptedmedia.Companion_$external_object_getInstance' : () => ({}),
        'org.w3c.dom.encryptedmedia.Companion_$external_class_instanceof' : (x) => x instanceof ({}),
        'org.w3c.dom.encryptedmedia.Companion_$external_class_get' : () => ({}),
        'org.w3c.dom.encryptedmedia.MediaKeySession_$external_fun' : () => new MediaKeySession(),
        'org.w3c.dom.encryptedmedia.sessionId_$external_prop_getter' : (_this) => _this.sessionId,
        'org.w3c.dom.encryptedmedia.expiration_$external_prop_getter' : (_this) => _this.expiration,
        'org.w3c.dom.encryptedmedia.closed_$external_prop_getter' : (_this) => _this.closed,
        'org.w3c.dom.encryptedmedia.keyStatuses_$external_prop_getter' : (_this) => _this.keyStatuses,
        'org.w3c.dom.encryptedmedia.onkeystatuseschange_$external_prop_getter' : (_this) => _this.onkeystatuseschange,
        'org.w3c.dom.encryptedmedia.onkeystatuseschange_$external_prop_setter' : (_this, v) => _this.onkeystatuseschange = v,
        'org.w3c.dom.encryptedmedia.__convertKotlinClosureToJsClosure_((Js)->Unit)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js)->Unit)'](f, p0)),
        'org.w3c.dom.encryptedmedia.onmessage_$external_prop_getter' : (_this) => _this.onmessage,
        'org.w3c.dom.encryptedmedia.onmessage_$external_prop_setter' : (_this, v) => _this.onmessage = v,
        'org.w3c.dom.encryptedmedia.generateRequest_$external_fun' : (_this, p0, p1) => _this.generateRequest(p0, p1),
        'org.w3c.dom.encryptedmedia.load_$external_fun' : (_this, p0) => _this.load(p0),
        'org.w3c.dom.encryptedmedia.update_$external_fun' : (_this, p0) => _this.update(p0),
        'org.w3c.dom.encryptedmedia.close_$external_fun' : (_this, ) => _this.close(),
        'org.w3c.dom.encryptedmedia.remove_$external_fun' : (_this, ) => _this.remove(),
        'org.w3c.dom.encryptedmedia.MediaKeySession_$external_class_instanceof' : (x) => x instanceof MediaKeySession,
        'org.w3c.dom.encryptedmedia.MediaKeySession_$external_class_get' : () => MediaKeySession,
        'org.w3c.dom.encryptedmedia.Companion_$external_fun_1' : () => new ({})(),
        'org.w3c.dom.encryptedmedia.Companion_$external_object_getInstance_1' : () => ({}),
        'org.w3c.dom.encryptedmedia.Companion_$external_class_instanceof_1' : (x) => x instanceof ({}),
        'org.w3c.dom.encryptedmedia.Companion_$external_class_get_1' : () => ({}),
        'org.w3c.dom.encryptedmedia.MediaKeyStatusMap_$external_fun' : () => new MediaKeyStatusMap(),
        'org.w3c.dom.encryptedmedia.size_$external_prop_getter' : (_this) => _this.size,
        'org.w3c.dom.encryptedmedia.has_$external_fun' : (_this, p0) => _this.has(p0),
        'org.w3c.dom.encryptedmedia.get_$external_fun' : (_this, p0) => _this.get(p0),
        'org.w3c.dom.encryptedmedia.MediaKeyStatusMap_$external_class_instanceof' : (x) => x instanceof MediaKeyStatusMap,
        'org.w3c.dom.encryptedmedia.MediaKeyStatusMap_$external_class_get' : () => MediaKeyStatusMap,
        'org.w3c.dom.events.KeyboardEventInit_js_code' : (key, code, location, repeat, isComposing, ctrlKey, shiftKey, altKey, metaKey, modifierAltGraph, modifierCapsLock, modifierFn, modifierFnLock, modifierHyper, modifierNumLock, modifierScrollLock, modifierSuper, modifierSymbol, modifierSymbolLock, view, detail, bubbles, cancelable, composed) => { return { key, code, location, repeat, isComposing, ctrlKey, shiftKey, altKey, metaKey, modifierAltGraph, modifierCapsLock, modifierFn, modifierFnLock, modifierHyper, modifierNumLock, modifierScrollLock, modifierSuper, modifierSymbol, modifierSymbolLock, view, detail, bubbles, cancelable, composed }; },
        'org.w3c.dom.events.EventTarget_$external_fun' : () => new EventTarget(),
        'org.w3c.dom.events.addEventListener_$external_fun' : (_this, p0, p1, p2) => _this.addEventListener(p0, p1, p2),
        'org.w3c.dom.events.addEventListener_$external_fun_1' : (_this, p0, p1, p2) => _this.addEventListener(p0, p1, p2),
        'org.w3c.dom.events.addEventListener_$external_fun_2' : (_this, p0, p1, p2) => _this.addEventListener(p0, p1, p2),
        'org.w3c.dom.events.addEventListener_$external_fun_3' : (_this, p0, p1, p2) => _this.addEventListener(p0, p1, p2),
        'org.w3c.dom.events.addEventListener_$external_fun_4' : (_this, p0, p1) => _this.addEventListener(p0, p1),
        'org.w3c.dom.events.addEventListener_$external_fun_5' : (_this, p0, p1) => _this.addEventListener(p0, p1),
        'org.w3c.dom.events.removeEventListener_$external_fun' : (_this, p0, p1, p2) => _this.removeEventListener(p0, p1, p2),
        'org.w3c.dom.events.removeEventListener_$external_fun_1' : (_this, p0, p1, p2) => _this.removeEventListener(p0, p1, p2),
        'org.w3c.dom.events.removeEventListener_$external_fun_2' : (_this, p0, p1, p2) => _this.removeEventListener(p0, p1, p2),
        'org.w3c.dom.events.removeEventListener_$external_fun_3' : (_this, p0, p1, p2) => _this.removeEventListener(p0, p1, p2),
        'org.w3c.dom.events.removeEventListener_$external_fun_4' : (_this, p0, p1) => _this.removeEventListener(p0, p1),
        'org.w3c.dom.events.removeEventListener_$external_fun_5' : (_this, p0, p1) => _this.removeEventListener(p0, p1),
        'org.w3c.dom.events.dispatchEvent_$external_fun' : (_this, p0) => _this.dispatchEvent(p0),
        'org.w3c.dom.events.EventTarget_$external_class_instanceof' : (x) => x instanceof EventTarget,
        'org.w3c.dom.events.EventTarget_$external_class_get' : () => EventTarget,
        'org.w3c.dom.events.Event_$external_fun' : (p0, p1, isDefault0) => new Event(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.dom.events.target_$external_prop_getter' : (_this) => _this.target,
        'org.w3c.dom.events.currentTarget_$external_prop_getter' : (_this) => _this.currentTarget,
        'org.w3c.dom.events.eventPhase_$external_prop_getter' : (_this) => _this.eventPhase,
        'org.w3c.dom.events.bubbles_$external_prop_getter' : (_this) => _this.bubbles,
        'org.w3c.dom.events.cancelable_$external_prop_getter' : (_this) => _this.cancelable,
        'org.w3c.dom.events.defaultPrevented_$external_prop_getter' : (_this) => _this.defaultPrevented,
        'org.w3c.dom.events.composed_$external_prop_getter' : (_this) => _this.composed,
        'org.w3c.dom.events.isTrusted_$external_prop_getter' : (_this) => _this.isTrusted,
        'org.w3c.dom.events.timeStamp_$external_prop_getter' : (_this) => _this.timeStamp,
        'org.w3c.dom.events.composedPath_$external_fun' : (_this, ) => _this.composedPath(),
        'org.w3c.dom.events.stopPropagation_$external_fun' : (_this, ) => _this.stopPropagation(),
        'org.w3c.dom.events.stopImmediatePropagation_$external_fun' : (_this, ) => _this.stopImmediatePropagation(),
        'org.w3c.dom.events.preventDefault_$external_fun' : (_this, ) => _this.preventDefault(),
        'org.w3c.dom.events.initEvent_$external_fun' : (_this, p0, p1, p2) => _this.initEvent(p0, p1, p2),
        'org.w3c.dom.events.Companion_$external_fun' : () => new Event(),
        'org.w3c.dom.events.NONE_$external_prop_getter' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance' : () => Event,
        'org.w3c.dom.events.Companion_$external_class_instanceof' : (x) => x instanceof Event,
        'org.w3c.dom.events.Companion_$external_class_get' : () => Event,
        'org.w3c.dom.events.Event_$external_class_instanceof' : (x) => x instanceof Event,
        'org.w3c.dom.events.Event_$external_class_get' : () => Event,
        'org.w3c.dom.events.FocusEvent_$external_fun' : (p0, p1, isDefault0) => new FocusEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.relatedTarget_$external_prop_getter' : (_this) => _this.relatedTarget,
        'org.w3c.dom.events.Companion_$external_fun_1' : () => new FocusEvent(),
        'org.w3c.dom.events.NONE_$external_prop_getter_1' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter_1' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter_1' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter_1' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance_1' : () => FocusEvent,
        'org.w3c.dom.events.Companion_$external_class_instanceof_1' : (x) => x instanceof FocusEvent,
        'org.w3c.dom.events.Companion_$external_class_get_1' : () => FocusEvent,
        'org.w3c.dom.events.FocusEvent_$external_class_instanceof' : (x) => x instanceof FocusEvent,
        'org.w3c.dom.events.FocusEvent_$external_class_get' : () => FocusEvent,
        'org.w3c.dom.events.MouseEvent_$external_fun' : (p0, p1, isDefault0) => new MouseEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.screenX_$external_prop_getter' : (_this) => _this.screenX,
        'org.w3c.dom.events.screenY_$external_prop_getter' : (_this) => _this.screenY,
        'org.w3c.dom.events.clientX_$external_prop_getter' : (_this) => _this.clientX,
        'org.w3c.dom.events.clientY_$external_prop_getter' : (_this) => _this.clientY,
        'org.w3c.dom.events.ctrlKey_$external_prop_getter' : (_this) => _this.ctrlKey,
        'org.w3c.dom.events.shiftKey_$external_prop_getter' : (_this) => _this.shiftKey,
        'org.w3c.dom.events.altKey_$external_prop_getter' : (_this) => _this.altKey,
        'org.w3c.dom.events.metaKey_$external_prop_getter' : (_this) => _this.metaKey,
        'org.w3c.dom.events.button_$external_prop_getter' : (_this) => _this.button,
        'org.w3c.dom.events.buttons_$external_prop_getter' : (_this) => _this.buttons,
        'org.w3c.dom.events.relatedTarget_$external_prop_getter_1' : (_this) => _this.relatedTarget,
        'org.w3c.dom.events.region_$external_prop_getter' : (_this) => _this.region,
        'org.w3c.dom.events.pageX_$external_prop_getter' : (_this) => _this.pageX,
        'org.w3c.dom.events.pageY_$external_prop_getter' : (_this) => _this.pageY,
        'org.w3c.dom.events.x_$external_prop_getter' : (_this) => _this.x,
        'org.w3c.dom.events.y_$external_prop_getter' : (_this) => _this.y,
        'org.w3c.dom.events.offsetX_$external_prop_getter' : (_this) => _this.offsetX,
        'org.w3c.dom.events.offsetY_$external_prop_getter' : (_this) => _this.offsetY,
        'org.w3c.dom.events.getModifierState_$external_fun' : (_this, p0) => _this.getModifierState(p0),
        'org.w3c.dom.events.Companion_$external_fun_2' : () => new MouseEvent(),
        'org.w3c.dom.events.NONE_$external_prop_getter_2' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter_2' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter_2' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter_2' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance_2' : () => MouseEvent,
        'org.w3c.dom.events.Companion_$external_class_instanceof_2' : (x) => x instanceof MouseEvent,
        'org.w3c.dom.events.Companion_$external_class_get_2' : () => MouseEvent,
        'org.w3c.dom.events.MouseEvent_$external_class_instanceof' : (x) => x instanceof MouseEvent,
        'org.w3c.dom.events.MouseEvent_$external_class_get' : () => MouseEvent,
        'org.w3c.dom.events.InputEvent_$external_fun' : (p0, p1, isDefault0) => new InputEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.data_$external_prop_getter' : (_this) => _this.data,
        'org.w3c.dom.events.isComposing_$external_prop_getter' : (_this) => _this.isComposing,
        'org.w3c.dom.events.Companion_$external_fun_3' : () => new InputEvent(),
        'org.w3c.dom.events.NONE_$external_prop_getter_3' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter_3' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter_3' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter_3' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance_3' : () => InputEvent,
        'org.w3c.dom.events.Companion_$external_class_instanceof_3' : (x) => x instanceof InputEvent,
        'org.w3c.dom.events.Companion_$external_class_get_3' : () => InputEvent,
        'org.w3c.dom.events.InputEvent_$external_class_instanceof' : (x) => x instanceof InputEvent,
        'org.w3c.dom.events.InputEvent_$external_class_get' : () => InputEvent,
        'org.w3c.dom.events.KeyboardEvent_$external_fun' : (p0, p1, isDefault0) => new KeyboardEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.key_$external_prop_getter' : (_this) => _this.key,
        'org.w3c.dom.events.code_$external_prop_getter' : (_this) => _this.code,
        'org.w3c.dom.events.location_$external_prop_getter' : (_this) => _this.location,
        'org.w3c.dom.events.ctrlKey_$external_prop_getter_1' : (_this) => _this.ctrlKey,
        'org.w3c.dom.events.shiftKey_$external_prop_getter_1' : (_this) => _this.shiftKey,
        'org.w3c.dom.events.altKey_$external_prop_getter_1' : (_this) => _this.altKey,
        'org.w3c.dom.events.metaKey_$external_prop_getter_1' : (_this) => _this.metaKey,
        'org.w3c.dom.events.repeat_$external_prop_getter' : (_this) => _this.repeat,
        'org.w3c.dom.events.isComposing_$external_prop_getter_1' : (_this) => _this.isComposing,
        'org.w3c.dom.events.charCode_$external_prop_getter' : (_this) => _this.charCode,
        'org.w3c.dom.events.keyCode_$external_prop_getter' : (_this) => _this.keyCode,
        'org.w3c.dom.events.which_$external_prop_getter' : (_this) => _this.which,
        'org.w3c.dom.events.getModifierState_$external_fun_1' : (_this, p0) => _this.getModifierState(p0),
        'org.w3c.dom.events.Companion_$external_fun_4' : () => new KeyboardEvent(),
        'org.w3c.dom.events.DOM_KEY_LOCATION_STANDARD_$external_prop_getter' : (_this) => _this.DOM_KEY_LOCATION_STANDARD,
        'org.w3c.dom.events.DOM_KEY_LOCATION_LEFT_$external_prop_getter' : (_this) => _this.DOM_KEY_LOCATION_LEFT,
        'org.w3c.dom.events.DOM_KEY_LOCATION_RIGHT_$external_prop_getter' : (_this) => _this.DOM_KEY_LOCATION_RIGHT,
        'org.w3c.dom.events.DOM_KEY_LOCATION_NUMPAD_$external_prop_getter' : (_this) => _this.DOM_KEY_LOCATION_NUMPAD,
        'org.w3c.dom.events.NONE_$external_prop_getter_4' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter_4' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter_4' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter_4' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance_4' : () => KeyboardEvent,
        'org.w3c.dom.events.Companion_$external_class_instanceof_4' : (x) => x instanceof KeyboardEvent,
        'org.w3c.dom.events.Companion_$external_class_get_4' : () => KeyboardEvent,
        'org.w3c.dom.events.KeyboardEvent_$external_class_instanceof' : (x) => x instanceof KeyboardEvent,
        'org.w3c.dom.events.KeyboardEvent_$external_class_get' : () => KeyboardEvent,
        'org.w3c.dom.events.WheelEvent_$external_fun' : (p0, p1, isDefault0) => new WheelEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.deltaX_$external_prop_getter' : (_this) => _this.deltaX,
        'org.w3c.dom.events.deltaY_$external_prop_getter' : (_this) => _this.deltaY,
        'org.w3c.dom.events.deltaZ_$external_prop_getter' : (_this) => _this.deltaZ,
        'org.w3c.dom.events.deltaMode_$external_prop_getter' : (_this) => _this.deltaMode,
        'org.w3c.dom.events.Companion_$external_fun_5' : () => new WheelEvent(),
        'org.w3c.dom.events.DOM_DELTA_PIXEL_$external_prop_getter' : (_this) => _this.DOM_DELTA_PIXEL,
        'org.w3c.dom.events.DOM_DELTA_LINE_$external_prop_getter' : (_this) => _this.DOM_DELTA_LINE,
        'org.w3c.dom.events.DOM_DELTA_PAGE_$external_prop_getter' : (_this) => _this.DOM_DELTA_PAGE,
        'org.w3c.dom.events.NONE_$external_prop_getter_5' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter_5' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter_5' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter_5' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance_5' : () => WheelEvent,
        'org.w3c.dom.events.Companion_$external_class_instanceof_5' : (x) => x instanceof WheelEvent,
        'org.w3c.dom.events.Companion_$external_class_get_5' : () => WheelEvent,
        'org.w3c.dom.events.WheelEvent_$external_class_instanceof' : (x) => x instanceof WheelEvent,
        'org.w3c.dom.events.WheelEvent_$external_class_get' : () => WheelEvent,
        'org.w3c.dom.events.handleEvent_$external_fun' : (_this, p0) => _this.handleEvent(p0),
        'org.w3c.dom.events.screenX_$external_prop_getter_1' : (_this) => _this.screenX,
        'org.w3c.dom.events.screenX_$external_prop_setter' : (_this, v) => _this.screenX = v,
        'org.w3c.dom.events.screenY_$external_prop_getter_1' : (_this) => _this.screenY,
        'org.w3c.dom.events.screenY_$external_prop_setter' : (_this, v) => _this.screenY = v,
        'org.w3c.dom.events.clientX_$external_prop_getter_1' : (_this) => _this.clientX,
        'org.w3c.dom.events.clientX_$external_prop_setter' : (_this, v) => _this.clientX = v,
        'org.w3c.dom.events.clientY_$external_prop_getter_1' : (_this) => _this.clientY,
        'org.w3c.dom.events.clientY_$external_prop_setter' : (_this, v) => _this.clientY = v,
        'org.w3c.dom.events.button_$external_prop_getter_1' : (_this) => _this.button,
        'org.w3c.dom.events.button_$external_prop_setter' : (_this, v) => _this.button = v,
        'org.w3c.dom.events.buttons_$external_prop_getter_1' : (_this) => _this.buttons,
        'org.w3c.dom.events.buttons_$external_prop_setter' : (_this, v) => _this.buttons = v,
        'org.w3c.dom.events.relatedTarget_$external_prop_getter_2' : (_this) => _this.relatedTarget,
        'org.w3c.dom.events.relatedTarget_$external_prop_setter' : (_this, v) => _this.relatedTarget = v,
        'org.w3c.dom.events.region_$external_prop_getter_1' : (_this) => _this.region,
        'org.w3c.dom.events.region_$external_prop_setter' : (_this, v) => _this.region = v,
        'org.w3c.dom.events.UIEvent_$external_fun' : (p0, p1, isDefault0) => new UIEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.events.view_$external_prop_getter' : (_this) => _this.view,
        'org.w3c.dom.events.detail_$external_prop_getter' : (_this) => _this.detail,
        'org.w3c.dom.events.Companion_$external_fun_6' : () => new UIEvent(),
        'org.w3c.dom.events.NONE_$external_prop_getter_6' : (_this) => _this.NONE,
        'org.w3c.dom.events.CAPTURING_PHASE_$external_prop_getter_6' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.events.AT_TARGET_$external_prop_getter_6' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.events.BUBBLING_PHASE_$external_prop_getter_6' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.events.Companion_$external_object_getInstance_6' : () => UIEvent,
        'org.w3c.dom.events.Companion_$external_class_instanceof_6' : (x) => x instanceof UIEvent,
        'org.w3c.dom.events.Companion_$external_class_get_6' : () => UIEvent,
        'org.w3c.dom.events.UIEvent_$external_class_instanceof' : (x) => x instanceof UIEvent,
        'org.w3c.dom.events.UIEvent_$external_class_get' : () => UIEvent,
        'org.w3c.dom.events.relatedTarget_$external_prop_getter_3' : (_this) => _this.relatedTarget,
        'org.w3c.dom.events.relatedTarget_$external_prop_setter_1' : (_this, v) => _this.relatedTarget = v,
        'org.w3c.dom.events.data_$external_prop_getter_1' : (_this) => _this.data,
        'org.w3c.dom.events.data_$external_prop_setter' : (_this, v) => _this.data = v,
        'org.w3c.dom.events.isComposing_$external_prop_getter_2' : (_this) => _this.isComposing,
        'org.w3c.dom.events.isComposing_$external_prop_setter' : (_this, v) => _this.isComposing = v,
        'org.w3c.dom.events.key_$external_prop_getter_1' : (_this) => _this.key,
        'org.w3c.dom.events.key_$external_prop_setter' : (_this, v) => _this.key = v,
        'org.w3c.dom.events.code_$external_prop_getter_1' : (_this) => _this.code,
        'org.w3c.dom.events.code_$external_prop_setter' : (_this, v) => _this.code = v,
        'org.w3c.dom.events.location_$external_prop_getter_1' : (_this) => _this.location,
        'org.w3c.dom.events.location_$external_prop_setter' : (_this, v) => _this.location = v,
        'org.w3c.dom.events.repeat_$external_prop_getter_1' : (_this) => _this.repeat,
        'org.w3c.dom.events.repeat_$external_prop_setter' : (_this, v) => _this.repeat = v,
        'org.w3c.dom.events.isComposing_$external_prop_getter_3' : (_this) => _this.isComposing,
        'org.w3c.dom.events.isComposing_$external_prop_setter_1' : (_this, v) => _this.isComposing = v,
        'org.w3c.dom.events.deltaX_$external_prop_getter_1' : (_this) => _this.deltaX,
        'org.w3c.dom.events.deltaX_$external_prop_setter' : (_this, v) => _this.deltaX = v,
        'org.w3c.dom.events.deltaY_$external_prop_getter_1' : (_this) => _this.deltaY,
        'org.w3c.dom.events.deltaY_$external_prop_setter' : (_this, v) => _this.deltaY = v,
        'org.w3c.dom.events.deltaZ_$external_prop_getter_1' : (_this) => _this.deltaZ,
        'org.w3c.dom.events.deltaZ_$external_prop_setter' : (_this, v) => _this.deltaZ = v,
        'org.w3c.dom.events.deltaMode_$external_prop_getter_1' : (_this) => _this.deltaMode,
        'org.w3c.dom.events.deltaMode_$external_prop_setter' : (_this, v) => _this.deltaMode = v,
        'org.w3c.dom.events.ctrlKey_$external_prop_getter_2' : (_this) => _this.ctrlKey,
        'org.w3c.dom.events.ctrlKey_$external_prop_setter' : (_this, v) => _this.ctrlKey = v,
        'org.w3c.dom.events.shiftKey_$external_prop_getter_2' : (_this) => _this.shiftKey,
        'org.w3c.dom.events.shiftKey_$external_prop_setter' : (_this, v) => _this.shiftKey = v,
        'org.w3c.dom.events.altKey_$external_prop_getter_2' : (_this) => _this.altKey,
        'org.w3c.dom.events.altKey_$external_prop_setter' : (_this, v) => _this.altKey = v,
        'org.w3c.dom.events.metaKey_$external_prop_getter_2' : (_this) => _this.metaKey,
        'org.w3c.dom.events.metaKey_$external_prop_setter' : (_this, v) => _this.metaKey = v,
        'org.w3c.dom.events.modifierAltGraph_$external_prop_getter' : (_this) => _this.modifierAltGraph,
        'org.w3c.dom.events.modifierAltGraph_$external_prop_setter' : (_this, v) => _this.modifierAltGraph = v,
        'org.w3c.dom.events.modifierCapsLock_$external_prop_getter' : (_this) => _this.modifierCapsLock,
        'org.w3c.dom.events.modifierCapsLock_$external_prop_setter' : (_this, v) => _this.modifierCapsLock = v,
        'org.w3c.dom.events.modifierFn_$external_prop_getter' : (_this) => _this.modifierFn,
        'org.w3c.dom.events.modifierFn_$external_prop_setter' : (_this, v) => _this.modifierFn = v,
        'org.w3c.dom.events.modifierFnLock_$external_prop_getter' : (_this) => _this.modifierFnLock,
        'org.w3c.dom.events.modifierFnLock_$external_prop_setter' : (_this, v) => _this.modifierFnLock = v,
        'org.w3c.dom.events.modifierHyper_$external_prop_getter' : (_this) => _this.modifierHyper,
        'org.w3c.dom.events.modifierHyper_$external_prop_setter' : (_this, v) => _this.modifierHyper = v,
        'org.w3c.dom.events.modifierNumLock_$external_prop_getter' : (_this) => _this.modifierNumLock,
        'org.w3c.dom.events.modifierNumLock_$external_prop_setter' : (_this, v) => _this.modifierNumLock = v,
        'org.w3c.dom.events.modifierScrollLock_$external_prop_getter' : (_this) => _this.modifierScrollLock,
        'org.w3c.dom.events.modifierScrollLock_$external_prop_setter' : (_this, v) => _this.modifierScrollLock = v,
        'org.w3c.dom.events.modifierSuper_$external_prop_getter' : (_this) => _this.modifierSuper,
        'org.w3c.dom.events.modifierSuper_$external_prop_setter' : (_this, v) => _this.modifierSuper = v,
        'org.w3c.dom.events.modifierSymbol_$external_prop_getter' : (_this) => _this.modifierSymbol,
        'org.w3c.dom.events.modifierSymbol_$external_prop_setter' : (_this, v) => _this.modifierSymbol = v,
        'org.w3c.dom.events.modifierSymbolLock_$external_prop_getter' : (_this) => _this.modifierSymbolLock,
        'org.w3c.dom.events.modifierSymbolLock_$external_prop_setter' : (_this, v) => _this.modifierSymbolLock = v,
        'org.w3c.dom.events.view_$external_prop_getter_1' : (_this) => _this.view,
        'org.w3c.dom.events.view_$external_prop_setter' : (_this, v) => _this.view = v,
        'org.w3c.dom.events.detail_$external_prop_getter_1' : (_this) => _this.detail,
        'org.w3c.dom.events.detail_$external_prop_setter' : (_this, v) => _this.detail = v,
        'org.w3c.dom.AddEventListenerOptions_js_code' : (passive, once, capture) => { return { passive, once, capture }; },
        'org.w3c.dom.Window_$external_fun' : () => new Window(),
        'org.w3c.dom.window_$external_prop_getter' : (_this) => _this.window,
        'org.w3c.dom.self_$external_prop_getter' : (_this) => _this.self,
        'org.w3c.dom.document_$external_prop_getter' : (_this) => _this.document,
        'org.w3c.dom.name_$external_prop_getter' : (_this) => _this.name,
        'org.w3c.dom.name_$external_prop_setter' : (_this, v) => _this.name = v,
        'org.w3c.dom.location_$external_prop_getter' : (_this) => _this.location,
        'org.w3c.dom.history_$external_prop_getter' : (_this) => _this.history,
        'org.w3c.dom.customElements_$external_prop_getter' : (_this) => _this.customElements,
        'org.w3c.dom.locationbar_$external_prop_getter' : (_this) => _this.locationbar,
        'org.w3c.dom.menubar_$external_prop_getter' : (_this) => _this.menubar,
        'org.w3c.dom.personalbar_$external_prop_getter' : (_this) => _this.personalbar,
        'org.w3c.dom.scrollbars_$external_prop_getter' : (_this) => _this.scrollbars,
        'org.w3c.dom.statusbar_$external_prop_getter' : (_this) => _this.statusbar,
        'org.w3c.dom.toolbar_$external_prop_getter' : (_this) => _this.toolbar,
        'org.w3c.dom.status_$external_prop_getter' : (_this) => _this.status,
        'org.w3c.dom.status_$external_prop_setter' : (_this, v) => _this.status = v,
        'org.w3c.dom.closed_$external_prop_getter' : (_this) => _this.closed,
        'org.w3c.dom.frames_$external_prop_getter' : (_this) => _this.frames,
        'org.w3c.dom.length_$external_prop_getter_1' : (_this) => _this.length,
        'org.w3c.dom.top_$external_prop_getter' : (_this) => _this.top,
        'org.w3c.dom.opener_$external_prop_getter' : (_this) => _this.opener,
        'org.w3c.dom.opener_$external_prop_setter' : (_this, v) => _this.opener = v,
        'org.w3c.dom.parent_$external_prop_getter' : (_this) => _this.parent,
        'org.w3c.dom.frameElement_$external_prop_getter' : (_this) => _this.frameElement,
        'org.w3c.dom.navigator_$external_prop_getter' : (_this) => _this.navigator,
        'org.w3c.dom.applicationCache_$external_prop_getter' : (_this) => _this.applicationCache,
        'org.w3c.dom.external_$external_prop_getter' : (_this) => _this.external,
        'org.w3c.dom.screen_$external_prop_getter' : (_this) => _this.screen,
        'org.w3c.dom.innerWidth_$external_prop_getter' : (_this) => _this.innerWidth,
        'org.w3c.dom.innerHeight_$external_prop_getter' : (_this) => _this.innerHeight,
        'org.w3c.dom.scrollX_$external_prop_getter' : (_this) => _this.scrollX,
        'org.w3c.dom.pageXOffset_$external_prop_getter' : (_this) => _this.pageXOffset,
        'org.w3c.dom.scrollY_$external_prop_getter' : (_this) => _this.scrollY,
        'org.w3c.dom.pageYOffset_$external_prop_getter' : (_this) => _this.pageYOffset,
        'org.w3c.dom.screenX_$external_prop_getter' : (_this) => _this.screenX,
        'org.w3c.dom.screenY_$external_prop_getter' : (_this) => _this.screenY,
        'org.w3c.dom.outerWidth_$external_prop_getter' : (_this) => _this.outerWidth,
        'org.w3c.dom.outerHeight_$external_prop_getter' : (_this) => _this.outerHeight,
        'org.w3c.dom.devicePixelRatio_$external_prop_getter' : (_this) => _this.devicePixelRatio,
        'org.w3c.dom.close_$external_fun' : (_this, ) => _this.close(),
        'org.w3c.dom.stop_$external_fun' : (_this, ) => _this.stop(),
        'org.w3c.dom.focus_$external_fun' : (_this, ) => _this.focus(),
        'org.w3c.dom.blur_$external_fun' : (_this, ) => _this.blur(),
        'org.w3c.dom.open_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1, isDefault2) => _this.open(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, isDefault2 ? undefined : p2, ),
        'org.w3c.dom.alert_$external_fun' : (_this, ) => _this.alert(),
        'org.w3c.dom.alert_$external_fun_1' : (_this, p0) => _this.alert(p0),
        'org.w3c.dom.confirm_$external_fun' : (_this, p0, isDefault0) => _this.confirm(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.prompt_$external_fun' : (_this, p0, p1, isDefault0, isDefault1) => _this.prompt(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.dom.print_$external_fun' : (_this, ) => _this.print(),
        'org.w3c.dom.requestAnimationFrame_$external_fun' : (_this, p0) => _this.requestAnimationFrame(p0),
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((Double)->Unit)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Double)->Unit)'](f, p0)),
        'org.w3c.dom.cancelAnimationFrame_$external_fun' : (_this, p0) => _this.cancelAnimationFrame(p0),
        'org.w3c.dom.postMessage_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.postMessage(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.captureEvents_$external_fun' : (_this, ) => _this.captureEvents(),
        'org.w3c.dom.releaseEvents_$external_fun' : (_this, ) => _this.releaseEvents(),
        'org.w3c.dom.matchMedia_$external_fun' : (_this, p0) => _this.matchMedia(p0),
        'org.w3c.dom.moveTo_$external_fun' : (_this, p0, p1) => _this.moveTo(p0, p1),
        'org.w3c.dom.moveBy_$external_fun' : (_this, p0, p1) => _this.moveBy(p0, p1),
        'org.w3c.dom.resizeTo_$external_fun' : (_this, p0, p1) => _this.resizeTo(p0, p1),
        'org.w3c.dom.resizeBy_$external_fun' : (_this, p0, p1) => _this.resizeBy(p0, p1),
        'org.w3c.dom.scroll_$external_fun' : (_this, p0, isDefault0) => _this.scroll(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.scroll_$external_fun_1' : (_this, p0, p1) => _this.scroll(p0, p1),
        'org.w3c.dom.scrollTo_$external_fun' : (_this, p0, isDefault0) => _this.scrollTo(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.scrollTo_$external_fun_1' : (_this, p0, p1) => _this.scrollTo(p0, p1),
        'org.w3c.dom.scrollBy_$external_fun' : (_this, p0, isDefault0) => _this.scrollBy(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.scrollBy_$external_fun_1' : (_this, p0, p1) => _this.scrollBy(p0, p1),
        'org.w3c.dom.getComputedStyle_$external_fun' : (_this, p0, p1, isDefault0) => _this.getComputedStyle(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.Window_$external_class_instanceof' : (x) => x instanceof Window,
        'org.w3c.dom.Window_$external_class_get' : () => Window,
        'org.w3c.dom.onabort_$external_prop_getter' : (_this) => _this.onabort,
        'org.w3c.dom.onabort_$external_prop_setter' : (_this, v) => _this.onabort = v,
        'org.w3c.dom.onblur_$external_prop_getter' : (_this) => _this.onblur,
        'org.w3c.dom.onblur_$external_prop_setter' : (_this, v) => _this.onblur = v,
        'org.w3c.dom.oncancel_$external_prop_getter' : (_this) => _this.oncancel,
        'org.w3c.dom.oncancel_$external_prop_setter' : (_this, v) => _this.oncancel = v,
        'org.w3c.dom.oncanplay_$external_prop_getter' : (_this) => _this.oncanplay,
        'org.w3c.dom.oncanplay_$external_prop_setter' : (_this, v) => _this.oncanplay = v,
        'org.w3c.dom.oncanplaythrough_$external_prop_getter' : (_this) => _this.oncanplaythrough,
        'org.w3c.dom.oncanplaythrough_$external_prop_setter' : (_this, v) => _this.oncanplaythrough = v,
        'org.w3c.dom.onchange_$external_prop_getter' : (_this) => _this.onchange,
        'org.w3c.dom.onchange_$external_prop_setter' : (_this, v) => _this.onchange = v,
        'org.w3c.dom.onclick_$external_prop_getter' : (_this) => _this.onclick,
        'org.w3c.dom.onclick_$external_prop_setter' : (_this, v) => _this.onclick = v,
        'org.w3c.dom.onclose_$external_prop_getter' : (_this) => _this.onclose,
        'org.w3c.dom.onclose_$external_prop_setter' : (_this, v) => _this.onclose = v,
        'org.w3c.dom.oncontextmenu_$external_prop_getter' : (_this) => _this.oncontextmenu,
        'org.w3c.dom.oncontextmenu_$external_prop_setter' : (_this, v) => _this.oncontextmenu = v,
        'org.w3c.dom.oncuechange_$external_prop_getter' : (_this) => _this.oncuechange,
        'org.w3c.dom.oncuechange_$external_prop_setter' : (_this, v) => _this.oncuechange = v,
        'org.w3c.dom.ondblclick_$external_prop_getter' : (_this) => _this.ondblclick,
        'org.w3c.dom.ondblclick_$external_prop_setter' : (_this, v) => _this.ondblclick = v,
        'org.w3c.dom.ondrag_$external_prop_getter' : (_this) => _this.ondrag,
        'org.w3c.dom.ondrag_$external_prop_setter' : (_this, v) => _this.ondrag = v,
        'org.w3c.dom.ondragend_$external_prop_getter' : (_this) => _this.ondragend,
        'org.w3c.dom.ondragend_$external_prop_setter' : (_this, v) => _this.ondragend = v,
        'org.w3c.dom.ondragenter_$external_prop_getter' : (_this) => _this.ondragenter,
        'org.w3c.dom.ondragenter_$external_prop_setter' : (_this, v) => _this.ondragenter = v,
        'org.w3c.dom.ondragexit_$external_prop_getter' : (_this) => _this.ondragexit,
        'org.w3c.dom.ondragexit_$external_prop_setter' : (_this, v) => _this.ondragexit = v,
        'org.w3c.dom.ondragleave_$external_prop_getter' : (_this) => _this.ondragleave,
        'org.w3c.dom.ondragleave_$external_prop_setter' : (_this, v) => _this.ondragleave = v,
        'org.w3c.dom.ondragover_$external_prop_getter' : (_this) => _this.ondragover,
        'org.w3c.dom.ondragover_$external_prop_setter' : (_this, v) => _this.ondragover = v,
        'org.w3c.dom.ondragstart_$external_prop_getter' : (_this) => _this.ondragstart,
        'org.w3c.dom.ondragstart_$external_prop_setter' : (_this, v) => _this.ondragstart = v,
        'org.w3c.dom.ondrop_$external_prop_getter' : (_this) => _this.ondrop,
        'org.w3c.dom.ondrop_$external_prop_setter' : (_this, v) => _this.ondrop = v,
        'org.w3c.dom.ondurationchange_$external_prop_getter' : (_this) => _this.ondurationchange,
        'org.w3c.dom.ondurationchange_$external_prop_setter' : (_this, v) => _this.ondurationchange = v,
        'org.w3c.dom.onemptied_$external_prop_getter' : (_this) => _this.onemptied,
        'org.w3c.dom.onemptied_$external_prop_setter' : (_this, v) => _this.onemptied = v,
        'org.w3c.dom.onended_$external_prop_getter' : (_this) => _this.onended,
        'org.w3c.dom.onended_$external_prop_setter' : (_this, v) => _this.onended = v,
        'org.w3c.dom.onerror_$external_prop_getter' : (_this) => _this.onerror,
        'org.w3c.dom.__callJsClosure_((Js?,String,Int,Int,Js?)->Js?)' : (f, p0, p1, p2, p3, p4) => f(p0, p1, p2, p3, p4),
        'org.w3c.dom.onerror_$external_prop_setter' : (_this, v) => _this.onerror = v,
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((Js?,String,Int,Int,Js?)->Js?)' : (f) => getCachedJsObject(f, (p0, p1, p2, p3, p4) => wasmExports['__callFunction_((Js?,String,Int,Int,Js?)->Js?)'](f, p0, p1, p2, p3, p4)),
        'org.w3c.dom.onfocus_$external_prop_getter' : (_this) => _this.onfocus,
        'org.w3c.dom.onfocus_$external_prop_setter' : (_this, v) => _this.onfocus = v,
        'org.w3c.dom.oninput_$external_prop_getter' : (_this) => _this.oninput,
        'org.w3c.dom.oninput_$external_prop_setter' : (_this, v) => _this.oninput = v,
        'org.w3c.dom.oninvalid_$external_prop_getter' : (_this) => _this.oninvalid,
        'org.w3c.dom.oninvalid_$external_prop_setter' : (_this, v) => _this.oninvalid = v,
        'org.w3c.dom.onkeydown_$external_prop_getter' : (_this) => _this.onkeydown,
        'org.w3c.dom.onkeydown_$external_prop_setter' : (_this, v) => _this.onkeydown = v,
        'org.w3c.dom.onkeypress_$external_prop_getter' : (_this) => _this.onkeypress,
        'org.w3c.dom.onkeypress_$external_prop_setter' : (_this, v) => _this.onkeypress = v,
        'org.w3c.dom.onkeyup_$external_prop_getter' : (_this) => _this.onkeyup,
        'org.w3c.dom.onkeyup_$external_prop_setter' : (_this, v) => _this.onkeyup = v,
        'org.w3c.dom.onload_$external_prop_getter' : (_this) => _this.onload,
        'org.w3c.dom.onload_$external_prop_setter' : (_this, v) => _this.onload = v,
        'org.w3c.dom.onloadeddata_$external_prop_getter' : (_this) => _this.onloadeddata,
        'org.w3c.dom.onloadeddata_$external_prop_setter' : (_this, v) => _this.onloadeddata = v,
        'org.w3c.dom.onloadedmetadata_$external_prop_getter' : (_this) => _this.onloadedmetadata,
        'org.w3c.dom.onloadedmetadata_$external_prop_setter' : (_this, v) => _this.onloadedmetadata = v,
        'org.w3c.dom.onloadend_$external_prop_getter' : (_this) => _this.onloadend,
        'org.w3c.dom.onloadend_$external_prop_setter' : (_this, v) => _this.onloadend = v,
        'org.w3c.dom.onloadstart_$external_prop_getter' : (_this) => _this.onloadstart,
        'org.w3c.dom.onloadstart_$external_prop_setter' : (_this, v) => _this.onloadstart = v,
        'org.w3c.dom.onmousedown_$external_prop_getter' : (_this) => _this.onmousedown,
        'org.w3c.dom.onmousedown_$external_prop_setter' : (_this, v) => _this.onmousedown = v,
        'org.w3c.dom.onmouseenter_$external_prop_getter' : (_this) => _this.onmouseenter,
        'org.w3c.dom.onmouseenter_$external_prop_setter' : (_this, v) => _this.onmouseenter = v,
        'org.w3c.dom.onmouseleave_$external_prop_getter' : (_this) => _this.onmouseleave,
        'org.w3c.dom.onmouseleave_$external_prop_setter' : (_this, v) => _this.onmouseleave = v,
        'org.w3c.dom.onmousemove_$external_prop_getter' : (_this) => _this.onmousemove,
        'org.w3c.dom.onmousemove_$external_prop_setter' : (_this, v) => _this.onmousemove = v,
        'org.w3c.dom.onmouseout_$external_prop_getter' : (_this) => _this.onmouseout,
        'org.w3c.dom.onmouseout_$external_prop_setter' : (_this, v) => _this.onmouseout = v,
        'org.w3c.dom.onmouseover_$external_prop_getter' : (_this) => _this.onmouseover,
        'org.w3c.dom.onmouseover_$external_prop_setter' : (_this, v) => _this.onmouseover = v,
        'org.w3c.dom.onmouseup_$external_prop_getter' : (_this) => _this.onmouseup,
        'org.w3c.dom.onmouseup_$external_prop_setter' : (_this, v) => _this.onmouseup = v,
        'org.w3c.dom.onwheel_$external_prop_getter' : (_this) => _this.onwheel,
        'org.w3c.dom.onwheel_$external_prop_setter' : (_this, v) => _this.onwheel = v,
        'org.w3c.dom.onpause_$external_prop_getter' : (_this) => _this.onpause,
        'org.w3c.dom.onpause_$external_prop_setter' : (_this, v) => _this.onpause = v,
        'org.w3c.dom.onplay_$external_prop_getter' : (_this) => _this.onplay,
        'org.w3c.dom.onplay_$external_prop_setter' : (_this, v) => _this.onplay = v,
        'org.w3c.dom.onplaying_$external_prop_getter' : (_this) => _this.onplaying,
        'org.w3c.dom.onplaying_$external_prop_setter' : (_this, v) => _this.onplaying = v,
        'org.w3c.dom.onprogress_$external_prop_getter' : (_this) => _this.onprogress,
        'org.w3c.dom.onprogress_$external_prop_setter' : (_this, v) => _this.onprogress = v,
        'org.w3c.dom.onratechange_$external_prop_getter' : (_this) => _this.onratechange,
        'org.w3c.dom.onratechange_$external_prop_setter' : (_this, v) => _this.onratechange = v,
        'org.w3c.dom.onreset_$external_prop_getter' : (_this) => _this.onreset,
        'org.w3c.dom.onreset_$external_prop_setter' : (_this, v) => _this.onreset = v,
        'org.w3c.dom.onresize_$external_prop_getter' : (_this) => _this.onresize,
        'org.w3c.dom.onresize_$external_prop_setter' : (_this, v) => _this.onresize = v,
        'org.w3c.dom.onscroll_$external_prop_getter' : (_this) => _this.onscroll,
        'org.w3c.dom.onscroll_$external_prop_setter' : (_this, v) => _this.onscroll = v,
        'org.w3c.dom.onseeked_$external_prop_getter' : (_this) => _this.onseeked,
        'org.w3c.dom.onseeked_$external_prop_setter' : (_this, v) => _this.onseeked = v,
        'org.w3c.dom.onseeking_$external_prop_getter' : (_this) => _this.onseeking,
        'org.w3c.dom.onseeking_$external_prop_setter' : (_this, v) => _this.onseeking = v,
        'org.w3c.dom.onselect_$external_prop_getter' : (_this) => _this.onselect,
        'org.w3c.dom.onselect_$external_prop_setter' : (_this, v) => _this.onselect = v,
        'org.w3c.dom.onshow_$external_prop_getter' : (_this) => _this.onshow,
        'org.w3c.dom.onshow_$external_prop_setter' : (_this, v) => _this.onshow = v,
        'org.w3c.dom.onstalled_$external_prop_getter' : (_this) => _this.onstalled,
        'org.w3c.dom.onstalled_$external_prop_setter' : (_this, v) => _this.onstalled = v,
        'org.w3c.dom.onsubmit_$external_prop_getter' : (_this) => _this.onsubmit,
        'org.w3c.dom.onsubmit_$external_prop_setter' : (_this, v) => _this.onsubmit = v,
        'org.w3c.dom.onsuspend_$external_prop_getter' : (_this) => _this.onsuspend,
        'org.w3c.dom.onsuspend_$external_prop_setter' : (_this, v) => _this.onsuspend = v,
        'org.w3c.dom.ontimeupdate_$external_prop_getter' : (_this) => _this.ontimeupdate,
        'org.w3c.dom.ontimeupdate_$external_prop_setter' : (_this, v) => _this.ontimeupdate = v,
        'org.w3c.dom.ontoggle_$external_prop_getter' : (_this) => _this.ontoggle,
        'org.w3c.dom.ontoggle_$external_prop_setter' : (_this, v) => _this.ontoggle = v,
        'org.w3c.dom.onvolumechange_$external_prop_getter' : (_this) => _this.onvolumechange,
        'org.w3c.dom.onvolumechange_$external_prop_setter' : (_this, v) => _this.onvolumechange = v,
        'org.w3c.dom.onwaiting_$external_prop_getter' : (_this) => _this.onwaiting,
        'org.w3c.dom.onwaiting_$external_prop_setter' : (_this, v) => _this.onwaiting = v,
        'org.w3c.dom.ongotpointercapture_$external_prop_getter' : (_this) => _this.ongotpointercapture,
        'org.w3c.dom.ongotpointercapture_$external_prop_setter' : (_this, v) => _this.ongotpointercapture = v,
        'org.w3c.dom.onlostpointercapture_$external_prop_getter' : (_this) => _this.onlostpointercapture,
        'org.w3c.dom.onlostpointercapture_$external_prop_setter' : (_this, v) => _this.onlostpointercapture = v,
        'org.w3c.dom.onpointerdown_$external_prop_getter' : (_this) => _this.onpointerdown,
        'org.w3c.dom.onpointerdown_$external_prop_setter' : (_this, v) => _this.onpointerdown = v,
        'org.w3c.dom.onpointermove_$external_prop_getter' : (_this) => _this.onpointermove,
        'org.w3c.dom.onpointermove_$external_prop_setter' : (_this, v) => _this.onpointermove = v,
        'org.w3c.dom.onpointerup_$external_prop_getter' : (_this) => _this.onpointerup,
        'org.w3c.dom.onpointerup_$external_prop_setter' : (_this, v) => _this.onpointerup = v,
        'org.w3c.dom.onpointercancel_$external_prop_getter' : (_this) => _this.onpointercancel,
        'org.w3c.dom.onpointercancel_$external_prop_setter' : (_this, v) => _this.onpointercancel = v,
        'org.w3c.dom.onpointerover_$external_prop_getter' : (_this) => _this.onpointerover,
        'org.w3c.dom.onpointerover_$external_prop_setter' : (_this, v) => _this.onpointerover = v,
        'org.w3c.dom.onpointerout_$external_prop_getter' : (_this) => _this.onpointerout,
        'org.w3c.dom.onpointerout_$external_prop_setter' : (_this, v) => _this.onpointerout = v,
        'org.w3c.dom.onpointerenter_$external_prop_getter' : (_this) => _this.onpointerenter,
        'org.w3c.dom.onpointerenter_$external_prop_setter' : (_this, v) => _this.onpointerenter = v,
        'org.w3c.dom.onpointerleave_$external_prop_getter' : (_this) => _this.onpointerleave,
        'org.w3c.dom.onpointerleave_$external_prop_setter' : (_this, v) => _this.onpointerleave = v,
        'org.w3c.dom.onafterprint_$external_prop_getter' : (_this) => _this.onafterprint,
        'org.w3c.dom.onafterprint_$external_prop_setter' : (_this, v) => _this.onafterprint = v,
        'org.w3c.dom.onbeforeprint_$external_prop_getter' : (_this) => _this.onbeforeprint,
        'org.w3c.dom.onbeforeprint_$external_prop_setter' : (_this, v) => _this.onbeforeprint = v,
        'org.w3c.dom.onbeforeunload_$external_prop_getter' : (_this) => _this.onbeforeunload,
        'org.w3c.dom.__callJsClosure_((Js)->String?)' : (f, p0) => f(p0),
        'org.w3c.dom.onbeforeunload_$external_prop_setter' : (_this, v) => _this.onbeforeunload = v,
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((Js)->String?)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js)->String?)'](f, p0)),
        'org.w3c.dom.onhashchange_$external_prop_getter' : (_this) => _this.onhashchange,
        'org.w3c.dom.onhashchange_$external_prop_setter' : (_this, v) => _this.onhashchange = v,
        'org.w3c.dom.onlanguagechange_$external_prop_getter' : (_this) => _this.onlanguagechange,
        'org.w3c.dom.onlanguagechange_$external_prop_setter' : (_this, v) => _this.onlanguagechange = v,
        'org.w3c.dom.onmessage_$external_prop_getter' : (_this) => _this.onmessage,
        'org.w3c.dom.onmessage_$external_prop_setter' : (_this, v) => _this.onmessage = v,
        'org.w3c.dom.onoffline_$external_prop_getter' : (_this) => _this.onoffline,
        'org.w3c.dom.onoffline_$external_prop_setter' : (_this, v) => _this.onoffline = v,
        'org.w3c.dom.ononline_$external_prop_getter' : (_this) => _this.ononline,
        'org.w3c.dom.ononline_$external_prop_setter' : (_this, v) => _this.ononline = v,
        'org.w3c.dom.onpagehide_$external_prop_getter' : (_this) => _this.onpagehide,
        'org.w3c.dom.onpagehide_$external_prop_setter' : (_this, v) => _this.onpagehide = v,
        'org.w3c.dom.onpageshow_$external_prop_getter' : (_this) => _this.onpageshow,
        'org.w3c.dom.onpageshow_$external_prop_setter' : (_this, v) => _this.onpageshow = v,
        'org.w3c.dom.onpopstate_$external_prop_getter' : (_this) => _this.onpopstate,
        'org.w3c.dom.onpopstate_$external_prop_setter' : (_this, v) => _this.onpopstate = v,
        'org.w3c.dom.onrejectionhandled_$external_prop_getter' : (_this) => _this.onrejectionhandled,
        'org.w3c.dom.onrejectionhandled_$external_prop_setter' : (_this, v) => _this.onrejectionhandled = v,
        'org.w3c.dom.onstorage_$external_prop_getter' : (_this) => _this.onstorage,
        'org.w3c.dom.onstorage_$external_prop_setter' : (_this, v) => _this.onstorage = v,
        'org.w3c.dom.onunhandledrejection_$external_prop_getter' : (_this) => _this.onunhandledrejection,
        'org.w3c.dom.onunhandledrejection_$external_prop_setter' : (_this, v) => _this.onunhandledrejection = v,
        'org.w3c.dom.onunload_$external_prop_getter' : (_this) => _this.onunload,
        'org.w3c.dom.onunload_$external_prop_setter' : (_this, v) => _this.onunload = v,
        'org.w3c.dom.origin_$external_prop_getter' : (_this) => _this.origin,
        'org.w3c.dom.caches_$external_prop_getter' : (_this) => _this.caches,
        'org.w3c.dom.btoa_$external_fun' : (_this, p0) => _this.btoa(p0),
        'org.w3c.dom.atob_$external_fun' : (_this, p0) => _this.atob(p0),
        'org.w3c.dom.setTimeout_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.setTimeout(p0, isDefault0 ? undefined : p1, ...p2, ),
        'org.w3c.dom.setTimeout_$external_fun_1' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.setTimeout(p0, isDefault0 ? undefined : p1, ...p2, ),
        'org.w3c.dom.__convertKotlinClosureToJsClosure_(()->Js?)' : (f) => getCachedJsObject(f, () => wasmExports['__callFunction_(()->Js?)'](f, )),
        'org.w3c.dom.clearTimeout_$external_fun' : (_this, p0, isDefault0) => _this.clearTimeout(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.setInterval_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.setInterval(p0, isDefault0 ? undefined : p1, ...p2, ),
        'org.w3c.dom.setInterval_$external_fun_1' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.setInterval(p0, isDefault0 ? undefined : p1, ...p2, ),
        'org.w3c.dom.clearInterval_$external_fun' : (_this, p0, isDefault0) => _this.clearInterval(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.createImageBitmap_$external_fun' : (_this, p0, p1, isDefault0) => _this.createImageBitmap(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.createImageBitmap_$external_fun_1' : (_this, p0, p1, p2, p3, p4, p5, isDefault0) => _this.createImageBitmap(p0, p1, p2, p3, p4, isDefault0 ? undefined : p5, ),
        'org.w3c.dom.fetch_$external_fun' : (_this, p0, p1, isDefault0) => _this.fetch(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.fetch_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.fetch(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.sessionStorage_$external_prop_getter' : (_this) => _this.sessionStorage,
        'org.w3c.dom.localStorage_$external_prop_getter' : (_this) => _this.localStorage,
        'org.w3c.dom.Document_$external_fun' : () => new Document(),
        'org.w3c.dom.implementation_$external_prop_getter' : (_this) => _this.implementation,
        'org.w3c.dom.URL_$external_prop_getter' : (_this) => _this.URL,
        'org.w3c.dom.documentURI_$external_prop_getter' : (_this) => _this.documentURI,
        'org.w3c.dom.origin_$external_prop_getter_1' : (_this) => _this.origin,
        'org.w3c.dom.compatMode_$external_prop_getter' : (_this) => _this.compatMode,
        'org.w3c.dom.characterSet_$external_prop_getter' : (_this) => _this.characterSet,
        'org.w3c.dom.charset_$external_prop_getter' : (_this) => _this.charset,
        'org.w3c.dom.inputEncoding_$external_prop_getter' : (_this) => _this.inputEncoding,
        'org.w3c.dom.contentType_$external_prop_getter' : (_this) => _this.contentType,
        'org.w3c.dom.doctype_$external_prop_getter' : (_this) => _this.doctype,
        'org.w3c.dom.documentElement_$external_prop_getter' : (_this) => _this.documentElement,
        'org.w3c.dom.location_$external_prop_getter_1' : (_this) => _this.location,
        'org.w3c.dom.domain_$external_prop_getter' : (_this) => _this.domain,
        'org.w3c.dom.domain_$external_prop_setter' : (_this, v) => _this.domain = v,
        'org.w3c.dom.referrer_$external_prop_getter' : (_this) => _this.referrer,
        'org.w3c.dom.cookie_$external_prop_getter' : (_this) => _this.cookie,
        'org.w3c.dom.cookie_$external_prop_setter' : (_this, v) => _this.cookie = v,
        'org.w3c.dom.lastModified_$external_prop_getter' : (_this) => _this.lastModified,
        'org.w3c.dom.readyState_$external_prop_getter' : (_this) => _this.readyState,
        'org.w3c.dom.title_$external_prop_getter' : (_this) => _this.title,
        'org.w3c.dom.title_$external_prop_setter' : (_this, v) => _this.title = v,
        'org.w3c.dom.dir_$external_prop_getter' : (_this) => _this.dir,
        'org.w3c.dom.dir_$external_prop_setter' : (_this, v) => _this.dir = v,
        'org.w3c.dom.body_$external_prop_getter' : (_this) => _this.body,
        'org.w3c.dom.body_$external_prop_setter' : (_this, v) => _this.body = v,
        'org.w3c.dom.head_$external_prop_getter' : (_this) => _this.head,
        'org.w3c.dom.images_$external_prop_getter' : (_this) => _this.images,
        'org.w3c.dom.embeds_$external_prop_getter' : (_this) => _this.embeds,
        'org.w3c.dom.plugins_$external_prop_getter' : (_this) => _this.plugins,
        'org.w3c.dom.links_$external_prop_getter' : (_this) => _this.links,
        'org.w3c.dom.forms_$external_prop_getter' : (_this) => _this.forms,
        'org.w3c.dom.scripts_$external_prop_getter' : (_this) => _this.scripts,
        'org.w3c.dom.currentScript_$external_prop_getter' : (_this) => _this.currentScript,
        'org.w3c.dom.defaultView_$external_prop_getter' : (_this) => _this.defaultView,
        'org.w3c.dom.activeElement_$external_prop_getter' : (_this) => _this.activeElement,
        'org.w3c.dom.designMode_$external_prop_getter' : (_this) => _this.designMode,
        'org.w3c.dom.designMode_$external_prop_setter' : (_this, v) => _this.designMode = v,
        'org.w3c.dom.onreadystatechange_$external_prop_getter' : (_this) => _this.onreadystatechange,
        'org.w3c.dom.onreadystatechange_$external_prop_setter' : (_this, v) => _this.onreadystatechange = v,
        'org.w3c.dom.fgColor_$external_prop_getter' : (_this) => _this.fgColor,
        'org.w3c.dom.fgColor_$external_prop_setter' : (_this, v) => _this.fgColor = v,
        'org.w3c.dom.linkColor_$external_prop_getter' : (_this) => _this.linkColor,
        'org.w3c.dom.linkColor_$external_prop_setter' : (_this, v) => _this.linkColor = v,
        'org.w3c.dom.vlinkColor_$external_prop_getter' : (_this) => _this.vlinkColor,
        'org.w3c.dom.vlinkColor_$external_prop_setter' : (_this, v) => _this.vlinkColor = v,
        'org.w3c.dom.alinkColor_$external_prop_getter' : (_this) => _this.alinkColor,
        'org.w3c.dom.alinkColor_$external_prop_setter' : (_this, v) => _this.alinkColor = v,
        'org.w3c.dom.bgColor_$external_prop_getter' : (_this) => _this.bgColor,
        'org.w3c.dom.bgColor_$external_prop_setter' : (_this, v) => _this.bgColor = v,
        'org.w3c.dom.anchors_$external_prop_getter' : (_this) => _this.anchors,
        'org.w3c.dom.applets_$external_prop_getter' : (_this) => _this.applets,
        'org.w3c.dom.all_$external_prop_getter' : (_this) => _this.all,
        'org.w3c.dom.scrollingElement_$external_prop_getter' : (_this) => _this.scrollingElement,
        'org.w3c.dom.styleSheets_$external_prop_getter' : (_this) => _this.styleSheets,
        'org.w3c.dom.rootElement_$external_prop_getter' : (_this) => _this.rootElement,
        'org.w3c.dom.fullscreenEnabled_$external_prop_getter' : (_this) => _this.fullscreenEnabled,
        'org.w3c.dom.fullscreen_$external_prop_getter' : (_this) => _this.fullscreen,
        'org.w3c.dom.onfullscreenchange_$external_prop_getter' : (_this) => _this.onfullscreenchange,
        'org.w3c.dom.onfullscreenchange_$external_prop_setter' : (_this, v) => _this.onfullscreenchange = v,
        'org.w3c.dom.onfullscreenerror_$external_prop_getter' : (_this) => _this.onfullscreenerror,
        'org.w3c.dom.onfullscreenerror_$external_prop_setter' : (_this, v) => _this.onfullscreenerror = v,
        'org.w3c.dom.onabort_$external_prop_getter_1' : (_this) => _this.onabort,
        'org.w3c.dom.onabort_$external_prop_setter_1' : (_this, v) => _this.onabort = v,
        'org.w3c.dom.onblur_$external_prop_getter_1' : (_this) => _this.onblur,
        'org.w3c.dom.onblur_$external_prop_setter_1' : (_this, v) => _this.onblur = v,
        'org.w3c.dom.oncancel_$external_prop_getter_1' : (_this) => _this.oncancel,
        'org.w3c.dom.oncancel_$external_prop_setter_1' : (_this, v) => _this.oncancel = v,
        'org.w3c.dom.oncanplay_$external_prop_getter_1' : (_this) => _this.oncanplay,
        'org.w3c.dom.oncanplay_$external_prop_setter_1' : (_this, v) => _this.oncanplay = v,
        'org.w3c.dom.oncanplaythrough_$external_prop_getter_1' : (_this) => _this.oncanplaythrough,
        'org.w3c.dom.oncanplaythrough_$external_prop_setter_1' : (_this, v) => _this.oncanplaythrough = v,
        'org.w3c.dom.onchange_$external_prop_getter_1' : (_this) => _this.onchange,
        'org.w3c.dom.onchange_$external_prop_setter_1' : (_this, v) => _this.onchange = v,
        'org.w3c.dom.onclick_$external_prop_getter_1' : (_this) => _this.onclick,
        'org.w3c.dom.onclick_$external_prop_setter_1' : (_this, v) => _this.onclick = v,
        'org.w3c.dom.onclose_$external_prop_getter_1' : (_this) => _this.onclose,
        'org.w3c.dom.onclose_$external_prop_setter_1' : (_this, v) => _this.onclose = v,
        'org.w3c.dom.oncontextmenu_$external_prop_getter_1' : (_this) => _this.oncontextmenu,
        'org.w3c.dom.oncontextmenu_$external_prop_setter_1' : (_this, v) => _this.oncontextmenu = v,
        'org.w3c.dom.oncuechange_$external_prop_getter_1' : (_this) => _this.oncuechange,
        'org.w3c.dom.oncuechange_$external_prop_setter_1' : (_this, v) => _this.oncuechange = v,
        'org.w3c.dom.ondblclick_$external_prop_getter_1' : (_this) => _this.ondblclick,
        'org.w3c.dom.ondblclick_$external_prop_setter_1' : (_this, v) => _this.ondblclick = v,
        'org.w3c.dom.ondrag_$external_prop_getter_1' : (_this) => _this.ondrag,
        'org.w3c.dom.ondrag_$external_prop_setter_1' : (_this, v) => _this.ondrag = v,
        'org.w3c.dom.ondragend_$external_prop_getter_1' : (_this) => _this.ondragend,
        'org.w3c.dom.ondragend_$external_prop_setter_1' : (_this, v) => _this.ondragend = v,
        'org.w3c.dom.ondragenter_$external_prop_getter_1' : (_this) => _this.ondragenter,
        'org.w3c.dom.ondragenter_$external_prop_setter_1' : (_this, v) => _this.ondragenter = v,
        'org.w3c.dom.ondragexit_$external_prop_getter_1' : (_this) => _this.ondragexit,
        'org.w3c.dom.ondragexit_$external_prop_setter_1' : (_this, v) => _this.ondragexit = v,
        'org.w3c.dom.ondragleave_$external_prop_getter_1' : (_this) => _this.ondragleave,
        'org.w3c.dom.ondragleave_$external_prop_setter_1' : (_this, v) => _this.ondragleave = v,
        'org.w3c.dom.ondragover_$external_prop_getter_1' : (_this) => _this.ondragover,
        'org.w3c.dom.ondragover_$external_prop_setter_1' : (_this, v) => _this.ondragover = v,
        'org.w3c.dom.ondragstart_$external_prop_getter_1' : (_this) => _this.ondragstart,
        'org.w3c.dom.ondragstart_$external_prop_setter_1' : (_this, v) => _this.ondragstart = v,
        'org.w3c.dom.ondrop_$external_prop_getter_1' : (_this) => _this.ondrop,
        'org.w3c.dom.ondrop_$external_prop_setter_1' : (_this, v) => _this.ondrop = v,
        'org.w3c.dom.ondurationchange_$external_prop_getter_1' : (_this) => _this.ondurationchange,
        'org.w3c.dom.ondurationchange_$external_prop_setter_1' : (_this, v) => _this.ondurationchange = v,
        'org.w3c.dom.onemptied_$external_prop_getter_1' : (_this) => _this.onemptied,
        'org.w3c.dom.onemptied_$external_prop_setter_1' : (_this, v) => _this.onemptied = v,
        'org.w3c.dom.onended_$external_prop_getter_1' : (_this) => _this.onended,
        'org.w3c.dom.onended_$external_prop_setter_1' : (_this, v) => _this.onended = v,
        'org.w3c.dom.onerror_$external_prop_getter_1' : (_this) => _this.onerror,
        'org.w3c.dom.onerror_$external_prop_setter_1' : (_this, v) => _this.onerror = v,
        'org.w3c.dom.onfocus_$external_prop_getter_1' : (_this) => _this.onfocus,
        'org.w3c.dom.onfocus_$external_prop_setter_1' : (_this, v) => _this.onfocus = v,
        'org.w3c.dom.oninput_$external_prop_getter_1' : (_this) => _this.oninput,
        'org.w3c.dom.oninput_$external_prop_setter_1' : (_this, v) => _this.oninput = v,
        'org.w3c.dom.oninvalid_$external_prop_getter_1' : (_this) => _this.oninvalid,
        'org.w3c.dom.oninvalid_$external_prop_setter_1' : (_this, v) => _this.oninvalid = v,
        'org.w3c.dom.onkeydown_$external_prop_getter_1' : (_this) => _this.onkeydown,
        'org.w3c.dom.onkeydown_$external_prop_setter_1' : (_this, v) => _this.onkeydown = v,
        'org.w3c.dom.onkeypress_$external_prop_getter_1' : (_this) => _this.onkeypress,
        'org.w3c.dom.onkeypress_$external_prop_setter_1' : (_this, v) => _this.onkeypress = v,
        'org.w3c.dom.onkeyup_$external_prop_getter_1' : (_this) => _this.onkeyup,
        'org.w3c.dom.onkeyup_$external_prop_setter_1' : (_this, v) => _this.onkeyup = v,
        'org.w3c.dom.onload_$external_prop_getter_1' : (_this) => _this.onload,
        'org.w3c.dom.onload_$external_prop_setter_1' : (_this, v) => _this.onload = v,
        'org.w3c.dom.onloadeddata_$external_prop_getter_1' : (_this) => _this.onloadeddata,
        'org.w3c.dom.onloadeddata_$external_prop_setter_1' : (_this, v) => _this.onloadeddata = v,
        'org.w3c.dom.onloadedmetadata_$external_prop_getter_1' : (_this) => _this.onloadedmetadata,
        'org.w3c.dom.onloadedmetadata_$external_prop_setter_1' : (_this, v) => _this.onloadedmetadata = v,
        'org.w3c.dom.onloadend_$external_prop_getter_1' : (_this) => _this.onloadend,
        'org.w3c.dom.onloadend_$external_prop_setter_1' : (_this, v) => _this.onloadend = v,
        'org.w3c.dom.onloadstart_$external_prop_getter_1' : (_this) => _this.onloadstart,
        'org.w3c.dom.onloadstart_$external_prop_setter_1' : (_this, v) => _this.onloadstart = v,
        'org.w3c.dom.onmousedown_$external_prop_getter_1' : (_this) => _this.onmousedown,
        'org.w3c.dom.onmousedown_$external_prop_setter_1' : (_this, v) => _this.onmousedown = v,
        'org.w3c.dom.onmouseenter_$external_prop_getter_1' : (_this) => _this.onmouseenter,
        'org.w3c.dom.onmouseenter_$external_prop_setter_1' : (_this, v) => _this.onmouseenter = v,
        'org.w3c.dom.onmouseleave_$external_prop_getter_1' : (_this) => _this.onmouseleave,
        'org.w3c.dom.onmouseleave_$external_prop_setter_1' : (_this, v) => _this.onmouseleave = v,
        'org.w3c.dom.onmousemove_$external_prop_getter_1' : (_this) => _this.onmousemove,
        'org.w3c.dom.onmousemove_$external_prop_setter_1' : (_this, v) => _this.onmousemove = v,
        'org.w3c.dom.onmouseout_$external_prop_getter_1' : (_this) => _this.onmouseout,
        'org.w3c.dom.onmouseout_$external_prop_setter_1' : (_this, v) => _this.onmouseout = v,
        'org.w3c.dom.onmouseover_$external_prop_getter_1' : (_this) => _this.onmouseover,
        'org.w3c.dom.onmouseover_$external_prop_setter_1' : (_this, v) => _this.onmouseover = v,
        'org.w3c.dom.onmouseup_$external_prop_getter_1' : (_this) => _this.onmouseup,
        'org.w3c.dom.onmouseup_$external_prop_setter_1' : (_this, v) => _this.onmouseup = v,
        'org.w3c.dom.onwheel_$external_prop_getter_1' : (_this) => _this.onwheel,
        'org.w3c.dom.onwheel_$external_prop_setter_1' : (_this, v) => _this.onwheel = v,
        'org.w3c.dom.onpause_$external_prop_getter_1' : (_this) => _this.onpause,
        'org.w3c.dom.onpause_$external_prop_setter_1' : (_this, v) => _this.onpause = v,
        'org.w3c.dom.onplay_$external_prop_getter_1' : (_this) => _this.onplay,
        'org.w3c.dom.onplay_$external_prop_setter_1' : (_this, v) => _this.onplay = v,
        'org.w3c.dom.onplaying_$external_prop_getter_1' : (_this) => _this.onplaying,
        'org.w3c.dom.onplaying_$external_prop_setter_1' : (_this, v) => _this.onplaying = v,
        'org.w3c.dom.onprogress_$external_prop_getter_1' : (_this) => _this.onprogress,
        'org.w3c.dom.onprogress_$external_prop_setter_1' : (_this, v) => _this.onprogress = v,
        'org.w3c.dom.onratechange_$external_prop_getter_1' : (_this) => _this.onratechange,
        'org.w3c.dom.onratechange_$external_prop_setter_1' : (_this, v) => _this.onratechange = v,
        'org.w3c.dom.onreset_$external_prop_getter_1' : (_this) => _this.onreset,
        'org.w3c.dom.onreset_$external_prop_setter_1' : (_this, v) => _this.onreset = v,
        'org.w3c.dom.onresize_$external_prop_getter_1' : (_this) => _this.onresize,
        'org.w3c.dom.onresize_$external_prop_setter_1' : (_this, v) => _this.onresize = v,
        'org.w3c.dom.onscroll_$external_prop_getter_1' : (_this) => _this.onscroll,
        'org.w3c.dom.onscroll_$external_prop_setter_1' : (_this, v) => _this.onscroll = v,
        'org.w3c.dom.onseeked_$external_prop_getter_1' : (_this) => _this.onseeked,
        'org.w3c.dom.onseeked_$external_prop_setter_1' : (_this, v) => _this.onseeked = v,
        'org.w3c.dom.onseeking_$external_prop_getter_1' : (_this) => _this.onseeking,
        'org.w3c.dom.onseeking_$external_prop_setter_1' : (_this, v) => _this.onseeking = v,
        'org.w3c.dom.onselect_$external_prop_getter_1' : (_this) => _this.onselect,
        'org.w3c.dom.onselect_$external_prop_setter_1' : (_this, v) => _this.onselect = v,
        'org.w3c.dom.onshow_$external_prop_getter_1' : (_this) => _this.onshow,
        'org.w3c.dom.onshow_$external_prop_setter_1' : (_this, v) => _this.onshow = v,
        'org.w3c.dom.onstalled_$external_prop_getter_1' : (_this) => _this.onstalled,
        'org.w3c.dom.onstalled_$external_prop_setter_1' : (_this, v) => _this.onstalled = v,
        'org.w3c.dom.onsubmit_$external_prop_getter_1' : (_this) => _this.onsubmit,
        'org.w3c.dom.onsubmit_$external_prop_setter_1' : (_this, v) => _this.onsubmit = v,
        'org.w3c.dom.onsuspend_$external_prop_getter_1' : (_this) => _this.onsuspend,
        'org.w3c.dom.onsuspend_$external_prop_setter_1' : (_this, v) => _this.onsuspend = v,
        'org.w3c.dom.ontimeupdate_$external_prop_getter_1' : (_this) => _this.ontimeupdate,
        'org.w3c.dom.ontimeupdate_$external_prop_setter_1' : (_this, v) => _this.ontimeupdate = v,
        'org.w3c.dom.ontoggle_$external_prop_getter_1' : (_this) => _this.ontoggle,
        'org.w3c.dom.ontoggle_$external_prop_setter_1' : (_this, v) => _this.ontoggle = v,
        'org.w3c.dom.onvolumechange_$external_prop_getter_1' : (_this) => _this.onvolumechange,
        'org.w3c.dom.onvolumechange_$external_prop_setter_1' : (_this, v) => _this.onvolumechange = v,
        'org.w3c.dom.onwaiting_$external_prop_getter_1' : (_this) => _this.onwaiting,
        'org.w3c.dom.onwaiting_$external_prop_setter_1' : (_this, v) => _this.onwaiting = v,
        'org.w3c.dom.ongotpointercapture_$external_prop_getter_1' : (_this) => _this.ongotpointercapture,
        'org.w3c.dom.ongotpointercapture_$external_prop_setter_1' : (_this, v) => _this.ongotpointercapture = v,
        'org.w3c.dom.onlostpointercapture_$external_prop_getter_1' : (_this) => _this.onlostpointercapture,
        'org.w3c.dom.onlostpointercapture_$external_prop_setter_1' : (_this, v) => _this.onlostpointercapture = v,
        'org.w3c.dom.onpointerdown_$external_prop_getter_1' : (_this) => _this.onpointerdown,
        'org.w3c.dom.onpointerdown_$external_prop_setter_1' : (_this, v) => _this.onpointerdown = v,
        'org.w3c.dom.onpointermove_$external_prop_getter_1' : (_this) => _this.onpointermove,
        'org.w3c.dom.onpointermove_$external_prop_setter_1' : (_this, v) => _this.onpointermove = v,
        'org.w3c.dom.onpointerup_$external_prop_getter_1' : (_this) => _this.onpointerup,
        'org.w3c.dom.onpointerup_$external_prop_setter_1' : (_this, v) => _this.onpointerup = v,
        'org.w3c.dom.onpointercancel_$external_prop_getter_1' : (_this) => _this.onpointercancel,
        'org.w3c.dom.onpointercancel_$external_prop_setter_1' : (_this, v) => _this.onpointercancel = v,
        'org.w3c.dom.onpointerover_$external_prop_getter_1' : (_this) => _this.onpointerover,
        'org.w3c.dom.onpointerover_$external_prop_setter_1' : (_this, v) => _this.onpointerover = v,
        'org.w3c.dom.onpointerout_$external_prop_getter_1' : (_this) => _this.onpointerout,
        'org.w3c.dom.onpointerout_$external_prop_setter_1' : (_this, v) => _this.onpointerout = v,
        'org.w3c.dom.onpointerenter_$external_prop_getter_1' : (_this) => _this.onpointerenter,
        'org.w3c.dom.onpointerenter_$external_prop_setter_1' : (_this, v) => _this.onpointerenter = v,
        'org.w3c.dom.onpointerleave_$external_prop_getter_1' : (_this) => _this.onpointerleave,
        'org.w3c.dom.onpointerleave_$external_prop_setter_1' : (_this, v) => _this.onpointerleave = v,
        'org.w3c.dom.oncopy_$external_prop_getter' : (_this) => _this.oncopy,
        'org.w3c.dom.oncopy_$external_prop_setter' : (_this, v) => _this.oncopy = v,
        'org.w3c.dom.oncut_$external_prop_getter' : (_this) => _this.oncut,
        'org.w3c.dom.oncut_$external_prop_setter' : (_this, v) => _this.oncut = v,
        'org.w3c.dom.onpaste_$external_prop_getter' : (_this) => _this.onpaste,
        'org.w3c.dom.onpaste_$external_prop_setter' : (_this, v) => _this.onpaste = v,
        'org.w3c.dom.fullscreenElement_$external_prop_getter' : (_this) => _this.fullscreenElement,
        'org.w3c.dom.children_$external_prop_getter' : (_this) => _this.children,
        'org.w3c.dom.firstElementChild_$external_prop_getter' : (_this) => _this.firstElementChild,
        'org.w3c.dom.lastElementChild_$external_prop_getter' : (_this) => _this.lastElementChild,
        'org.w3c.dom.childElementCount_$external_prop_getter' : (_this) => _this.childElementCount,
        'org.w3c.dom.getElementsByTagName_$external_fun' : (_this, p0) => _this.getElementsByTagName(p0),
        'org.w3c.dom.getElementsByTagNameNS_$external_fun' : (_this, p0, p1) => _this.getElementsByTagNameNS(p0, p1),
        'org.w3c.dom.getElementsByClassName_$external_fun' : (_this, p0) => _this.getElementsByClassName(p0),
        'org.w3c.dom.createElement_$external_fun' : (_this, p0, p1, isDefault0) => _this.createElement(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.createElementNS_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.createElementNS(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.createDocumentFragment_$external_fun' : (_this, ) => _this.createDocumentFragment(),
        'org.w3c.dom.createTextNode_$external_fun' : (_this, p0) => _this.createTextNode(p0),
        'org.w3c.dom.createCDATASection_$external_fun' : (_this, p0) => _this.createCDATASection(p0),
        'org.w3c.dom.createComment_$external_fun' : (_this, p0) => _this.createComment(p0),
        'org.w3c.dom.createProcessingInstruction_$external_fun' : (_this, p0, p1) => _this.createProcessingInstruction(p0, p1),
        'org.w3c.dom.importNode_$external_fun' : (_this, p0, p1, isDefault0) => _this.importNode(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.adoptNode_$external_fun' : (_this, p0) => _this.adoptNode(p0),
        'org.w3c.dom.createAttribute_$external_fun' : (_this, p0) => _this.createAttribute(p0),
        'org.w3c.dom.createAttributeNS_$external_fun' : (_this, p0, p1) => _this.createAttributeNS(p0, p1),
        'org.w3c.dom.createEvent_$external_fun' : (_this, p0) => _this.createEvent(p0),
        'org.w3c.dom.createRange_$external_fun' : (_this, ) => _this.createRange(),
        'org.w3c.dom.createNodeIterator_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.createNodeIterator(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.createNodeIterator_$external_fun_1' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.createNodeIterator(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((Js)->Short)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js)->Short)'](f, p0)),
        'org.w3c.dom.createTreeWalker_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.createTreeWalker(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.createTreeWalker_$external_fun_1' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.createTreeWalker(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.getElementsByName_$external_fun' : (_this, p0) => _this.getElementsByName(p0),
        'org.w3c.dom.open_$external_fun_1' : (_this, p0, p1, isDefault0, isDefault1) => _this.open(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.dom.open_$external_fun_2' : (_this, p0, p1, p2) => _this.open(p0, p1, p2),
        'org.w3c.dom.close_$external_fun_1' : (_this, ) => _this.close(),
        'org.w3c.dom.write_$external_fun' : (_this, p0) => _this.write(...p0),
        'org.w3c.dom.writeln_$external_fun' : (_this, p0) => _this.writeln(...p0),
        'org.w3c.dom.hasFocus_$external_fun' : (_this, ) => _this.hasFocus(),
        'org.w3c.dom.execCommand_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.execCommand(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.queryCommandEnabled_$external_fun' : (_this, p0) => _this.queryCommandEnabled(p0),
        'org.w3c.dom.queryCommandIndeterm_$external_fun' : (_this, p0) => _this.queryCommandIndeterm(p0),
        'org.w3c.dom.queryCommandState_$external_fun' : (_this, p0) => _this.queryCommandState(p0),
        'org.w3c.dom.queryCommandSupported_$external_fun' : (_this, p0) => _this.queryCommandSupported(p0),
        'org.w3c.dom.queryCommandValue_$external_fun' : (_this, p0) => _this.queryCommandValue(p0),
        'org.w3c.dom.clear_$external_fun' : (_this, ) => _this.clear(),
        'org.w3c.dom.captureEvents_$external_fun_1' : (_this, ) => _this.captureEvents(),
        'org.w3c.dom.releaseEvents_$external_fun_1' : (_this, ) => _this.releaseEvents(),
        'org.w3c.dom.elementFromPoint_$external_fun' : (_this, p0, p1) => _this.elementFromPoint(p0, p1),
        'org.w3c.dom.elementsFromPoint_$external_fun' : (_this, p0, p1) => _this.elementsFromPoint(p0, p1),
        'org.w3c.dom.caretPositionFromPoint_$external_fun' : (_this, p0, p1) => _this.caretPositionFromPoint(p0, p1),
        'org.w3c.dom.createTouch_$external_fun' : (_this, p0, p1, p2, p3, p4, p5, p6) => _this.createTouch(p0, p1, p2, p3, p4, p5, p6),
        'org.w3c.dom.createTouchList_$external_fun' : (_this, p0) => _this.createTouchList(...p0),
        'org.w3c.dom.exitFullscreen_$external_fun' : (_this, ) => _this.exitFullscreen(),
        'org.w3c.dom.getElementById_$external_fun' : (_this, p0) => _this.getElementById(p0),
        'org.w3c.dom.prepend_$external_fun' : (_this, p0) => _this.prepend(...p0),
        'org.w3c.dom.prepend_$external_fun_1' : (_this, p0) => _this.prepend(...p0),
        'org.w3c.dom.append_$external_fun' : (_this, p0) => _this.append(...p0),
        'org.w3c.dom.append_$external_fun_1' : (_this, p0) => _this.append(...p0),
        'org.w3c.dom.querySelector_$external_fun' : (_this, p0) => _this.querySelector(p0),
        'org.w3c.dom.querySelectorAll_$external_fun' : (_this, p0) => _this.querySelectorAll(p0),
        'org.w3c.dom.getBoxQuads_$external_fun' : (_this, p0, isDefault0) => _this.getBoxQuads(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_1' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_2' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_1' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_2' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_1' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_2' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.Companion_$external_fun' : () => new Document(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance' : () => Document,
        'org.w3c.dom.Companion_$external_class_instanceof' : (x) => x instanceof Document,
        'org.w3c.dom.Companion_$external_class_get' : () => Document,
        'org.w3c.dom.Document_$external_class_instanceof' : (x) => x instanceof Document,
        'org.w3c.dom.Document_$external_class_get' : () => Document,
        'org.w3c.dom.Location_$external_fun' : () => new Location(),
        'org.w3c.dom.href_$external_prop_getter' : (_this) => _this.href,
        'org.w3c.dom.href_$external_prop_setter' : (_this, v) => _this.href = v,
        'org.w3c.dom.origin_$external_prop_getter_2' : (_this) => _this.origin,
        'org.w3c.dom.protocol_$external_prop_getter' : (_this) => _this.protocol,
        'org.w3c.dom.protocol_$external_prop_setter' : (_this, v) => _this.protocol = v,
        'org.w3c.dom.host_$external_prop_getter' : (_this) => _this.host,
        'org.w3c.dom.host_$external_prop_setter' : (_this, v) => _this.host = v,
        'org.w3c.dom.hostname_$external_prop_getter' : (_this) => _this.hostname,
        'org.w3c.dom.hostname_$external_prop_setter' : (_this, v) => _this.hostname = v,
        'org.w3c.dom.port_$external_prop_getter' : (_this) => _this.port,
        'org.w3c.dom.port_$external_prop_setter' : (_this, v) => _this.port = v,
        'org.w3c.dom.pathname_$external_prop_getter' : (_this) => _this.pathname,
        'org.w3c.dom.pathname_$external_prop_setter' : (_this, v) => _this.pathname = v,
        'org.w3c.dom.search_$external_prop_getter' : (_this) => _this.search,
        'org.w3c.dom.search_$external_prop_setter' : (_this, v) => _this.search = v,
        'org.w3c.dom.hash_$external_prop_getter' : (_this) => _this.hash,
        'org.w3c.dom.hash_$external_prop_setter' : (_this, v) => _this.hash = v,
        'org.w3c.dom.ancestorOrigins_$external_prop_getter' : (_this) => _this.ancestorOrigins,
        'org.w3c.dom.assign_$external_fun' : (_this, p0) => _this.assign(p0),
        'org.w3c.dom.replace_$external_fun' : (_this, p0) => _this.replace(p0),
        'org.w3c.dom.reload_$external_fun' : (_this, ) => _this.reload(),
        'org.w3c.dom.Location_$external_class_instanceof' : (x) => x instanceof Location,
        'org.w3c.dom.Location_$external_class_get' : () => Location,
        'org.w3c.dom.History_$external_fun' : () => new History(),
        'org.w3c.dom.length_$external_prop_getter_2' : (_this) => _this.length,
        'org.w3c.dom.scrollRestoration_$external_prop_getter' : (_this) => _this.scrollRestoration,
        'org.w3c.dom.scrollRestoration_$external_prop_setter' : (_this, v) => _this.scrollRestoration = v,
        'org.w3c.dom.state_$external_prop_getter' : (_this) => _this.state,
        'org.w3c.dom.go_$external_fun' : (_this, p0, isDefault0) => _this.go(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.back_$external_fun' : (_this, ) => _this.back(),
        'org.w3c.dom.forward_$external_fun' : (_this, ) => _this.forward(),
        'org.w3c.dom.pushState_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.pushState(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.replaceState_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.replaceState(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.History_$external_class_instanceof' : (x) => x instanceof History,
        'org.w3c.dom.History_$external_class_get' : () => History,
        'org.w3c.dom.CustomElementRegistry_$external_fun' : () => new CustomElementRegistry(),
        'org.w3c.dom.define_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.define(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.get_$external_fun' : (_this, p0) => _this.get(p0),
        'org.w3c.dom.whenDefined_$external_fun' : (_this, p0) => _this.whenDefined(p0),
        'org.w3c.dom.CustomElementRegistry_$external_class_instanceof' : (x) => x instanceof CustomElementRegistry,
        'org.w3c.dom.CustomElementRegistry_$external_class_get' : () => CustomElementRegistry,
        'org.w3c.dom.BarProp_$external_fun' : () => new BarProp(),
        'org.w3c.dom.visible_$external_prop_getter' : (_this) => _this.visible,
        'org.w3c.dom.BarProp_$external_class_instanceof' : (x) => x instanceof BarProp,
        'org.w3c.dom.BarProp_$external_class_get' : () => BarProp,
        'org.w3c.dom.Element_$external_fun' : () => new Element(),
        'org.w3c.dom.namespaceURI_$external_prop_getter' : (_this) => _this.namespaceURI,
        'org.w3c.dom.prefix_$external_prop_getter' : (_this) => _this.prefix,
        'org.w3c.dom.localName_$external_prop_getter' : (_this) => _this.localName,
        'org.w3c.dom.tagName_$external_prop_getter' : (_this) => _this.tagName,
        'org.w3c.dom.id_$external_prop_getter' : (_this) => _this.id,
        'org.w3c.dom.id_$external_prop_setter' : (_this, v) => _this.id = v,
        'org.w3c.dom.className_$external_prop_getter' : (_this) => _this.className,
        'org.w3c.dom.className_$external_prop_setter' : (_this, v) => _this.className = v,
        'org.w3c.dom.classList_$external_prop_getter' : (_this) => _this.classList,
        'org.w3c.dom.slot_$external_prop_getter' : (_this) => _this.slot,
        'org.w3c.dom.slot_$external_prop_setter' : (_this, v) => _this.slot = v,
        'org.w3c.dom.attributes_$external_prop_getter' : (_this) => _this.attributes,
        'org.w3c.dom.shadowRoot_$external_prop_getter' : (_this) => _this.shadowRoot,
        'org.w3c.dom.scrollTop_$external_prop_getter' : (_this) => _this.scrollTop,
        'org.w3c.dom.scrollTop_$external_prop_setter' : (_this, v) => _this.scrollTop = v,
        'org.w3c.dom.scrollLeft_$external_prop_getter' : (_this) => _this.scrollLeft,
        'org.w3c.dom.scrollLeft_$external_prop_setter' : (_this, v) => _this.scrollLeft = v,
        'org.w3c.dom.scrollWidth_$external_prop_getter' : (_this) => _this.scrollWidth,
        'org.w3c.dom.scrollHeight_$external_prop_getter' : (_this) => _this.scrollHeight,
        'org.w3c.dom.clientTop_$external_prop_getter' : (_this) => _this.clientTop,
        'org.w3c.dom.clientLeft_$external_prop_getter' : (_this) => _this.clientLeft,
        'org.w3c.dom.clientWidth_$external_prop_getter' : (_this) => _this.clientWidth,
        'org.w3c.dom.clientHeight_$external_prop_getter' : (_this) => _this.clientHeight,
        'org.w3c.dom.innerHTML_$external_prop_getter' : (_this) => _this.innerHTML,
        'org.w3c.dom.innerHTML_$external_prop_setter' : (_this, v) => _this.innerHTML = v,
        'org.w3c.dom.outerHTML_$external_prop_getter' : (_this) => _this.outerHTML,
        'org.w3c.dom.outerHTML_$external_prop_setter' : (_this, v) => _this.outerHTML = v,
        'org.w3c.dom.hasAttributes_$external_fun' : (_this, ) => _this.hasAttributes(),
        'org.w3c.dom.getAttributeNames_$external_fun' : (_this, ) => _this.getAttributeNames(),
        'org.w3c.dom.getAttribute_$external_fun' : (_this, p0) => _this.getAttribute(p0),
        'org.w3c.dom.getAttributeNS_$external_fun' : (_this, p0, p1) => _this.getAttributeNS(p0, p1),
        'org.w3c.dom.setAttribute_$external_fun' : (_this, p0, p1) => _this.setAttribute(p0, p1),
        'org.w3c.dom.setAttributeNS_$external_fun' : (_this, p0, p1, p2) => _this.setAttributeNS(p0, p1, p2),
        'org.w3c.dom.removeAttribute_$external_fun' : (_this, p0) => _this.removeAttribute(p0),
        'org.w3c.dom.removeAttributeNS_$external_fun' : (_this, p0, p1) => _this.removeAttributeNS(p0, p1),
        'org.w3c.dom.hasAttribute_$external_fun' : (_this, p0) => _this.hasAttribute(p0),
        'org.w3c.dom.hasAttributeNS_$external_fun' : (_this, p0, p1) => _this.hasAttributeNS(p0, p1),
        'org.w3c.dom.getAttributeNode_$external_fun' : (_this, p0) => _this.getAttributeNode(p0),
        'org.w3c.dom.getAttributeNodeNS_$external_fun' : (_this, p0, p1) => _this.getAttributeNodeNS(p0, p1),
        'org.w3c.dom.setAttributeNode_$external_fun' : (_this, p0) => _this.setAttributeNode(p0),
        'org.w3c.dom.setAttributeNodeNS_$external_fun' : (_this, p0) => _this.setAttributeNodeNS(p0),
        'org.w3c.dom.removeAttributeNode_$external_fun' : (_this, p0) => _this.removeAttributeNode(p0),
        'org.w3c.dom.attachShadow_$external_fun' : (_this, p0) => _this.attachShadow(p0),
        'org.w3c.dom.closest_$external_fun' : (_this, p0) => _this.closest(p0),
        'org.w3c.dom.matches_$external_fun' : (_this, p0) => _this.matches(p0),
        'org.w3c.dom.webkitMatchesSelector_$external_fun' : (_this, p0) => _this.webkitMatchesSelector(p0),
        'org.w3c.dom.getElementsByTagName_$external_fun_1' : (_this, p0) => _this.getElementsByTagName(p0),
        'org.w3c.dom.getElementsByTagNameNS_$external_fun_1' : (_this, p0, p1) => _this.getElementsByTagNameNS(p0, p1),
        'org.w3c.dom.getElementsByClassName_$external_fun_1' : (_this, p0) => _this.getElementsByClassName(p0),
        'org.w3c.dom.insertAdjacentElement_$external_fun' : (_this, p0, p1) => _this.insertAdjacentElement(p0, p1),
        'org.w3c.dom.insertAdjacentText_$external_fun' : (_this, p0, p1) => _this.insertAdjacentText(p0, p1),
        'org.w3c.dom.getClientRects_$external_fun' : (_this, ) => _this.getClientRects(),
        'org.w3c.dom.getBoundingClientRect_$external_fun' : (_this, ) => _this.getBoundingClientRect(),
        'org.w3c.dom.scrollIntoView_$external_fun' : (_this, ) => _this.scrollIntoView(),
        'org.w3c.dom.scrollIntoView_$external_fun_1' : (_this, p0) => _this.scrollIntoView(p0),
        'org.w3c.dom.scrollIntoView_$external_fun_2' : (_this, p0) => _this.scrollIntoView(p0),
        'org.w3c.dom.scroll_$external_fun_2' : (_this, p0, isDefault0) => _this.scroll(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.scroll_$external_fun_3' : (_this, p0, p1) => _this.scroll(p0, p1),
        'org.w3c.dom.scrollTo_$external_fun_2' : (_this, p0, isDefault0) => _this.scrollTo(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.scrollTo_$external_fun_3' : (_this, p0, p1) => _this.scrollTo(p0, p1),
        'org.w3c.dom.scrollBy_$external_fun_2' : (_this, p0, isDefault0) => _this.scrollBy(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.scrollBy_$external_fun_3' : (_this, p0, p1) => _this.scrollBy(p0, p1),
        'org.w3c.dom.insertAdjacentHTML_$external_fun' : (_this, p0, p1) => _this.insertAdjacentHTML(p0, p1),
        'org.w3c.dom.setPointerCapture_$external_fun' : (_this, p0) => _this.setPointerCapture(p0),
        'org.w3c.dom.releasePointerCapture_$external_fun' : (_this, p0) => _this.releasePointerCapture(p0),
        'org.w3c.dom.hasPointerCapture_$external_fun' : (_this, p0) => _this.hasPointerCapture(p0),
        'org.w3c.dom.requestFullscreen_$external_fun' : (_this, ) => _this.requestFullscreen(),
        'org.w3c.dom.Companion_$external_fun_1' : () => new Element(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_1' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_1' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_1' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_1' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_1' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_1' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_1' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_1' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_1' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_1' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_1' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_1' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_1' : () => Element,
        'org.w3c.dom.Companion_$external_class_instanceof_1' : (x) => x instanceof Element,
        'org.w3c.dom.Companion_$external_class_get_1' : () => Element,
        'org.w3c.dom.Element_$external_class_instanceof' : (x) => x instanceof Element,
        'org.w3c.dom.Element_$external_class_get' : () => Element,
        'org.w3c.dom.Navigator_$external_fun' : () => new Navigator(),
        'org.w3c.dom.clipboard_$external_prop_getter' : (_this) => _this.clipboard,
        'org.w3c.dom.mediaDevices_$external_prop_getter' : (_this) => _this.mediaDevices,
        'org.w3c.dom.maxTouchPoints_$external_prop_getter' : (_this) => _this.maxTouchPoints,
        'org.w3c.dom.serviceWorker_$external_prop_getter' : (_this) => _this.serviceWorker,
        'org.w3c.dom.requestMediaKeySystemAccess_$external_fun' : (_this, p0, p1) => _this.requestMediaKeySystemAccess(p0, p1),
        'org.w3c.dom.getUserMedia_$external_fun' : (_this, p0, p1, p2) => _this.getUserMedia(p0, p1, p2),
        'org.w3c.dom.vibrate_$external_fun' : (_this, p0) => _this.vibrate(p0),
        'org.w3c.dom.vibrate_$external_fun_1' : (_this, p0) => _this.vibrate(p0),
        'org.w3c.dom.Navigator_$external_class_instanceof' : (x) => x instanceof Navigator,
        'org.w3c.dom.Navigator_$external_class_get' : () => Navigator,
        'org.w3c.dom.ApplicationCache_$external_fun' : () => new ApplicationCache(),
        'org.w3c.dom.status_$external_prop_getter_1' : (_this) => _this.status,
        'org.w3c.dom.onchecking_$external_prop_getter' : (_this) => _this.onchecking,
        'org.w3c.dom.onchecking_$external_prop_setter' : (_this, v) => _this.onchecking = v,
        'org.w3c.dom.onerror_$external_prop_getter_2' : (_this) => _this.onerror,
        'org.w3c.dom.onerror_$external_prop_setter_2' : (_this, v) => _this.onerror = v,
        'org.w3c.dom.onnoupdate_$external_prop_getter' : (_this) => _this.onnoupdate,
        'org.w3c.dom.onnoupdate_$external_prop_setter' : (_this, v) => _this.onnoupdate = v,
        'org.w3c.dom.ondownloading_$external_prop_getter' : (_this) => _this.ondownloading,
        'org.w3c.dom.ondownloading_$external_prop_setter' : (_this, v) => _this.ondownloading = v,
        'org.w3c.dom.onprogress_$external_prop_getter_2' : (_this) => _this.onprogress,
        'org.w3c.dom.onprogress_$external_prop_setter_2' : (_this, v) => _this.onprogress = v,
        'org.w3c.dom.onupdateready_$external_prop_getter' : (_this) => _this.onupdateready,
        'org.w3c.dom.onupdateready_$external_prop_setter' : (_this, v) => _this.onupdateready = v,
        'org.w3c.dom.oncached_$external_prop_getter' : (_this) => _this.oncached,
        'org.w3c.dom.oncached_$external_prop_setter' : (_this, v) => _this.oncached = v,
        'org.w3c.dom.onobsolete_$external_prop_getter' : (_this) => _this.onobsolete,
        'org.w3c.dom.onobsolete_$external_prop_setter' : (_this, v) => _this.onobsolete = v,
        'org.w3c.dom.update_$external_fun' : (_this, ) => _this.update(),
        'org.w3c.dom.abort_$external_fun' : (_this, ) => _this.abort(),
        'org.w3c.dom.swapCache_$external_fun' : (_this, ) => _this.swapCache(),
        'org.w3c.dom.Companion_$external_fun_2' : () => new ApplicationCache(),
        'org.w3c.dom.UNCACHED_$external_prop_getter' : (_this) => _this.UNCACHED,
        'org.w3c.dom.IDLE_$external_prop_getter' : (_this) => _this.IDLE,
        'org.w3c.dom.CHECKING_$external_prop_getter' : (_this) => _this.CHECKING,
        'org.w3c.dom.DOWNLOADING_$external_prop_getter' : (_this) => _this.DOWNLOADING,
        'org.w3c.dom.UPDATEREADY_$external_prop_getter' : (_this) => _this.UPDATEREADY,
        'org.w3c.dom.OBSOLETE_$external_prop_getter' : (_this) => _this.OBSOLETE,
        'org.w3c.dom.Companion_$external_object_getInstance_2' : () => ApplicationCache,
        'org.w3c.dom.Companion_$external_class_instanceof_2' : (x) => x instanceof ApplicationCache,
        'org.w3c.dom.Companion_$external_class_get_2' : () => ApplicationCache,
        'org.w3c.dom.ApplicationCache_$external_class_instanceof' : (x) => x instanceof ApplicationCache,
        'org.w3c.dom.ApplicationCache_$external_class_get' : () => ApplicationCache,
        'org.w3c.dom.AddSearchProvider_$external_fun' : (_this, ) => _this.AddSearchProvider(),
        'org.w3c.dom.IsSearchProviderInstalled_$external_fun' : (_this, ) => _this.IsSearchProviderInstalled(),
        'org.w3c.dom.Screen_$external_fun' : () => new Screen(),
        'org.w3c.dom.availWidth_$external_prop_getter' : (_this) => _this.availWidth,
        'org.w3c.dom.availHeight_$external_prop_getter' : (_this) => _this.availHeight,
        'org.w3c.dom.width_$external_prop_getter' : (_this) => _this.width,
        'org.w3c.dom.height_$external_prop_getter' : (_this) => _this.height,
        'org.w3c.dom.colorDepth_$external_prop_getter' : (_this) => _this.colorDepth,
        'org.w3c.dom.pixelDepth_$external_prop_getter' : (_this) => _this.pixelDepth,
        'org.w3c.dom.Screen_$external_class_instanceof' : (x) => x instanceof Screen,
        'org.w3c.dom.Screen_$external_class_get' : () => Screen,
        'org.w3c.dom.MediaQueryList_$external_fun' : () => new MediaQueryList(),
        'org.w3c.dom.media_$external_prop_getter' : (_this) => _this.media,
        'org.w3c.dom.matches_$external_prop_getter' : (_this) => _this.matches,
        'org.w3c.dom.onchange_$external_prop_getter_2' : (_this) => _this.onchange,
        'org.w3c.dom.onchange_$external_prop_setter_2' : (_this, v) => _this.onchange = v,
        'org.w3c.dom.addListener_$external_fun' : (_this, p0) => _this.addListener(p0),
        'org.w3c.dom.addListener_$external_fun_1' : (_this, p0) => _this.addListener(p0),
        'org.w3c.dom.removeListener_$external_fun' : (_this, p0) => _this.removeListener(p0),
        'org.w3c.dom.removeListener_$external_fun_1' : (_this, p0) => _this.removeListener(p0),
        'org.w3c.dom.MediaQueryList_$external_class_instanceof' : (x) => x instanceof MediaQueryList,
        'org.w3c.dom.MediaQueryList_$external_class_get' : () => MediaQueryList,
        'org.w3c.dom.left_$external_prop_getter' : (_this) => _this.left,
        'org.w3c.dom.left_$external_prop_setter' : (_this, v) => _this.left = v,
        'org.w3c.dom.top_$external_prop_getter_1' : (_this) => _this.top,
        'org.w3c.dom.top_$external_prop_setter' : (_this, v) => _this.top = v,
        'org.w3c.dom.DragEvent_$external_fun' : (p0, p1, isDefault0) => new DragEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.dataTransfer_$external_prop_getter' : (_this) => _this.dataTransfer,
        'org.w3c.dom.Companion_$external_fun_3' : () => new DragEvent(),
        'org.w3c.dom.NONE_$external_prop_getter' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_3' : () => DragEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_3' : (x) => x instanceof DragEvent,
        'org.w3c.dom.Companion_$external_class_get_3' : () => DragEvent,
        'org.w3c.dom.DragEvent_$external_class_instanceof' : (x) => x instanceof DragEvent,
        'org.w3c.dom.DragEvent_$external_class_get' : () => DragEvent,
        'org.w3c.dom.BeforeUnloadEvent_$external_fun' : () => new BeforeUnloadEvent(),
        'org.w3c.dom.returnValue_$external_prop_getter' : (_this) => _this.returnValue,
        'org.w3c.dom.returnValue_$external_prop_setter' : (_this, v) => _this.returnValue = v,
        'org.w3c.dom.Companion_$external_fun_4' : () => new BeforeUnloadEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_1' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_1' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_1' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_1' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_4' : () => BeforeUnloadEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_4' : (x) => x instanceof BeforeUnloadEvent,
        'org.w3c.dom.Companion_$external_class_get_4' : () => BeforeUnloadEvent,
        'org.w3c.dom.BeforeUnloadEvent_$external_class_instanceof' : (x) => x instanceof BeforeUnloadEvent,
        'org.w3c.dom.BeforeUnloadEvent_$external_class_get' : () => BeforeUnloadEvent,
        'org.w3c.dom.HashChangeEvent_$external_fun' : (p0, p1, isDefault0) => new HashChangeEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.oldURL_$external_prop_getter' : (_this) => _this.oldURL,
        'org.w3c.dom.newURL_$external_prop_getter' : (_this) => _this.newURL,
        'org.w3c.dom.Companion_$external_fun_5' : () => new HashChangeEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_2' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_2' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_2' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_2' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_5' : () => HashChangeEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_5' : (x) => x instanceof HashChangeEvent,
        'org.w3c.dom.Companion_$external_class_get_5' : () => HashChangeEvent,
        'org.w3c.dom.HashChangeEvent_$external_class_instanceof' : (x) => x instanceof HashChangeEvent,
        'org.w3c.dom.HashChangeEvent_$external_class_get' : () => HashChangeEvent,
        'org.w3c.dom.MessageEvent_$external_fun' : (p0, p1, isDefault0) => new MessageEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.data_$external_prop_getter' : (_this) => _this.data,
        'org.w3c.dom.origin_$external_prop_getter_3' : (_this) => _this.origin,
        'org.w3c.dom.lastEventId_$external_prop_getter' : (_this) => _this.lastEventId,
        'org.w3c.dom.source_$external_prop_getter' : (_this) => _this.source,
        'org.w3c.dom.ports_$external_prop_getter' : (_this) => _this.ports,
        'org.w3c.dom.initMessageEvent_$external_fun' : (_this, p0, p1, p2, p3, p4, p5, p6, p7) => _this.initMessageEvent(p0, p1, p2, p3, p4, p5, p6, p7),
        'org.w3c.dom.Companion_$external_fun_6' : () => new MessageEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_3' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_3' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_3' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_3' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_6' : () => MessageEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_6' : (x) => x instanceof MessageEvent,
        'org.w3c.dom.Companion_$external_class_get_6' : () => MessageEvent,
        'org.w3c.dom.MessageEvent_$external_class_instanceof' : (x) => x instanceof MessageEvent,
        'org.w3c.dom.MessageEvent_$external_class_get' : () => MessageEvent,
        'org.w3c.dom.PageTransitionEvent_$external_fun' : (p0, p1, isDefault0) => new PageTransitionEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.persisted_$external_prop_getter' : (_this) => _this.persisted,
        'org.w3c.dom.Companion_$external_fun_7' : () => new PageTransitionEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_4' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_4' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_4' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_4' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_7' : () => PageTransitionEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_7' : (x) => x instanceof PageTransitionEvent,
        'org.w3c.dom.Companion_$external_class_get_7' : () => PageTransitionEvent,
        'org.w3c.dom.PageTransitionEvent_$external_class_instanceof' : (x) => x instanceof PageTransitionEvent,
        'org.w3c.dom.PageTransitionEvent_$external_class_get' : () => PageTransitionEvent,
        'org.w3c.dom.PopStateEvent_$external_fun' : (p0, p1, isDefault0) => new PopStateEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.state_$external_prop_getter_1' : (_this) => _this.state,
        'org.w3c.dom.Companion_$external_fun_8' : () => new PopStateEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_5' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_5' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_5' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_5' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_8' : () => PopStateEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_8' : (x) => x instanceof PopStateEvent,
        'org.w3c.dom.Companion_$external_class_get_8' : () => PopStateEvent,
        'org.w3c.dom.PopStateEvent_$external_class_instanceof' : (x) => x instanceof PopStateEvent,
        'org.w3c.dom.PopStateEvent_$external_class_get' : () => PopStateEvent,
        'org.w3c.dom.StorageEvent_$external_fun' : (p0, p1, isDefault0) => new StorageEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.key_$external_prop_getter' : (_this) => _this.key,
        'org.w3c.dom.oldValue_$external_prop_getter' : (_this) => _this.oldValue,
        'org.w3c.dom.newValue_$external_prop_getter' : (_this) => _this.newValue,
        'org.w3c.dom.url_$external_prop_getter' : (_this) => _this.url,
        'org.w3c.dom.storageArea_$external_prop_getter' : (_this) => _this.storageArea,
        'org.w3c.dom.Companion_$external_fun_9' : () => new StorageEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_6' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_6' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_6' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_6' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_9' : () => StorageEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_9' : (x) => x instanceof StorageEvent,
        'org.w3c.dom.Companion_$external_class_get_9' : () => StorageEvent,
        'org.w3c.dom.StorageEvent_$external_class_instanceof' : (x) => x instanceof StorageEvent,
        'org.w3c.dom.StorageEvent_$external_class_get' : () => StorageEvent,
        'org.w3c.dom.PromiseRejectionEvent_$external_fun' : (p0, p1) => new PromiseRejectionEvent(p0, p1),
        'org.w3c.dom.promise_$external_prop_getter' : (_this) => _this.promise,
        'org.w3c.dom.reason_$external_prop_getter' : (_this) => _this.reason,
        'org.w3c.dom.Companion_$external_fun_10' : () => new PromiseRejectionEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_7' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_7' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_7' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_7' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_10' : () => PromiseRejectionEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_10' : (x) => x instanceof PromiseRejectionEvent,
        'org.w3c.dom.Companion_$external_class_get_10' : () => PromiseRejectionEvent,
        'org.w3c.dom.PromiseRejectionEvent_$external_class_instanceof' : (x) => x instanceof PromiseRejectionEvent,
        'org.w3c.dom.PromiseRejectionEvent_$external_class_get' : () => PromiseRejectionEvent,
        'org.w3c.dom.ImageBitmap_$external_fun' : () => new ImageBitmap(),
        'org.w3c.dom.width_$external_prop_getter_1' : (_this) => _this.width,
        'org.w3c.dom.height_$external_prop_getter_1' : (_this) => _this.height,
        'org.w3c.dom.close_$external_fun_2' : (_this, ) => _this.close(),
        'org.w3c.dom.ImageBitmap_$external_class_instanceof' : (x) => x instanceof ImageBitmap,
        'org.w3c.dom.ImageBitmap_$external_class_get' : () => ImageBitmap,
        'org.w3c.dom.imageOrientation_$external_prop_getter' : (_this) => _this.imageOrientation,
        'org.w3c.dom.imageOrientation_$external_prop_setter' : (_this, v) => _this.imageOrientation = v,
        'org.w3c.dom.premultiplyAlpha_$external_prop_getter' : (_this) => _this.premultiplyAlpha,
        'org.w3c.dom.premultiplyAlpha_$external_prop_setter' : (_this, v) => _this.premultiplyAlpha = v,
        'org.w3c.dom.colorSpaceConversion_$external_prop_getter' : (_this) => _this.colorSpaceConversion,
        'org.w3c.dom.colorSpaceConversion_$external_prop_setter' : (_this, v) => _this.colorSpaceConversion = v,
        'org.w3c.dom.resizeWidth_$external_prop_getter' : (_this) => _this.resizeWidth,
        'org.w3c.dom.resizeWidth_$external_prop_setter' : (_this, v) => _this.resizeWidth = v,
        'org.w3c.dom.resizeHeight_$external_prop_getter' : (_this) => _this.resizeHeight,
        'org.w3c.dom.resizeHeight_$external_prop_setter' : (_this, v) => _this.resizeHeight = v,
        'org.w3c.dom.resizeQuality_$external_prop_getter' : (_this) => _this.resizeQuality,
        'org.w3c.dom.resizeQuality_$external_prop_setter' : (_this, v) => _this.resizeQuality = v,
        'org.w3c.dom.Storage_$external_fun' : () => new Storage(),
        'org.w3c.dom.length_$external_prop_getter_3' : (_this) => _this.length,
        'org.w3c.dom.key_$external_fun' : (_this, p0) => _this.key(p0),
        'org.w3c.dom.removeItem_$external_fun' : (_this, p0) => _this.removeItem(p0),
        'org.w3c.dom.clear_$external_fun_1' : (_this, ) => _this.clear(),
        'org.w3c.dom.getItem_$external_fun' : (_this, p0) => _this.getItem(p0),
        'org.w3c.dom.setItem_$external_fun' : (_this, p0, p1) => _this.setItem(p0, p1),
        'org.w3c.dom.Storage_$external_class_instanceof' : (x) => x instanceof Storage,
        'org.w3c.dom.Storage_$external_class_get' : () => Storage,
        'org.w3c.dom.Node_$external_fun' : () => new Node(),
        'org.w3c.dom.nodeType_$external_prop_getter' : (_this) => _this.nodeType,
        'org.w3c.dom.nodeName_$external_prop_getter' : (_this) => _this.nodeName,
        'org.w3c.dom.baseURI_$external_prop_getter' : (_this) => _this.baseURI,
        'org.w3c.dom.isConnected_$external_prop_getter' : (_this) => _this.isConnected,
        'org.w3c.dom.ownerDocument_$external_prop_getter' : (_this) => _this.ownerDocument,
        'org.w3c.dom.parentNode_$external_prop_getter' : (_this) => _this.parentNode,
        'org.w3c.dom.parentElement_$external_prop_getter' : (_this) => _this.parentElement,
        'org.w3c.dom.childNodes_$external_prop_getter' : (_this) => _this.childNodes,
        'org.w3c.dom.firstChild_$external_prop_getter' : (_this) => _this.firstChild,
        'org.w3c.dom.lastChild_$external_prop_getter' : (_this) => _this.lastChild,
        'org.w3c.dom.previousSibling_$external_prop_getter' : (_this) => _this.previousSibling,
        'org.w3c.dom.nextSibling_$external_prop_getter' : (_this) => _this.nextSibling,
        'org.w3c.dom.nodeValue_$external_prop_getter' : (_this) => _this.nodeValue,
        'org.w3c.dom.nodeValue_$external_prop_setter' : (_this, v) => _this.nodeValue = v,
        'org.w3c.dom.textContent_$external_prop_getter' : (_this) => _this.textContent,
        'org.w3c.dom.textContent_$external_prop_setter' : (_this, v) => _this.textContent = v,
        'org.w3c.dom.getRootNode_$external_fun' : (_this, p0, isDefault0) => _this.getRootNode(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.hasChildNodes_$external_fun' : (_this, ) => _this.hasChildNodes(),
        'org.w3c.dom.normalize_$external_fun' : (_this, ) => _this.normalize(),
        'org.w3c.dom.cloneNode_$external_fun' : (_this, p0, isDefault0) => _this.cloneNode(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.isEqualNode_$external_fun' : (_this, p0) => _this.isEqualNode(p0),
        'org.w3c.dom.isSameNode_$external_fun' : (_this, p0) => _this.isSameNode(p0),
        'org.w3c.dom.compareDocumentPosition_$external_fun' : (_this, p0) => _this.compareDocumentPosition(p0),
        'org.w3c.dom.contains_$external_fun' : (_this, p0) => _this.contains(p0),
        'org.w3c.dom.lookupPrefix_$external_fun' : (_this, p0) => _this.lookupPrefix(p0),
        'org.w3c.dom.lookupNamespaceURI_$external_fun' : (_this, p0) => _this.lookupNamespaceURI(p0),
        'org.w3c.dom.isDefaultNamespace_$external_fun' : (_this, p0) => _this.isDefaultNamespace(p0),
        'org.w3c.dom.insertBefore_$external_fun' : (_this, p0, p1) => _this.insertBefore(p0, p1),
        'org.w3c.dom.appendChild_$external_fun' : (_this, p0) => _this.appendChild(p0),
        'org.w3c.dom.replaceChild_$external_fun' : (_this, p0, p1) => _this.replaceChild(p0, p1),
        'org.w3c.dom.removeChild_$external_fun' : (_this, p0) => _this.removeChild(p0),
        'org.w3c.dom.Companion_$external_fun_11' : () => new Node(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_2' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_2' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_2' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_2' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_2' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_2' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_2' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_2' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_2' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_2' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_2' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_2' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_11' : () => Node,
        'org.w3c.dom.Companion_$external_class_instanceof_11' : (x) => x instanceof Node,
        'org.w3c.dom.Companion_$external_class_get_11' : () => Node,
        'org.w3c.dom.Node_$external_class_instanceof' : (x) => x instanceof Node,
        'org.w3c.dom.Node_$external_class_get' : () => Node,
        'org.w3c.dom.oncopy_$external_prop_getter_1' : (_this) => _this.oncopy,
        'org.w3c.dom.oncopy_$external_prop_setter_1' : (_this, v) => _this.oncopy = v,
        'org.w3c.dom.oncut_$external_prop_getter_1' : (_this) => _this.oncut,
        'org.w3c.dom.oncut_$external_prop_setter_1' : (_this, v) => _this.oncut = v,
        'org.w3c.dom.onpaste_$external_prop_getter_1' : (_this) => _this.onpaste,
        'org.w3c.dom.onpaste_$external_prop_setter_1' : (_this, v) => _this.onpaste = v,
        'org.w3c.dom.getElementById_$external_fun_1' : (_this, p0) => _this.getElementById(p0),
        'org.w3c.dom.fullscreenElement_$external_prop_getter_1' : (_this) => _this.fullscreenElement,
        'org.w3c.dom.children_$external_prop_getter_1' : (_this) => _this.children,
        'org.w3c.dom.firstElementChild_$external_prop_getter_1' : (_this) => _this.firstElementChild,
        'org.w3c.dom.lastElementChild_$external_prop_getter_1' : (_this) => _this.lastElementChild,
        'org.w3c.dom.childElementCount_$external_prop_getter_1' : (_this) => _this.childElementCount,
        'org.w3c.dom.prepend_$external_fun_2' : (_this, p0) => _this.prepend(...p0),
        'org.w3c.dom.prepend_$external_fun_3' : (_this, p0) => _this.prepend(...p0),
        'org.w3c.dom.append_$external_fun_2' : (_this, p0) => _this.append(...p0),
        'org.w3c.dom.append_$external_fun_3' : (_this, p0) => _this.append(...p0),
        'org.w3c.dom.querySelector_$external_fun_1' : (_this, p0) => _this.querySelector(p0),
        'org.w3c.dom.querySelectorAll_$external_fun_1' : (_this, p0) => _this.querySelectorAll(p0),
        'org.w3c.dom.getBoxQuads_$external_fun_1' : (_this, p0, isDefault0) => _this.getBoxQuads(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_3' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_4' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_5' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_3' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_4' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_5' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_3' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_4' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_5' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.DOMImplementation_$external_fun' : () => new DOMImplementation(),
        'org.w3c.dom.createDocumentType_$external_fun' : (_this, p0, p1, p2) => _this.createDocumentType(p0, p1, p2),
        'org.w3c.dom.createDocument_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.createDocument(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.createHTMLDocument_$external_fun' : (_this, p0, isDefault0) => _this.createHTMLDocument(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.hasFeature_$external_fun' : (_this, ) => _this.hasFeature(),
        'org.w3c.dom.DOMImplementation_$external_class_instanceof' : (x) => x instanceof DOMImplementation,
        'org.w3c.dom.DOMImplementation_$external_class_get' : () => DOMImplementation,
        'org.w3c.dom.DocumentType_$external_fun' : () => new DocumentType(),
        'org.w3c.dom.name_$external_prop_getter_1' : (_this) => _this.name,
        'org.w3c.dom.publicId_$external_prop_getter' : (_this) => _this.publicId,
        'org.w3c.dom.systemId_$external_prop_getter' : (_this) => _this.systemId,
        'org.w3c.dom.Companion_$external_fun_12' : () => new DocumentType(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_3' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_3' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_3' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_3' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_3' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_3' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_3' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_3' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_3' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_3' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_3' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_3' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_12' : () => DocumentType,
        'org.w3c.dom.Companion_$external_class_instanceof_12' : (x) => x instanceof DocumentType,
        'org.w3c.dom.Companion_$external_class_get_12' : () => DocumentType,
        'org.w3c.dom.DocumentType_$external_class_instanceof' : (x) => x instanceof DocumentType,
        'org.w3c.dom.DocumentType_$external_class_get' : () => DocumentType,
        'org.w3c.dom.Companion_$external_fun_13' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_13' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_13' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_13' : () => ({}),
        'org.w3c.dom.HTMLElement_$external_fun' : () => new HTMLElement(),
        'org.w3c.dom.title_$external_prop_getter_1' : (_this) => _this.title,
        'org.w3c.dom.title_$external_prop_setter_1' : (_this, v) => _this.title = v,
        'org.w3c.dom.lang_$external_prop_getter' : (_this) => _this.lang,
        'org.w3c.dom.lang_$external_prop_setter' : (_this, v) => _this.lang = v,
        'org.w3c.dom.translate_$external_prop_getter' : (_this) => _this.translate,
        'org.w3c.dom.translate_$external_prop_setter' : (_this, v) => _this.translate = v,
        'org.w3c.dom.dir_$external_prop_getter_1' : (_this) => _this.dir,
        'org.w3c.dom.dir_$external_prop_setter_1' : (_this, v) => _this.dir = v,
        'org.w3c.dom.dataset_$external_prop_getter' : (_this) => _this.dataset,
        'org.w3c.dom.hidden_$external_prop_getter' : (_this) => _this.hidden,
        'org.w3c.dom.hidden_$external_prop_setter' : (_this, v) => _this.hidden = v,
        'org.w3c.dom.tabIndex_$external_prop_getter' : (_this) => _this.tabIndex,
        'org.w3c.dom.tabIndex_$external_prop_setter' : (_this, v) => _this.tabIndex = v,
        'org.w3c.dom.accessKey_$external_prop_getter' : (_this) => _this.accessKey,
        'org.w3c.dom.accessKey_$external_prop_setter' : (_this, v) => _this.accessKey = v,
        'org.w3c.dom.accessKeyLabel_$external_prop_getter' : (_this) => _this.accessKeyLabel,
        'org.w3c.dom.draggable_$external_prop_getter' : (_this) => _this.draggable,
        'org.w3c.dom.draggable_$external_prop_setter' : (_this, v) => _this.draggable = v,
        'org.w3c.dom.dropzone_$external_prop_getter' : (_this) => _this.dropzone,
        'org.w3c.dom.contextMenu_$external_prop_getter' : (_this) => _this.contextMenu,
        'org.w3c.dom.contextMenu_$external_prop_setter' : (_this, v) => _this.contextMenu = v,
        'org.w3c.dom.spellcheck_$external_prop_getter' : (_this) => _this.spellcheck,
        'org.w3c.dom.spellcheck_$external_prop_setter' : (_this, v) => _this.spellcheck = v,
        'org.w3c.dom.innerText_$external_prop_getter' : (_this) => _this.innerText,
        'org.w3c.dom.innerText_$external_prop_setter' : (_this, v) => _this.innerText = v,
        'org.w3c.dom.offsetParent_$external_prop_getter' : (_this) => _this.offsetParent,
        'org.w3c.dom.offsetTop_$external_prop_getter' : (_this) => _this.offsetTop,
        'org.w3c.dom.offsetLeft_$external_prop_getter' : (_this) => _this.offsetLeft,
        'org.w3c.dom.offsetWidth_$external_prop_getter' : (_this) => _this.offsetWidth,
        'org.w3c.dom.offsetHeight_$external_prop_getter' : (_this) => _this.offsetHeight,
        'org.w3c.dom.click_$external_fun' : (_this, ) => _this.click(),
        'org.w3c.dom.focus_$external_fun_1' : (_this, ) => _this.focus(),
        'org.w3c.dom.blur_$external_fun_1' : (_this, ) => _this.blur(),
        'org.w3c.dom.forceSpellCheck_$external_fun' : (_this, ) => _this.forceSpellCheck(),
        'org.w3c.dom.Companion_$external_fun_14' : () => new HTMLElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_4' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_4' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_4' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_4' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_4' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_4' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_4' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_4' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_4' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_4' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_4' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_4' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_4' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_4' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_4' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_4' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_4' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_4' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_14' : () => HTMLElement,
        'org.w3c.dom.Companion_$external_class_instanceof_14' : (x) => x instanceof HTMLElement,
        'org.w3c.dom.Companion_$external_class_get_14' : () => HTMLElement,
        'org.w3c.dom.HTMLElement_$external_class_instanceof' : (x) => x instanceof HTMLElement,
        'org.w3c.dom.HTMLElement_$external_class_get' : () => HTMLElement,
        'org.w3c.dom.HTMLHeadElement_$external_fun' : () => new HTMLHeadElement(),
        'org.w3c.dom.Companion_$external_fun_15' : () => new HTMLHeadElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_5' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_5' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_5' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_5' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_5' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_5' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_5' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_5' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_5' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_5' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_5' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_5' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_5' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_5' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_5' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_5' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_5' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_5' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_15' : () => HTMLHeadElement,
        'org.w3c.dom.Companion_$external_class_instanceof_15' : (x) => x instanceof HTMLHeadElement,
        'org.w3c.dom.Companion_$external_class_get_15' : () => HTMLHeadElement,
        'org.w3c.dom.HTMLHeadElement_$external_class_instanceof' : (x) => x instanceof HTMLHeadElement,
        'org.w3c.dom.HTMLHeadElement_$external_class_get' : () => HTMLHeadElement,
        'org.w3c.dom.HTMLCollection_$external_fun' : () => new HTMLCollection(),
        'org.w3c.dom.item_$external_fun_1' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.namedItem_$external_fun' : (_this, p0) => _this.namedItem(p0),
        'org.w3c.dom.HTMLCollection_$external_class_instanceof' : (x) => x instanceof HTMLCollection,
        'org.w3c.dom.HTMLCollection_$external_class_get' : () => HTMLCollection,
        'org.w3c.dom.HTMLAllCollection_$external_fun' : () => new HTMLAllCollection(),
        'org.w3c.dom.length_$external_prop_getter_4' : (_this) => _this.length,
        'org.w3c.dom.item_$external_fun_2' : (_this, p0, isDefault0) => _this.item(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.namedItem_$external_fun_1' : (_this, p0) => _this.namedItem(p0),
        'org.w3c.dom.HTMLAllCollection_$external_class_instanceof' : (x) => x instanceof HTMLAllCollection,
        'org.w3c.dom.HTMLAllCollection_$external_class_get' : () => HTMLAllCollection,
        'org.w3c.dom.is_$external_prop_getter' : (_this) => _this.is,
        'org.w3c.dom.is_$external_prop_setter' : (_this, v) => _this.is = v,
        'org.w3c.dom.DocumentFragment_$external_fun' : () => new DocumentFragment(),
        'org.w3c.dom.children_$external_prop_getter_2' : (_this) => _this.children,
        'org.w3c.dom.firstElementChild_$external_prop_getter_2' : (_this) => _this.firstElementChild,
        'org.w3c.dom.lastElementChild_$external_prop_getter_2' : (_this) => _this.lastElementChild,
        'org.w3c.dom.childElementCount_$external_prop_getter_2' : (_this) => _this.childElementCount,
        'org.w3c.dom.getElementById_$external_fun_2' : (_this, p0) => _this.getElementById(p0),
        'org.w3c.dom.prepend_$external_fun_4' : (_this, p0) => _this.prepend(...p0),
        'org.w3c.dom.prepend_$external_fun_5' : (_this, p0) => _this.prepend(...p0),
        'org.w3c.dom.append_$external_fun_4' : (_this, p0) => _this.append(...p0),
        'org.w3c.dom.append_$external_fun_5' : (_this, p0) => _this.append(...p0),
        'org.w3c.dom.querySelector_$external_fun_2' : (_this, p0) => _this.querySelector(p0),
        'org.w3c.dom.querySelectorAll_$external_fun_2' : (_this, p0) => _this.querySelectorAll(p0),
        'org.w3c.dom.Companion_$external_fun_16' : () => new DocumentFragment(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_6' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_6' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_6' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_6' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_6' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_6' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_6' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_6' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_6' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_6' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_6' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_6' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_6' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_6' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_6' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_6' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_6' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_6' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_16' : () => DocumentFragment,
        'org.w3c.dom.Companion_$external_class_instanceof_16' : (x) => x instanceof DocumentFragment,
        'org.w3c.dom.Companion_$external_class_get_16' : () => DocumentFragment,
        'org.w3c.dom.DocumentFragment_$external_class_instanceof' : (x) => x instanceof DocumentFragment,
        'org.w3c.dom.DocumentFragment_$external_class_get' : () => DocumentFragment,
        'org.w3c.dom.Text_$external_fun' : (p0, isDefault0) => new Text(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.wholeText_$external_prop_getter' : (_this) => _this.wholeText,
        'org.w3c.dom.assignedSlot_$external_prop_getter' : (_this) => _this.assignedSlot,
        'org.w3c.dom.previousElementSibling_$external_prop_getter' : (_this) => _this.previousElementSibling,
        'org.w3c.dom.nextElementSibling_$external_prop_getter' : (_this) => _this.nextElementSibling,
        'org.w3c.dom.splitText_$external_fun' : (_this, p0) => _this.splitText(p0),
        'org.w3c.dom.getBoxQuads_$external_fun_2' : (_this, p0, isDefault0) => _this.getBoxQuads(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_6' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_7' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertQuadFromNode_$external_fun_8' : (_this, p0, p1, p2, isDefault0) => _this.convertQuadFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_6' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_7' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertRectFromNode_$external_fun_8' : (_this, p0, p1, p2, isDefault0) => _this.convertRectFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_6' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_7' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.convertPointFromNode_$external_fun_8' : (_this, p0, p1, p2, isDefault0) => _this.convertPointFromNode(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.before_$external_fun' : (_this, p0) => _this.before(...p0),
        'org.w3c.dom.before_$external_fun_1' : (_this, p0) => _this.before(...p0),
        'org.w3c.dom.after_$external_fun' : (_this, p0) => _this.after(...p0),
        'org.w3c.dom.after_$external_fun_1' : (_this, p0) => _this.after(...p0),
        'org.w3c.dom.replaceWith_$external_fun' : (_this, p0) => _this.replaceWith(...p0),
        'org.w3c.dom.replaceWith_$external_fun_1' : (_this, p0) => _this.replaceWith(...p0),
        'org.w3c.dom.remove_$external_fun' : (_this, ) => _this.remove(),
        'org.w3c.dom.Companion_$external_fun_17' : () => new Text(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_7' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_7' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_7' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_7' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_7' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_7' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_7' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_7' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_7' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_7' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_7' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_7' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_7' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_7' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_7' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_7' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_7' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_7' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_17' : () => Text,
        'org.w3c.dom.Companion_$external_class_instanceof_17' : (x) => x instanceof Text,
        'org.w3c.dom.Companion_$external_class_get_17' : () => Text,
        'org.w3c.dom.Text_$external_class_instanceof' : (x) => x instanceof Text,
        'org.w3c.dom.Text_$external_class_get' : () => Text,
        'org.w3c.dom.CDATASection_$external_fun' : () => new CDATASection(),
        'org.w3c.dom.Companion_$external_fun_18' : () => new CDATASection(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_8' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_8' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_8' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_8' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_8' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_8' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_8' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_8' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_8' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_8' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_8' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_8' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_8' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_8' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_8' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_8' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_8' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_8' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_18' : () => CDATASection,
        'org.w3c.dom.Companion_$external_class_instanceof_18' : (x) => x instanceof CDATASection,
        'org.w3c.dom.Companion_$external_class_get_18' : () => CDATASection,
        'org.w3c.dom.CDATASection_$external_class_instanceof' : (x) => x instanceof CDATASection,
        'org.w3c.dom.CDATASection_$external_class_get' : () => CDATASection,
        'org.w3c.dom.Comment_$external_fun' : (p0, isDefault0) => new Comment(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.previousElementSibling_$external_prop_getter_1' : (_this) => _this.previousElementSibling,
        'org.w3c.dom.nextElementSibling_$external_prop_getter_1' : (_this) => _this.nextElementSibling,
        'org.w3c.dom.before_$external_fun_2' : (_this, p0) => _this.before(...p0),
        'org.w3c.dom.before_$external_fun_3' : (_this, p0) => _this.before(...p0),
        'org.w3c.dom.after_$external_fun_2' : (_this, p0) => _this.after(...p0),
        'org.w3c.dom.after_$external_fun_3' : (_this, p0) => _this.after(...p0),
        'org.w3c.dom.replaceWith_$external_fun_2' : (_this, p0) => _this.replaceWith(...p0),
        'org.w3c.dom.replaceWith_$external_fun_3' : (_this, p0) => _this.replaceWith(...p0),
        'org.w3c.dom.remove_$external_fun_1' : (_this, ) => _this.remove(),
        'org.w3c.dom.Companion_$external_fun_19' : () => new Comment(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_9' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_9' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_9' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_9' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_9' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_9' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_9' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_9' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_9' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_9' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_9' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_9' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_9' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_9' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_9' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_9' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_9' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_9' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_19' : () => Comment,
        'org.w3c.dom.Companion_$external_class_instanceof_19' : (x) => x instanceof Comment,
        'org.w3c.dom.Companion_$external_class_get_19' : () => Comment,
        'org.w3c.dom.Comment_$external_class_instanceof' : (x) => x instanceof Comment,
        'org.w3c.dom.Comment_$external_class_get' : () => Comment,
        'org.w3c.dom.ProcessingInstruction_$external_fun' : () => new ProcessingInstruction(),
        'org.w3c.dom.target_$external_prop_getter' : (_this) => _this.target,
        'org.w3c.dom.Companion_$external_fun_20' : () => new ProcessingInstruction(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_10' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_10' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_10' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_10' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_10' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_10' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_10' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_10' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_10' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_10' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_10' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_10' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_10' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_10' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_10' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_10' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_10' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_10' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_20' : () => ProcessingInstruction,
        'org.w3c.dom.Companion_$external_class_instanceof_20' : (x) => x instanceof ProcessingInstruction,
        'org.w3c.dom.Companion_$external_class_get_20' : () => ProcessingInstruction,
        'org.w3c.dom.ProcessingInstruction_$external_class_instanceof' : (x) => x instanceof ProcessingInstruction,
        'org.w3c.dom.ProcessingInstruction_$external_class_get' : () => ProcessingInstruction,
        'org.w3c.dom.Attr_$external_fun' : () => new Attr(),
        'org.w3c.dom.namespaceURI_$external_prop_getter_1' : (_this) => _this.namespaceURI,
        'org.w3c.dom.prefix_$external_prop_getter_1' : (_this) => _this.prefix,
        'org.w3c.dom.localName_$external_prop_getter_1' : (_this) => _this.localName,
        'org.w3c.dom.name_$external_prop_getter_2' : (_this) => _this.name,
        'org.w3c.dom.value_$external_prop_getter' : (_this) => _this.value,
        'org.w3c.dom.value_$external_prop_setter' : (_this, v) => _this.value = v,
        'org.w3c.dom.ownerElement_$external_prop_getter' : (_this) => _this.ownerElement,
        'org.w3c.dom.specified_$external_prop_getter' : (_this) => _this.specified,
        'org.w3c.dom.Companion_$external_fun_21' : () => new Attr(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_11' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_11' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_11' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_11' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_11' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_11' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_11' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_11' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_11' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_11' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_11' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_11' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_11' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_11' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_11' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_11' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_11' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_11' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_21' : () => Attr,
        'org.w3c.dom.Companion_$external_class_instanceof_21' : (x) => x instanceof Attr,
        'org.w3c.dom.Companion_$external_class_get_21' : () => Attr,
        'org.w3c.dom.Attr_$external_class_instanceof' : (x) => x instanceof Attr,
        'org.w3c.dom.Attr_$external_class_get' : () => Attr,
        'org.w3c.dom.Range_$external_fun' : () => new Range(),
        'org.w3c.dom.startContainer_$external_prop_getter' : (_this) => _this.startContainer,
        'org.w3c.dom.startOffset_$external_prop_getter' : (_this) => _this.startOffset,
        'org.w3c.dom.endContainer_$external_prop_getter' : (_this) => _this.endContainer,
        'org.w3c.dom.endOffset_$external_prop_getter' : (_this) => _this.endOffset,
        'org.w3c.dom.collapsed_$external_prop_getter' : (_this) => _this.collapsed,
        'org.w3c.dom.commonAncestorContainer_$external_prop_getter' : (_this) => _this.commonAncestorContainer,
        'org.w3c.dom.setStart_$external_fun' : (_this, p0, p1) => _this.setStart(p0, p1),
        'org.w3c.dom.setEnd_$external_fun' : (_this, p0, p1) => _this.setEnd(p0, p1),
        'org.w3c.dom.setStartBefore_$external_fun' : (_this, p0) => _this.setStartBefore(p0),
        'org.w3c.dom.setStartAfter_$external_fun' : (_this, p0) => _this.setStartAfter(p0),
        'org.w3c.dom.setEndBefore_$external_fun' : (_this, p0) => _this.setEndBefore(p0),
        'org.w3c.dom.setEndAfter_$external_fun' : (_this, p0) => _this.setEndAfter(p0),
        'org.w3c.dom.collapse_$external_fun' : (_this, p0, isDefault0) => _this.collapse(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.selectNode_$external_fun' : (_this, p0) => _this.selectNode(p0),
        'org.w3c.dom.selectNodeContents_$external_fun' : (_this, p0) => _this.selectNodeContents(p0),
        'org.w3c.dom.compareBoundaryPoints_$external_fun' : (_this, p0, p1) => _this.compareBoundaryPoints(p0, p1),
        'org.w3c.dom.deleteContents_$external_fun' : (_this, ) => _this.deleteContents(),
        'org.w3c.dom.extractContents_$external_fun' : (_this, ) => _this.extractContents(),
        'org.w3c.dom.cloneContents_$external_fun' : (_this, ) => _this.cloneContents(),
        'org.w3c.dom.insertNode_$external_fun' : (_this, p0) => _this.insertNode(p0),
        'org.w3c.dom.surroundContents_$external_fun' : (_this, p0) => _this.surroundContents(p0),
        'org.w3c.dom.cloneRange_$external_fun' : (_this, ) => _this.cloneRange(),
        'org.w3c.dom.detach_$external_fun' : (_this, ) => _this.detach(),
        'org.w3c.dom.isPointInRange_$external_fun' : (_this, p0, p1) => _this.isPointInRange(p0, p1),
        'org.w3c.dom.comparePoint_$external_fun' : (_this, p0, p1) => _this.comparePoint(p0, p1),
        'org.w3c.dom.intersectsNode_$external_fun' : (_this, p0) => _this.intersectsNode(p0),
        'org.w3c.dom.getClientRects_$external_fun_1' : (_this, ) => _this.getClientRects(),
        'org.w3c.dom.getBoundingClientRect_$external_fun_1' : (_this, ) => _this.getBoundingClientRect(),
        'org.w3c.dom.createContextualFragment_$external_fun' : (_this, p0) => _this.createContextualFragment(p0),
        'org.w3c.dom.Companion_$external_fun_22' : () => new Range(),
        'org.w3c.dom.START_TO_START_$external_prop_getter' : (_this) => _this.START_TO_START,
        'org.w3c.dom.START_TO_END_$external_prop_getter' : (_this) => _this.START_TO_END,
        'org.w3c.dom.END_TO_END_$external_prop_getter' : (_this) => _this.END_TO_END,
        'org.w3c.dom.END_TO_START_$external_prop_getter' : (_this) => _this.END_TO_START,
        'org.w3c.dom.Companion_$external_object_getInstance_22' : () => Range,
        'org.w3c.dom.Companion_$external_class_instanceof_22' : (x) => x instanceof Range,
        'org.w3c.dom.Companion_$external_class_get_22' : () => Range,
        'org.w3c.dom.Range_$external_class_instanceof' : (x) => x instanceof Range,
        'org.w3c.dom.Range_$external_class_get' : () => Range,
        'org.w3c.dom.NodeIterator_$external_fun' : () => new NodeIterator(),
        'org.w3c.dom.root_$external_prop_getter' : (_this) => _this.root,
        'org.w3c.dom.referenceNode_$external_prop_getter' : (_this) => _this.referenceNode,
        'org.w3c.dom.pointerBeforeReferenceNode_$external_prop_getter' : (_this) => _this.pointerBeforeReferenceNode,
        'org.w3c.dom.whatToShow_$external_prop_getter' : (_this) => _this.whatToShow,
        'org.w3c.dom.filter_$external_prop_getter' : (_this) => _this.filter,
        'org.w3c.dom.nextNode_$external_fun' : (_this, ) => _this.nextNode(),
        'org.w3c.dom.previousNode_$external_fun' : (_this, ) => _this.previousNode(),
        'org.w3c.dom.detach_$external_fun_1' : (_this, ) => _this.detach(),
        'org.w3c.dom.NodeIterator_$external_class_instanceof' : (x) => x instanceof NodeIterator,
        'org.w3c.dom.NodeIterator_$external_class_get' : () => NodeIterator,
        'org.w3c.dom.acceptNode_$external_fun' : (_this, p0) => _this.acceptNode(p0),
        'org.w3c.dom.Companion_$external_fun_23' : () => new ({})(),
        'org.w3c.dom.FILTER_ACCEPT_$external_prop_getter' : (_this) => _this.FILTER_ACCEPT,
        'org.w3c.dom.FILTER_REJECT_$external_prop_getter' : (_this) => _this.FILTER_REJECT,
        'org.w3c.dom.FILTER_SKIP_$external_prop_getter' : (_this) => _this.FILTER_SKIP,
        'org.w3c.dom.SHOW_ALL_$external_prop_getter' : (_this) => _this.SHOW_ALL,
        'org.w3c.dom.SHOW_ELEMENT_$external_prop_getter' : (_this) => _this.SHOW_ELEMENT,
        'org.w3c.dom.SHOW_ATTRIBUTE_$external_prop_getter' : (_this) => _this.SHOW_ATTRIBUTE,
        'org.w3c.dom.SHOW_TEXT_$external_prop_getter' : (_this) => _this.SHOW_TEXT,
        'org.w3c.dom.SHOW_CDATA_SECTION_$external_prop_getter' : (_this) => _this.SHOW_CDATA_SECTION,
        'org.w3c.dom.SHOW_ENTITY_REFERENCE_$external_prop_getter' : (_this) => _this.SHOW_ENTITY_REFERENCE,
        'org.w3c.dom.SHOW_ENTITY_$external_prop_getter' : (_this) => _this.SHOW_ENTITY,
        'org.w3c.dom.SHOW_PROCESSING_INSTRUCTION_$external_prop_getter' : (_this) => _this.SHOW_PROCESSING_INSTRUCTION,
        'org.w3c.dom.SHOW_COMMENT_$external_prop_getter' : (_this) => _this.SHOW_COMMENT,
        'org.w3c.dom.SHOW_DOCUMENT_$external_prop_getter' : (_this) => _this.SHOW_DOCUMENT,
        'org.w3c.dom.SHOW_DOCUMENT_TYPE_$external_prop_getter' : (_this) => _this.SHOW_DOCUMENT_TYPE,
        'org.w3c.dom.SHOW_DOCUMENT_FRAGMENT_$external_prop_getter' : (_this) => _this.SHOW_DOCUMENT_FRAGMENT,
        'org.w3c.dom.SHOW_NOTATION_$external_prop_getter' : (_this) => _this.SHOW_NOTATION,
        'org.w3c.dom.Companion_$external_object_getInstance_23' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_23' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_23' : () => ({}),
        'org.w3c.dom.TreeWalker_$external_fun' : () => new TreeWalker(),
        'org.w3c.dom.root_$external_prop_getter_1' : (_this) => _this.root,
        'org.w3c.dom.whatToShow_$external_prop_getter_1' : (_this) => _this.whatToShow,
        'org.w3c.dom.filter_$external_prop_getter_1' : (_this) => _this.filter,
        'org.w3c.dom.currentNode_$external_prop_getter' : (_this) => _this.currentNode,
        'org.w3c.dom.currentNode_$external_prop_setter' : (_this, v) => _this.currentNode = v,
        'org.w3c.dom.parentNode_$external_fun' : (_this, ) => _this.parentNode(),
        'org.w3c.dom.firstChild_$external_fun' : (_this, ) => _this.firstChild(),
        'org.w3c.dom.lastChild_$external_fun' : (_this, ) => _this.lastChild(),
        'org.w3c.dom.previousSibling_$external_fun' : (_this, ) => _this.previousSibling(),
        'org.w3c.dom.nextSibling_$external_fun' : (_this, ) => _this.nextSibling(),
        'org.w3c.dom.previousNode_$external_fun_1' : (_this, ) => _this.previousNode(),
        'org.w3c.dom.nextNode_$external_fun_1' : (_this, ) => _this.nextNode(),
        'org.w3c.dom.TreeWalker_$external_class_instanceof' : (x) => x instanceof TreeWalker,
        'org.w3c.dom.TreeWalker_$external_class_get' : () => TreeWalker,
        'org.w3c.dom.NodeList_$external_fun' : () => new NodeList(),
        'org.w3c.dom.item_$external_fun_3' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.NodeList_$external_class_instanceof' : (x) => x instanceof NodeList,
        'org.w3c.dom.NodeList_$external_class_get' : () => NodeList,
        'org.w3c.dom.CaretPosition_$external_fun' : () => new CaretPosition(),
        'org.w3c.dom.offsetNode_$external_prop_getter' : (_this) => _this.offsetNode,
        'org.w3c.dom.offset_$external_prop_getter' : (_this) => _this.offset,
        'org.w3c.dom.getClientRect_$external_fun' : (_this, ) => _this.getClientRect(),
        'org.w3c.dom.CaretPosition_$external_class_instanceof' : (x) => x instanceof CaretPosition,
        'org.w3c.dom.CaretPosition_$external_class_get' : () => CaretPosition,
        'org.w3c.dom.Touch_$external_fun' : () => new Touch(),
        'org.w3c.dom.identifier_$external_prop_getter' : (_this) => _this.identifier,
        'org.w3c.dom.target_$external_prop_getter_1' : (_this) => _this.target,
        'org.w3c.dom.screenX_$external_prop_getter_1' : (_this) => _this.screenX,
        'org.w3c.dom.screenY_$external_prop_getter_1' : (_this) => _this.screenY,
        'org.w3c.dom.clientX_$external_prop_getter' : (_this) => _this.clientX,
        'org.w3c.dom.clientY_$external_prop_getter' : (_this) => _this.clientY,
        'org.w3c.dom.pageX_$external_prop_getter' : (_this) => _this.pageX,
        'org.w3c.dom.pageY_$external_prop_getter' : (_this) => _this.pageY,
        'org.w3c.dom.region_$external_prop_getter' : (_this) => _this.region,
        'org.w3c.dom.Touch_$external_class_instanceof' : (x) => x instanceof Touch,
        'org.w3c.dom.Touch_$external_class_get' : () => Touch,
        'org.w3c.dom.TouchList_$external_fun' : () => new TouchList(),
        'org.w3c.dom.item_$external_fun_4' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.TouchList_$external_class_instanceof' : (x) => x instanceof TouchList,
        'org.w3c.dom.TouchList_$external_class_get' : () => TouchList,
        'org.w3c.dom.DOMQuad_$external_fun' : (p0, p1, p2, p3, isDefault0, isDefault1, isDefault2, isDefault3) => new DOMQuad(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, isDefault2 ? undefined : p2, isDefault3 ? undefined : p3, ),
        'org.w3c.dom.DOMQuad_$external_fun_1' : (p0) => new DOMQuad(p0),
        'org.w3c.dom.p1_$external_prop_getter' : (_this) => _this.p1,
        'org.w3c.dom.p2_$external_prop_getter' : (_this) => _this.p2,
        'org.w3c.dom.p3_$external_prop_getter' : (_this) => _this.p3,
        'org.w3c.dom.p4_$external_prop_getter' : (_this) => _this.p4,
        'org.w3c.dom.bounds_$external_prop_getter' : (_this) => _this.bounds,
        'org.w3c.dom.DOMQuad_$external_fun_2' : () => new DOMQuad(),
        'org.w3c.dom.DOMQuad_$external_class_instanceof' : (x) => x instanceof DOMQuad,
        'org.w3c.dom.DOMQuad_$external_class_get' : () => DOMQuad,
        'org.w3c.dom.box_$external_prop_getter' : (_this) => _this.box,
        'org.w3c.dom.box_$external_prop_setter' : (_this, v) => _this.box = v,
        'org.w3c.dom.relativeTo_$external_prop_getter' : (_this) => _this.relativeTo,
        'org.w3c.dom.relativeTo_$external_prop_setter' : (_this, v) => _this.relativeTo = v,
        'org.w3c.dom.fromBox_$external_prop_getter' : (_this) => _this.fromBox,
        'org.w3c.dom.fromBox_$external_prop_setter' : (_this, v) => _this.fromBox = v,
        'org.w3c.dom.toBox_$external_prop_getter' : (_this) => _this.toBox,
        'org.w3c.dom.toBox_$external_prop_setter' : (_this, v) => _this.toBox = v,
        'org.w3c.dom.DOMRectReadOnly_$external_fun' : (p0, p1, p2, p3) => new DOMRectReadOnly(p0, p1, p2, p3),
        'org.w3c.dom.x_$external_prop_getter' : (_this) => _this.x,
        'org.w3c.dom.y_$external_prop_getter' : (_this) => _this.y,
        'org.w3c.dom.width_$external_prop_getter_2' : (_this) => _this.width,
        'org.w3c.dom.height_$external_prop_getter_2' : (_this) => _this.height,
        'org.w3c.dom.top_$external_prop_getter_2' : (_this) => _this.top,
        'org.w3c.dom.right_$external_prop_getter' : (_this) => _this.right,
        'org.w3c.dom.bottom_$external_prop_getter' : (_this) => _this.bottom,
        'org.w3c.dom.left_$external_prop_getter_1' : (_this) => _this.left,
        'org.w3c.dom.DOMRectReadOnly_$external_class_instanceof' : (x) => x instanceof DOMRectReadOnly,
        'org.w3c.dom.DOMRectReadOnly_$external_class_get' : () => DOMRectReadOnly,
        'org.w3c.dom.DOMPoint_$external_fun' : (p0) => new DOMPoint(p0),
        'org.w3c.dom.DOMPoint_$external_fun_1' : (p0, p1, p2, p3, isDefault0, isDefault1, isDefault2, isDefault3) => new DOMPoint(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, isDefault2 ? undefined : p2, isDefault3 ? undefined : p3, ),
        'org.w3c.dom.x_$external_prop_getter_1' : (_this) => _this.x,
        'org.w3c.dom.x_$external_prop_setter' : (_this, v) => _this.x = v,
        'org.w3c.dom.y_$external_prop_getter_1' : (_this) => _this.y,
        'org.w3c.dom.y_$external_prop_setter' : (_this, v) => _this.y = v,
        'org.w3c.dom.z_$external_prop_getter' : (_this) => _this.z,
        'org.w3c.dom.z_$external_prop_setter' : (_this, v) => _this.z = v,
        'org.w3c.dom.w_$external_prop_getter' : (_this) => _this.w,
        'org.w3c.dom.w_$external_prop_setter' : (_this, v) => _this.w = v,
        'org.w3c.dom.DOMPoint_$external_fun_2' : () => new DOMPoint(),
        'org.w3c.dom.DOMPoint_$external_class_instanceof' : (x) => x instanceof DOMPoint,
        'org.w3c.dom.DOMPoint_$external_class_get' : () => DOMPoint,
        'org.w3c.dom.x_$external_prop_getter_2' : (_this) => _this.x,
        'org.w3c.dom.x_$external_prop_setter_1' : (_this, v) => _this.x = v,
        'org.w3c.dom.y_$external_prop_getter_2' : (_this) => _this.y,
        'org.w3c.dom.y_$external_prop_setter_1' : (_this, v) => _this.y = v,
        'org.w3c.dom.z_$external_prop_getter_1' : (_this) => _this.z,
        'org.w3c.dom.z_$external_prop_setter_1' : (_this, v) => _this.z = v,
        'org.w3c.dom.w_$external_prop_getter_1' : (_this) => _this.w,
        'org.w3c.dom.w_$external_prop_setter_1' : (_this, v) => _this.w = v,
        'org.w3c.dom.Companion_$external_fun_24' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_24' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_24' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_24' : () => ({}),
        'org.w3c.dom.extends_$external_prop_getter' : (_this) => _this.extends,
        'org.w3c.dom.extends_$external_prop_setter' : (_this, v) => _this.extends = v,
        'org.w3c.dom.previousElementSibling_$external_prop_getter_2' : (_this) => _this.previousElementSibling,
        'org.w3c.dom.nextElementSibling_$external_prop_getter_2' : (_this) => _this.nextElementSibling,
        'org.w3c.dom.before_$external_fun_4' : (_this, p0) => _this.before(...p0),
        'org.w3c.dom.before_$external_fun_5' : (_this, p0) => _this.before(...p0),
        'org.w3c.dom.after_$external_fun_4' : (_this, p0) => _this.after(...p0),
        'org.w3c.dom.after_$external_fun_5' : (_this, p0) => _this.after(...p0),
        'org.w3c.dom.replaceWith_$external_fun_4' : (_this, p0) => _this.replaceWith(...p0),
        'org.w3c.dom.replaceWith_$external_fun_5' : (_this, p0) => _this.replaceWith(...p0),
        'org.w3c.dom.remove_$external_fun_2' : (_this, ) => _this.remove(),
        'org.w3c.dom.assignedSlot_$external_prop_getter_1' : (_this) => _this.assignedSlot,
        'org.w3c.dom.DOMTokenList_$external_fun' : () => new DOMTokenList(),
        'org.w3c.dom.value_$external_prop_getter_1' : (_this) => _this.value,
        'org.w3c.dom.value_$external_prop_setter_1' : (_this, v) => _this.value = v,
        'org.w3c.dom.contains_$external_fun_1' : (_this, p0) => _this.contains(p0),
        'org.w3c.dom.add_$external_fun' : (_this, p0) => _this.add(...p0),
        'org.w3c.dom.remove_$external_fun_3' : (_this, p0) => _this.remove(...p0),
        'org.w3c.dom.toggle_$external_fun' : (_this, p0, p1, isDefault0) => _this.toggle(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.replace_$external_fun_1' : (_this, p0, p1) => _this.replace(p0, p1),
        'org.w3c.dom.supports_$external_fun' : (_this, p0) => _this.supports(p0),
        'org.w3c.dom.item_$external_fun_5' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.DOMTokenList_$external_class_instanceof' : (x) => x instanceof DOMTokenList,
        'org.w3c.dom.DOMTokenList_$external_class_get' : () => DOMTokenList,
        'org.w3c.dom.NamedNodeMap_$external_fun' : () => new NamedNodeMap(),
        'org.w3c.dom.getNamedItemNS_$external_fun' : (_this, p0, p1) => _this.getNamedItemNS(p0, p1),
        'org.w3c.dom.setNamedItem_$external_fun' : (_this, p0) => _this.setNamedItem(p0),
        'org.w3c.dom.setNamedItemNS_$external_fun' : (_this, p0) => _this.setNamedItemNS(p0),
        'org.w3c.dom.removeNamedItem_$external_fun' : (_this, p0) => _this.removeNamedItem(p0),
        'org.w3c.dom.removeNamedItemNS_$external_fun' : (_this, p0, p1) => _this.removeNamedItemNS(p0, p1),
        'org.w3c.dom.item_$external_fun_6' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.getNamedItem_$external_fun' : (_this, p0) => _this.getNamedItem(p0),
        'org.w3c.dom.NamedNodeMap_$external_class_instanceof' : (x) => x instanceof NamedNodeMap,
        'org.w3c.dom.NamedNodeMap_$external_class_get' : () => NamedNodeMap,
        'org.w3c.dom.ShadowRoot_$external_fun' : () => new ShadowRoot(),
        'org.w3c.dom.mode_$external_prop_getter' : (_this) => _this.mode,
        'org.w3c.dom.host_$external_prop_getter_1' : (_this) => _this.host,
        'org.w3c.dom.fullscreenElement_$external_prop_getter_2' : (_this) => _this.fullscreenElement,
        'org.w3c.dom.Companion_$external_fun_25' : () => new ShadowRoot(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_12' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_12' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_12' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_12' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_12' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_12' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_12' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_12' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_12' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_12' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_12' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_12' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_12' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_12' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_12' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_12' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_12' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_12' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_25' : () => ShadowRoot,
        'org.w3c.dom.Companion_$external_class_instanceof_25' : (x) => x instanceof ShadowRoot,
        'org.w3c.dom.Companion_$external_class_get_25' : () => ShadowRoot,
        'org.w3c.dom.ShadowRoot_$external_class_instanceof' : (x) => x instanceof ShadowRoot,
        'org.w3c.dom.ShadowRoot_$external_class_get' : () => ShadowRoot,
        'org.w3c.dom.mode_$external_prop_getter_1' : (_this) => _this.mode,
        'org.w3c.dom.mode_$external_prop_setter' : (_this, v) => _this.mode = v,
        'org.w3c.dom.DOMRect_$external_fun' : (p0, p1, p2, p3, isDefault0, isDefault1, isDefault2, isDefault3) => new DOMRect(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, isDefault2 ? undefined : p2, isDefault3 ? undefined : p3, ),
        'org.w3c.dom.x_$external_prop_getter_3' : (_this) => _this.x,
        'org.w3c.dom.x_$external_prop_setter_2' : (_this, v) => _this.x = v,
        'org.w3c.dom.y_$external_prop_getter_3' : (_this) => _this.y,
        'org.w3c.dom.y_$external_prop_setter_2' : (_this, v) => _this.y = v,
        'org.w3c.dom.width_$external_prop_getter_3' : (_this) => _this.width,
        'org.w3c.dom.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.w3c.dom.height_$external_prop_getter_3' : (_this) => _this.height,
        'org.w3c.dom.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.w3c.dom.DOMRect_$external_class_instanceof' : (x) => x instanceof DOMRect,
        'org.w3c.dom.DOMRect_$external_class_get' : () => DOMRect,
        'org.w3c.dom.appCodeName_$external_prop_getter' : (_this) => _this.appCodeName,
        'org.w3c.dom.appName_$external_prop_getter' : (_this) => _this.appName,
        'org.w3c.dom.appVersion_$external_prop_getter' : (_this) => _this.appVersion,
        'org.w3c.dom.platform_$external_prop_getter' : (_this) => _this.platform,
        'org.w3c.dom.product_$external_prop_getter' : (_this) => _this.product,
        'org.w3c.dom.productSub_$external_prop_getter' : (_this) => _this.productSub,
        'org.w3c.dom.userAgent_$external_prop_getter' : (_this) => _this.userAgent,
        'org.w3c.dom.vendor_$external_prop_getter' : (_this) => _this.vendor,
        'org.w3c.dom.vendorSub_$external_prop_getter' : (_this) => _this.vendorSub,
        'org.w3c.dom.oscpu_$external_prop_getter' : (_this) => _this.oscpu,
        'org.w3c.dom.taintEnabled_$external_fun' : (_this, ) => _this.taintEnabled(),
        'org.w3c.dom.language_$external_prop_getter' : (_this) => _this.language,
        'org.w3c.dom.languages_$external_prop_getter' : (_this) => _this.languages,
        'org.w3c.dom.onLine_$external_prop_getter' : (_this) => _this.onLine,
        'org.w3c.dom.registerProtocolHandler_$external_fun' : (_this, p0, p1, p2) => _this.registerProtocolHandler(p0, p1, p2),
        'org.w3c.dom.registerContentHandler_$external_fun' : (_this, p0, p1, p2) => _this.registerContentHandler(p0, p1, p2),
        'org.w3c.dom.isProtocolHandlerRegistered_$external_fun' : (_this, p0, p1) => _this.isProtocolHandlerRegistered(p0, p1),
        'org.w3c.dom.isContentHandlerRegistered_$external_fun' : (_this, p0, p1) => _this.isContentHandlerRegistered(p0, p1),
        'org.w3c.dom.unregisterProtocolHandler_$external_fun' : (_this, p0, p1) => _this.unregisterProtocolHandler(p0, p1),
        'org.w3c.dom.unregisterContentHandler_$external_fun' : (_this, p0, p1) => _this.unregisterContentHandler(p0, p1),
        'org.w3c.dom.cookieEnabled_$external_prop_getter' : (_this) => _this.cookieEnabled,
        'org.w3c.dom.plugins_$external_prop_getter_1' : (_this) => _this.plugins,
        'org.w3c.dom.mimeTypes_$external_prop_getter' : (_this) => _this.mimeTypes,
        'org.w3c.dom.javaEnabled_$external_fun' : (_this, ) => _this.javaEnabled(),
        'org.w3c.dom.hardwareConcurrency_$external_prop_getter' : (_this) => _this.hardwareConcurrency,
        'org.w3c.dom.behavior_$external_prop_getter' : (_this) => _this.behavior,
        'org.w3c.dom.behavior_$external_prop_setter' : (_this, v) => _this.behavior = v,
        'org.w3c.dom.dataTransfer_$external_prop_getter_1' : (_this) => _this.dataTransfer,
        'org.w3c.dom.dataTransfer_$external_prop_setter' : (_this, v) => _this.dataTransfer = v,
        'org.w3c.dom.DataTransfer_$external_fun' : () => new DataTransfer(),
        'org.w3c.dom.dropEffect_$external_prop_getter' : (_this) => _this.dropEffect,
        'org.w3c.dom.dropEffect_$external_prop_setter' : (_this, v) => _this.dropEffect = v,
        'org.w3c.dom.effectAllowed_$external_prop_getter' : (_this) => _this.effectAllowed,
        'org.w3c.dom.effectAllowed_$external_prop_setter' : (_this, v) => _this.effectAllowed = v,
        'org.w3c.dom.items_$external_prop_getter' : (_this) => _this.items,
        'org.w3c.dom.types_$external_prop_getter' : (_this) => _this.types,
        'org.w3c.dom.files_$external_prop_getter' : (_this) => _this.files,
        'org.w3c.dom.setDragImage_$external_fun' : (_this, p0, p1, p2) => _this.setDragImage(p0, p1, p2),
        'org.w3c.dom.getData_$external_fun' : (_this, p0) => _this.getData(p0),
        'org.w3c.dom.setData_$external_fun' : (_this, p0, p1) => _this.setData(p0, p1),
        'org.w3c.dom.clearData_$external_fun' : (_this, p0, isDefault0) => _this.clearData(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.DataTransfer_$external_class_instanceof' : (x) => x instanceof DataTransfer,
        'org.w3c.dom.DataTransfer_$external_class_get' : () => DataTransfer,
        'org.w3c.dom.oldURL_$external_prop_getter_1' : (_this) => _this.oldURL,
        'org.w3c.dom.oldURL_$external_prop_setter' : (_this, v) => _this.oldURL = v,
        'org.w3c.dom.newURL_$external_prop_getter_1' : (_this) => _this.newURL,
        'org.w3c.dom.newURL_$external_prop_setter' : (_this, v) => _this.newURL = v,
        'org.w3c.dom.data_$external_prop_getter_1' : (_this) => _this.data,
        'org.w3c.dom.data_$external_prop_setter' : (_this, v) => _this.data = v,
        'org.w3c.dom.origin_$external_prop_getter_4' : (_this) => _this.origin,
        'org.w3c.dom.origin_$external_prop_setter' : (_this, v) => _this.origin = v,
        'org.w3c.dom.lastEventId_$external_prop_getter_1' : (_this) => _this.lastEventId,
        'org.w3c.dom.lastEventId_$external_prop_setter' : (_this, v) => _this.lastEventId = v,
        'org.w3c.dom.source_$external_prop_getter_1' : (_this) => _this.source,
        'org.w3c.dom.source_$external_prop_setter' : (_this, v) => _this.source = v,
        'org.w3c.dom.ports_$external_prop_getter_1' : (_this) => _this.ports,
        'org.w3c.dom.ports_$external_prop_setter' : (_this, v) => _this.ports = v,
        'org.w3c.dom.MessagePort_$external_fun' : () => new MessagePort(),
        'org.w3c.dom.onmessage_$external_prop_getter_1' : (_this) => _this.onmessage,
        'org.w3c.dom.onmessage_$external_prop_setter_1' : (_this, v) => _this.onmessage = v,
        'org.w3c.dom.postMessage_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.postMessage(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.start_$external_fun' : (_this, ) => _this.start(),
        'org.w3c.dom.close_$external_fun_3' : (_this, ) => _this.close(),
        'org.w3c.dom.MessagePort_$external_class_instanceof' : (x) => x instanceof MessagePort,
        'org.w3c.dom.MessagePort_$external_class_get' : () => MessagePort,
        'org.w3c.dom.persisted_$external_prop_getter_1' : (_this) => _this.persisted,
        'org.w3c.dom.persisted_$external_prop_setter' : (_this, v) => _this.persisted = v,
        'org.w3c.dom.state_$external_prop_getter_2' : (_this) => _this.state,
        'org.w3c.dom.state_$external_prop_setter' : (_this, v) => _this.state = v,
        'org.w3c.dom.key_$external_prop_getter_1' : (_this) => _this.key,
        'org.w3c.dom.key_$external_prop_setter' : (_this, v) => _this.key = v,
        'org.w3c.dom.oldValue_$external_prop_getter_1' : (_this) => _this.oldValue,
        'org.w3c.dom.oldValue_$external_prop_setter' : (_this, v) => _this.oldValue = v,
        'org.w3c.dom.newValue_$external_prop_getter_1' : (_this) => _this.newValue,
        'org.w3c.dom.newValue_$external_prop_setter' : (_this, v) => _this.newValue = v,
        'org.w3c.dom.url_$external_prop_getter_1' : (_this) => _this.url,
        'org.w3c.dom.url_$external_prop_setter' : (_this, v) => _this.url = v,
        'org.w3c.dom.storageArea_$external_prop_getter_1' : (_this) => _this.storageArea,
        'org.w3c.dom.storageArea_$external_prop_setter' : (_this, v) => _this.storageArea = v,
        'org.w3c.dom.promise_$external_prop_getter_1' : (_this) => _this.promise,
        'org.w3c.dom.promise_$external_prop_setter' : (_this, v) => _this.promise = v,
        'org.w3c.dom.reason_$external_prop_getter_1' : (_this) => _this.reason,
        'org.w3c.dom.reason_$external_prop_setter' : (_this, v) => _this.reason = v,
        'org.w3c.dom.Companion_$external_fun_26' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_26' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_26' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_26' : () => ({}),
        'org.w3c.dom.Companion_$external_fun_27' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_27' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_27' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_27' : () => ({}),
        'org.w3c.dom.Companion_$external_fun_28' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_28' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_28' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_28' : () => ({}),
        'org.w3c.dom.Companion_$external_fun_29' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_29' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_29' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_29' : () => ({}),
        'org.w3c.dom.composed_$external_prop_getter' : (_this) => _this.composed,
        'org.w3c.dom.composed_$external_prop_setter' : (_this, v) => _this.composed = v,
        'org.w3c.dom.XMLDocument_$external_fun' : () => new XMLDocument(),
        'org.w3c.dom.Companion_$external_fun_30' : () => new XMLDocument(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_13' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_13' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_13' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_13' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_13' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_13' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_13' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_13' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_13' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_13' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_13' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_13' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_13' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_13' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_13' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_13' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_13' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_13' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_30' : () => XMLDocument,
        'org.w3c.dom.Companion_$external_class_instanceof_30' : (x) => x instanceof XMLDocument,
        'org.w3c.dom.Companion_$external_class_get_30' : () => XMLDocument,
        'org.w3c.dom.XMLDocument_$external_class_instanceof' : (x) => x instanceof XMLDocument,
        'org.w3c.dom.XMLDocument_$external_class_get' : () => XMLDocument,
        'org.w3c.dom.contentEditable_$external_prop_getter' : (_this) => _this.contentEditable,
        'org.w3c.dom.contentEditable_$external_prop_setter' : (_this, v) => _this.contentEditable = v,
        'org.w3c.dom.isContentEditable_$external_prop_getter' : (_this) => _this.isContentEditable,
        'org.w3c.dom.DOMStringMap_$external_fun' : () => new DOMStringMap(),
        'org.w3c.dom.DOMStringMap_$external_class_instanceof' : (x) => x instanceof DOMStringMap,
        'org.w3c.dom.DOMStringMap_$external_class_get' : () => DOMStringMap,
        'org.w3c.dom.HTMLMenuElement_$external_fun' : () => new HTMLMenuElement(),
        'org.w3c.dom.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.dom.type_$external_prop_setter' : (_this, v) => _this.type = v,
        'org.w3c.dom.label_$external_prop_getter' : (_this) => _this.label,
        'org.w3c.dom.label_$external_prop_setter' : (_this, v) => _this.label = v,
        'org.w3c.dom.compact_$external_prop_getter' : (_this) => _this.compact,
        'org.w3c.dom.compact_$external_prop_setter' : (_this, v) => _this.compact = v,
        'org.w3c.dom.Companion_$external_fun_31' : () => new HTMLMenuElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_14' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_14' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_14' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_14' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_14' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_14' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_14' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_14' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_14' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_14' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_14' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_14' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_14' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_14' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_14' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_14' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_14' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_14' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_31' : () => HTMLMenuElement,
        'org.w3c.dom.Companion_$external_class_instanceof_31' : (x) => x instanceof HTMLMenuElement,
        'org.w3c.dom.Companion_$external_class_get_31' : () => HTMLMenuElement,
        'org.w3c.dom.HTMLMenuElement_$external_class_instanceof' : (x) => x instanceof HTMLMenuElement,
        'org.w3c.dom.HTMLMenuElement_$external_class_get' : () => HTMLMenuElement,
        'org.w3c.dom.CharacterData_$external_fun' : () => new CharacterData(),
        'org.w3c.dom.data_$external_prop_getter_2' : (_this) => _this.data,
        'org.w3c.dom.data_$external_prop_setter_1' : (_this, v) => _this.data = v,
        'org.w3c.dom.length_$external_prop_getter_5' : (_this) => _this.length,
        'org.w3c.dom.substringData_$external_fun' : (_this, p0, p1) => _this.substringData(p0, p1),
        'org.w3c.dom.appendData_$external_fun' : (_this, p0) => _this.appendData(p0),
        'org.w3c.dom.insertData_$external_fun' : (_this, p0, p1) => _this.insertData(p0, p1),
        'org.w3c.dom.deleteData_$external_fun' : (_this, p0, p1) => _this.deleteData(p0, p1),
        'org.w3c.dom.replaceData_$external_fun' : (_this, p0, p1, p2) => _this.replaceData(p0, p1, p2),
        'org.w3c.dom.Companion_$external_fun_32' : () => new CharacterData(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_15' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_15' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_15' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_15' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_15' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_15' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_15' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_15' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_15' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_15' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_15' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_15' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_15' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_15' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_15' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_15' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_15' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_15' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_32' : () => CharacterData,
        'org.w3c.dom.Companion_$external_class_instanceof_32' : (x) => x instanceof CharacterData,
        'org.w3c.dom.Companion_$external_class_get_32' : () => CharacterData,
        'org.w3c.dom.CharacterData_$external_class_instanceof' : (x) => x instanceof CharacterData,
        'org.w3c.dom.CharacterData_$external_class_get' : () => CharacterData,
        'org.w3c.dom.HTMLSlotElement_$external_fun' : () => new HTMLSlotElement(),
        'org.w3c.dom.name_$external_prop_getter_3' : (_this) => _this.name,
        'org.w3c.dom.name_$external_prop_setter_1' : (_this, v) => _this.name = v,
        'org.w3c.dom.assignedNodes_$external_fun' : (_this, p0, isDefault0) => _this.assignedNodes(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.Companion_$external_fun_33' : () => new HTMLSlotElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_16' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_16' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_16' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_16' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_16' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_16' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_16' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_16' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_16' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_16' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_16' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_16' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_16' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_16' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_16' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_16' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_16' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_16' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_33' : () => HTMLSlotElement,
        'org.w3c.dom.Companion_$external_class_instanceof_33' : (x) => x instanceof HTMLSlotElement,
        'org.w3c.dom.Companion_$external_class_get_33' : () => HTMLSlotElement,
        'org.w3c.dom.HTMLSlotElement_$external_class_instanceof' : (x) => x instanceof HTMLSlotElement,
        'org.w3c.dom.HTMLSlotElement_$external_class_get' : () => HTMLSlotElement,
        'org.w3c.dom.x_$external_prop_getter_4' : (_this) => _this.x,
        'org.w3c.dom.x_$external_prop_setter_3' : (_this, v) => _this.x = v,
        'org.w3c.dom.y_$external_prop_getter_4' : (_this) => _this.y,
        'org.w3c.dom.y_$external_prop_setter_3' : (_this, v) => _this.y = v,
        'org.w3c.dom.width_$external_prop_getter_4' : (_this) => _this.width,
        'org.w3c.dom.width_$external_prop_setter_1' : (_this, v) => _this.width = v,
        'org.w3c.dom.height_$external_prop_getter_4' : (_this) => _this.height,
        'org.w3c.dom.height_$external_prop_setter_1' : (_this, v) => _this.height = v,
        'org.w3c.dom.Companion_$external_fun_34' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_34' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_34' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_34' : () => ({}),
        'org.w3c.dom.DOMPointReadOnly_$external_fun' : (p0, p1, p2, p3) => new DOMPointReadOnly(p0, p1, p2, p3),
        'org.w3c.dom.x_$external_prop_getter_5' : (_this) => _this.x,
        'org.w3c.dom.y_$external_prop_getter_5' : (_this) => _this.y,
        'org.w3c.dom.z_$external_prop_getter_2' : (_this) => _this.z,
        'org.w3c.dom.w_$external_prop_getter_2' : (_this) => _this.w,
        'org.w3c.dom.matrixTransform_$external_fun' : (_this, p0) => _this.matrixTransform(p0),
        'org.w3c.dom.DOMPointReadOnly_$external_class_instanceof' : (x) => x instanceof DOMPointReadOnly,
        'org.w3c.dom.DOMPointReadOnly_$external_class_get' : () => DOMPointReadOnly,
        'org.w3c.dom.Companion_$external_fun_35' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_35' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_35' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_35' : () => ({}),
        'org.w3c.dom.PluginArray_$external_fun' : () => new PluginArray(),
        'org.w3c.dom.refresh_$external_fun' : (_this, p0, isDefault0) => _this.refresh(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.item_$external_fun_7' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.namedItem_$external_fun_2' : (_this, p0) => _this.namedItem(p0),
        'org.w3c.dom.PluginArray_$external_class_instanceof' : (x) => x instanceof PluginArray,
        'org.w3c.dom.PluginArray_$external_class_get' : () => PluginArray,
        'org.w3c.dom.MimeTypeArray_$external_fun' : () => new MimeTypeArray(),
        'org.w3c.dom.item_$external_fun_8' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.namedItem_$external_fun_3' : (_this, p0) => _this.namedItem(p0),
        'org.w3c.dom.MimeTypeArray_$external_class_instanceof' : (x) => x instanceof MimeTypeArray,
        'org.w3c.dom.MimeTypeArray_$external_class_get' : () => MimeTypeArray,
        'org.w3c.dom.Companion_$external_fun_36' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_36' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_36' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_36' : () => ({}),
        'org.w3c.dom.DataTransferItemList_$external_fun' : () => new DataTransferItemList(),
        'org.w3c.dom.length_$external_prop_getter_6' : (_this) => _this.length,
        'org.w3c.dom.add_$external_fun_1' : (_this, p0, p1) => _this.add(p0, p1),
        'org.w3c.dom.add_$external_fun_2' : (_this, p0) => _this.add(p0),
        'org.w3c.dom.remove_$external_fun_4' : (_this, p0) => _this.remove(p0),
        'org.w3c.dom.clear_$external_fun_2' : (_this, ) => _this.clear(),
        'org.w3c.dom.DataTransferItemList_$external_class_instanceof' : (x) => x instanceof DataTransferItemList,
        'org.w3c.dom.DataTransferItemList_$external_class_get' : () => DataTransferItemList,
        'org.w3c.dom.bubbles_$external_prop_getter' : (_this) => _this.bubbles,
        'org.w3c.dom.bubbles_$external_prop_setter' : (_this, v) => _this.bubbles = v,
        'org.w3c.dom.cancelable_$external_prop_getter' : (_this) => _this.cancelable,
        'org.w3c.dom.cancelable_$external_prop_setter' : (_this, v) => _this.cancelable = v,
        'org.w3c.dom.composed_$external_prop_getter_1' : (_this) => _this.composed,
        'org.w3c.dom.composed_$external_prop_setter_1' : (_this, v) => _this.composed = v,
        'org.w3c.dom.flatten_$external_prop_getter' : (_this) => _this.flatten,
        'org.w3c.dom.flatten_$external_prop_setter' : (_this, v) => _this.flatten = v,
        'org.w3c.dom.DOMMatrixReadOnly_$external_fun' : (p0) => new DOMMatrixReadOnly(p0),
        'org.w3c.dom.a_$external_prop_getter' : (_this) => _this.a,
        'org.w3c.dom.b_$external_prop_getter' : (_this) => _this.b,
        'org.w3c.dom.c_$external_prop_getter' : (_this) => _this.c,
        'org.w3c.dom.d_$external_prop_getter' : (_this) => _this.d,
        'org.w3c.dom.e_$external_prop_getter' : (_this) => _this.e,
        'org.w3c.dom.f_$external_prop_getter' : (_this) => _this.f,
        'org.w3c.dom.m11_$external_prop_getter' : (_this) => _this.m11,
        'org.w3c.dom.m12_$external_prop_getter' : (_this) => _this.m12,
        'org.w3c.dom.m13_$external_prop_getter' : (_this) => _this.m13,
        'org.w3c.dom.m14_$external_prop_getter' : (_this) => _this.m14,
        'org.w3c.dom.m21_$external_prop_getter' : (_this) => _this.m21,
        'org.w3c.dom.m22_$external_prop_getter' : (_this) => _this.m22,
        'org.w3c.dom.m23_$external_prop_getter' : (_this) => _this.m23,
        'org.w3c.dom.m24_$external_prop_getter' : (_this) => _this.m24,
        'org.w3c.dom.m31_$external_prop_getter' : (_this) => _this.m31,
        'org.w3c.dom.m32_$external_prop_getter' : (_this) => _this.m32,
        'org.w3c.dom.m33_$external_prop_getter' : (_this) => _this.m33,
        'org.w3c.dom.m34_$external_prop_getter' : (_this) => _this.m34,
        'org.w3c.dom.m41_$external_prop_getter' : (_this) => _this.m41,
        'org.w3c.dom.m42_$external_prop_getter' : (_this) => _this.m42,
        'org.w3c.dom.m43_$external_prop_getter' : (_this) => _this.m43,
        'org.w3c.dom.m44_$external_prop_getter' : (_this) => _this.m44,
        'org.w3c.dom.is2D_$external_prop_getter' : (_this) => _this.is2D,
        'org.w3c.dom.isIdentity_$external_prop_getter' : (_this) => _this.isIdentity,
        'org.w3c.dom.translate_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.translate(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.scale_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.scale(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.scale3d_$external_fun' : (_this, p0, p1, p2, p3, isDefault0, isDefault1, isDefault2) => _this.scale3d(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, isDefault2 ? undefined : p3, ),
        'org.w3c.dom.scaleNonUniform_$external_fun' : (_this, p0, p1, p2, p3, p4, p5, isDefault0, isDefault1, isDefault2, isDefault3, isDefault4) => _this.scaleNonUniform(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, isDefault2 ? undefined : p3, isDefault3 ? undefined : p4, isDefault4 ? undefined : p5, ),
        'org.w3c.dom.rotate_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.rotate(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.rotateFromVector_$external_fun' : (_this, p0, p1) => _this.rotateFromVector(p0, p1),
        'org.w3c.dom.rotateAxisAngle_$external_fun' : (_this, p0, p1, p2, p3) => _this.rotateAxisAngle(p0, p1, p2, p3),
        'org.w3c.dom.skewX_$external_fun' : (_this, p0) => _this.skewX(p0),
        'org.w3c.dom.skewY_$external_fun' : (_this, p0) => _this.skewY(p0),
        'org.w3c.dom.multiply_$external_fun' : (_this, p0) => _this.multiply(p0),
        'org.w3c.dom.flipX_$external_fun' : (_this, ) => _this.flipX(),
        'org.w3c.dom.flipY_$external_fun' : (_this, ) => _this.flipY(),
        'org.w3c.dom.inverse_$external_fun' : (_this, ) => _this.inverse(),
        'org.w3c.dom.transformPoint_$external_fun' : (_this, p0, isDefault0) => _this.transformPoint(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.toFloat32Array_$external_fun' : (_this, ) => _this.toFloat32Array(),
        'org.w3c.dom.toFloat64Array_$external_fun' : (_this, ) => _this.toFloat64Array(),
        'org.w3c.dom.DOMMatrixReadOnly_$external_class_instanceof' : (x) => x instanceof DOMMatrixReadOnly,
        'org.w3c.dom.DOMMatrixReadOnly_$external_class_get' : () => DOMMatrixReadOnly,
        'org.w3c.dom.Plugin_$external_fun' : () => new Plugin(),
        'org.w3c.dom.name_$external_prop_getter_4' : (_this) => _this.name,
        'org.w3c.dom.description_$external_prop_getter' : (_this) => _this.description,
        'org.w3c.dom.filename_$external_prop_getter' : (_this) => _this.filename,
        'org.w3c.dom.item_$external_fun_9' : (_this, p0) => _this.item(p0),
        'org.w3c.dom.namedItem_$external_fun_4' : (_this, p0) => _this.namedItem(p0),
        'org.w3c.dom.Plugin_$external_class_instanceof' : (x) => x instanceof Plugin,
        'org.w3c.dom.Plugin_$external_class_get' : () => Plugin,
        'org.w3c.dom.MimeType_$external_fun' : () => new MimeType(),
        'org.w3c.dom.type_$external_prop_getter_1' : (_this) => _this.type,
        'org.w3c.dom.description_$external_prop_getter_1' : (_this) => _this.description,
        'org.w3c.dom.suffixes_$external_prop_getter' : (_this) => _this.suffixes,
        'org.w3c.dom.enabledPlugin_$external_prop_getter' : (_this) => _this.enabledPlugin,
        'org.w3c.dom.MimeType_$external_class_instanceof' : (x) => x instanceof MimeType,
        'org.w3c.dom.MimeType_$external_class_get' : () => MimeType,
        'org.w3c.dom.DataTransferItem_$external_fun' : () => new DataTransferItem(),
        'org.w3c.dom.kind_$external_prop_getter' : (_this) => _this.kind,
        'org.w3c.dom.type_$external_prop_getter_2' : (_this) => _this.type,
        'org.w3c.dom.getAsString_$external_fun' : (_this, p0) => _this.getAsString(p0),
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((String)->Unit)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((String)->Unit)'](f, p0)),
        'org.w3c.dom.getAsFile_$external_fun' : (_this, ) => _this.getAsFile(),
        'org.w3c.dom.DataTransferItem_$external_class_instanceof' : (x) => x instanceof DataTransferItem,
        'org.w3c.dom.DataTransferItem_$external_class_get' : () => DataTransferItem,
        'org.w3c.dom.DOMMatrix_$external_fun' : () => new DOMMatrix(),
        'org.w3c.dom.DOMMatrix_$external_fun_1' : (p0) => new DOMMatrix(p0),
        'org.w3c.dom.DOMMatrix_$external_fun_2' : (p0) => new DOMMatrix(p0),
        'org.w3c.dom.DOMMatrix_$external_fun_3' : (p0) => new DOMMatrix(p0),
        'org.w3c.dom.DOMMatrix_$external_fun_4' : (p0) => new DOMMatrix(p0),
        'org.w3c.dom.DOMMatrix_$external_fun_5' : (p0) => new DOMMatrix(p0),
        'org.w3c.dom.a_$external_prop_getter_1' : (_this) => _this.a,
        'org.w3c.dom.a_$external_prop_setter' : (_this, v) => _this.a = v,
        'org.w3c.dom.b_$external_prop_getter_1' : (_this) => _this.b,
        'org.w3c.dom.b_$external_prop_setter' : (_this, v) => _this.b = v,
        'org.w3c.dom.c_$external_prop_getter_1' : (_this) => _this.c,
        'org.w3c.dom.c_$external_prop_setter' : (_this, v) => _this.c = v,
        'org.w3c.dom.d_$external_prop_getter_1' : (_this) => _this.d,
        'org.w3c.dom.d_$external_prop_setter' : (_this, v) => _this.d = v,
        'org.w3c.dom.e_$external_prop_getter_1' : (_this) => _this.e,
        'org.w3c.dom.e_$external_prop_setter' : (_this, v) => _this.e = v,
        'org.w3c.dom.f_$external_prop_getter_1' : (_this) => _this.f,
        'org.w3c.dom.f_$external_prop_setter' : (_this, v) => _this.f = v,
        'org.w3c.dom.m11_$external_prop_getter_1' : (_this) => _this.m11,
        'org.w3c.dom.m11_$external_prop_setter' : (_this, v) => _this.m11 = v,
        'org.w3c.dom.m12_$external_prop_getter_1' : (_this) => _this.m12,
        'org.w3c.dom.m12_$external_prop_setter' : (_this, v) => _this.m12 = v,
        'org.w3c.dom.m13_$external_prop_getter_1' : (_this) => _this.m13,
        'org.w3c.dom.m13_$external_prop_setter' : (_this, v) => _this.m13 = v,
        'org.w3c.dom.m14_$external_prop_getter_1' : (_this) => _this.m14,
        'org.w3c.dom.m14_$external_prop_setter' : (_this, v) => _this.m14 = v,
        'org.w3c.dom.m21_$external_prop_getter_1' : (_this) => _this.m21,
        'org.w3c.dom.m21_$external_prop_setter' : (_this, v) => _this.m21 = v,
        'org.w3c.dom.m22_$external_prop_getter_1' : (_this) => _this.m22,
        'org.w3c.dom.m22_$external_prop_setter' : (_this, v) => _this.m22 = v,
        'org.w3c.dom.m23_$external_prop_getter_1' : (_this) => _this.m23,
        'org.w3c.dom.m23_$external_prop_setter' : (_this, v) => _this.m23 = v,
        'org.w3c.dom.m24_$external_prop_getter_1' : (_this) => _this.m24,
        'org.w3c.dom.m24_$external_prop_setter' : (_this, v) => _this.m24 = v,
        'org.w3c.dom.m31_$external_prop_getter_1' : (_this) => _this.m31,
        'org.w3c.dom.m31_$external_prop_setter' : (_this, v) => _this.m31 = v,
        'org.w3c.dom.m32_$external_prop_getter_1' : (_this) => _this.m32,
        'org.w3c.dom.m32_$external_prop_setter' : (_this, v) => _this.m32 = v,
        'org.w3c.dom.m33_$external_prop_getter_1' : (_this) => _this.m33,
        'org.w3c.dom.m33_$external_prop_setter' : (_this, v) => _this.m33 = v,
        'org.w3c.dom.m34_$external_prop_getter_1' : (_this) => _this.m34,
        'org.w3c.dom.m34_$external_prop_setter' : (_this, v) => _this.m34 = v,
        'org.w3c.dom.m41_$external_prop_getter_1' : (_this) => _this.m41,
        'org.w3c.dom.m41_$external_prop_setter' : (_this, v) => _this.m41 = v,
        'org.w3c.dom.m42_$external_prop_getter_1' : (_this) => _this.m42,
        'org.w3c.dom.m42_$external_prop_setter' : (_this, v) => _this.m42 = v,
        'org.w3c.dom.m43_$external_prop_getter_1' : (_this) => _this.m43,
        'org.w3c.dom.m43_$external_prop_setter' : (_this, v) => _this.m43 = v,
        'org.w3c.dom.m44_$external_prop_getter_1' : (_this) => _this.m44,
        'org.w3c.dom.m44_$external_prop_setter' : (_this, v) => _this.m44 = v,
        'org.w3c.dom.multiplySelf_$external_fun' : (_this, p0) => _this.multiplySelf(p0),
        'org.w3c.dom.preMultiplySelf_$external_fun' : (_this, p0) => _this.preMultiplySelf(p0),
        'org.w3c.dom.translateSelf_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.translateSelf(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.scaleSelf_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.scaleSelf(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.scale3dSelf_$external_fun' : (_this, p0, p1, p2, p3, isDefault0, isDefault1, isDefault2) => _this.scale3dSelf(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, isDefault2 ? undefined : p3, ),
        'org.w3c.dom.scaleNonUniformSelf_$external_fun' : (_this, p0, p1, p2, p3, p4, p5, isDefault0, isDefault1, isDefault2, isDefault3, isDefault4) => _this.scaleNonUniformSelf(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, isDefault2 ? undefined : p3, isDefault3 ? undefined : p4, isDefault4 ? undefined : p5, ),
        'org.w3c.dom.rotateSelf_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.rotateSelf(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.rotateFromVectorSelf_$external_fun' : (_this, p0, p1) => _this.rotateFromVectorSelf(p0, p1),
        'org.w3c.dom.rotateAxisAngleSelf_$external_fun' : (_this, p0, p1, p2, p3) => _this.rotateAxisAngleSelf(p0, p1, p2, p3),
        'org.w3c.dom.skewXSelf_$external_fun' : (_this, p0) => _this.skewXSelf(p0),
        'org.w3c.dom.skewYSelf_$external_fun' : (_this, p0) => _this.skewYSelf(p0),
        'org.w3c.dom.invertSelf_$external_fun' : (_this, ) => _this.invertSelf(),
        'org.w3c.dom.setMatrixValue_$external_fun' : (_this, p0) => _this.setMatrixValue(p0),
        'org.w3c.dom.DOMMatrix_$external_class_instanceof' : (x) => x instanceof DOMMatrix,
        'org.w3c.dom.DOMMatrix_$external_class_get' : () => DOMMatrix,
        'org.w3c.dom.onerror_$external_prop_getter_3' : (_this) => _this.onerror,
        'org.w3c.dom.onerror_$external_prop_setter_3' : (_this, v) => _this.onerror = v,
        'org.w3c.dom.Companion_$external_fun_37' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_37' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_37' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_37' : () => ({}),
        'org.w3c.dom.HTMLFormElement_$external_fun' : () => new HTMLFormElement(),
        'org.w3c.dom.acceptCharset_$external_prop_getter' : (_this) => _this.acceptCharset,
        'org.w3c.dom.acceptCharset_$external_prop_setter' : (_this, v) => _this.acceptCharset = v,
        'org.w3c.dom.action_$external_prop_getter' : (_this) => _this.action,
        'org.w3c.dom.action_$external_prop_setter' : (_this, v) => _this.action = v,
        'org.w3c.dom.autocomplete_$external_prop_getter' : (_this) => _this.autocomplete,
        'org.w3c.dom.autocomplete_$external_prop_setter' : (_this, v) => _this.autocomplete = v,
        'org.w3c.dom.enctype_$external_prop_getter' : (_this) => _this.enctype,
        'org.w3c.dom.enctype_$external_prop_setter' : (_this, v) => _this.enctype = v,
        'org.w3c.dom.encoding_$external_prop_getter' : (_this) => _this.encoding,
        'org.w3c.dom.encoding_$external_prop_setter' : (_this, v) => _this.encoding = v,
        'org.w3c.dom.method_$external_prop_getter' : (_this) => _this.method,
        'org.w3c.dom.method_$external_prop_setter' : (_this, v) => _this.method = v,
        'org.w3c.dom.name_$external_prop_getter_5' : (_this) => _this.name,
        'org.w3c.dom.name_$external_prop_setter_2' : (_this, v) => _this.name = v,
        'org.w3c.dom.noValidate_$external_prop_getter' : (_this) => _this.noValidate,
        'org.w3c.dom.noValidate_$external_prop_setter' : (_this, v) => _this.noValidate = v,
        'org.w3c.dom.target_$external_prop_getter_2' : (_this) => _this.target,
        'org.w3c.dom.target_$external_prop_setter' : (_this, v) => _this.target = v,
        'org.w3c.dom.elements_$external_prop_getter' : (_this) => _this.elements,
        'org.w3c.dom.length_$external_prop_getter_7' : (_this) => _this.length,
        'org.w3c.dom.submit_$external_fun' : (_this, ) => _this.submit(),
        'org.w3c.dom.reset_$external_fun' : (_this, ) => _this.reset(),
        'org.w3c.dom.checkValidity_$external_fun' : (_this, ) => _this.checkValidity(),
        'org.w3c.dom.reportValidity_$external_fun' : (_this, ) => _this.reportValidity(),
        'org.w3c.dom.Companion_$external_fun_38' : () => new HTMLFormElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_17' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_17' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_17' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_17' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_17' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_17' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_17' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_17' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_17' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_17' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_17' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_17' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_17' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_17' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_17' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_17' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_17' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_17' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_38' : () => HTMLFormElement,
        'org.w3c.dom.Companion_$external_class_instanceof_38' : (x) => x instanceof HTMLFormElement,
        'org.w3c.dom.Companion_$external_class_get_38' : () => HTMLFormElement,
        'org.w3c.dom.HTMLFormElement_$external_class_instanceof' : (x) => x instanceof HTMLFormElement,
        'org.w3c.dom.HTMLFormElement_$external_class_get' : () => HTMLFormElement,
        'org.w3c.dom.passive_$external_prop_getter' : (_this) => _this.passive,
        'org.w3c.dom.passive_$external_prop_setter' : (_this, v) => _this.passive = v,
        'org.w3c.dom.once_$external_prop_getter' : (_this) => _this.once,
        'org.w3c.dom.once_$external_prop_setter' : (_this, v) => _this.once = v,
        'org.w3c.dom.capture_$external_prop_getter' : (_this) => _this.capture,
        'org.w3c.dom.capture_$external_prop_setter' : (_this, v) => _this.capture = v,
        'org.w3c.dom.HTMLFormControlsCollection_$external_fun' : () => new HTMLFormControlsCollection(),
        'org.w3c.dom.HTMLFormControlsCollection_$external_class_instanceof' : (x) => x instanceof HTMLFormControlsCollection,
        'org.w3c.dom.HTMLFormControlsCollection_$external_class_get' : () => HTMLFormControlsCollection,
        'org.w3c.dom.HTMLCanvasElement_$external_fun' : () => new HTMLCanvasElement(),
        'org.w3c.dom.width_$external_prop_getter_5' : (_this) => _this.width,
        'org.w3c.dom.width_$external_prop_setter_2' : (_this, v) => _this.width = v,
        'org.w3c.dom.height_$external_prop_getter_5' : (_this) => _this.height,
        'org.w3c.dom.height_$external_prop_setter_2' : (_this, v) => _this.height = v,
        'org.w3c.dom.getContext_$external_fun' : (_this, p0, p1) => _this.getContext(p0, ...p1),
        'org.w3c.dom.toDataURL_$external_fun' : (_this, p0, p1, isDefault0, isDefault1) => _this.toDataURL(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.dom.toBlob_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1) => _this.toBlob(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((Js?)->Unit)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js?)->Unit)'](f, p0)),
        'org.w3c.dom.Companion_$external_fun_39' : () => new HTMLCanvasElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_18' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_18' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_18' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_18' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_18' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_18' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_18' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_18' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_18' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_18' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_18' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_18' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_18' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_18' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_18' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_18' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_18' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_18' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_39' : () => HTMLCanvasElement,
        'org.w3c.dom.Companion_$external_class_instanceof_39' : (x) => x instanceof HTMLCanvasElement,
        'org.w3c.dom.Companion_$external_class_get_39' : () => HTMLCanvasElement,
        'org.w3c.dom.HTMLCanvasElement_$external_class_instanceof' : (x) => x instanceof HTMLCanvasElement,
        'org.w3c.dom.HTMLCanvasElement_$external_class_get' : () => HTMLCanvasElement,
        'org.w3c.dom.TouchEvent_$external_fun' : () => new TouchEvent(),
        'org.w3c.dom.touches_$external_prop_getter' : (_this) => _this.touches,
        'org.w3c.dom.targetTouches_$external_prop_getter' : (_this) => _this.targetTouches,
        'org.w3c.dom.changedTouches_$external_prop_getter' : (_this) => _this.changedTouches,
        'org.w3c.dom.altKey_$external_prop_getter' : (_this) => _this.altKey,
        'org.w3c.dom.metaKey_$external_prop_getter' : (_this) => _this.metaKey,
        'org.w3c.dom.ctrlKey_$external_prop_getter' : (_this) => _this.ctrlKey,
        'org.w3c.dom.shiftKey_$external_prop_getter' : (_this) => _this.shiftKey,
        'org.w3c.dom.Companion_$external_fun_40' : () => new TouchEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_8' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_8' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_8' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_8' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_40' : () => TouchEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_40' : (x) => x instanceof TouchEvent,
        'org.w3c.dom.Companion_$external_class_get_40' : () => TouchEvent,
        'org.w3c.dom.TouchEvent_$external_class_instanceof' : (x) => x instanceof TouchEvent,
        'org.w3c.dom.TouchEvent_$external_class_get' : () => TouchEvent,
        'org.w3c.dom.MediaQueryListEvent_$external_fun' : (p0, p1, isDefault0) => new MediaQueryListEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.media_$external_prop_getter_1' : (_this) => _this.media,
        'org.w3c.dom.matches_$external_prop_getter_1' : (_this) => _this.matches,
        'org.w3c.dom.Companion_$external_fun_41' : () => new MediaQueryListEvent(),
        'org.w3c.dom.NONE_$external_prop_getter_9' : (_this) => _this.NONE,
        'org.w3c.dom.CAPTURING_PHASE_$external_prop_getter_9' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.AT_TARGET_$external_prop_getter_9' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.BUBBLING_PHASE_$external_prop_getter_9' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.Companion_$external_object_getInstance_41' : () => MediaQueryListEvent,
        'org.w3c.dom.Companion_$external_class_instanceof_41' : (x) => x instanceof MediaQueryListEvent,
        'org.w3c.dom.Companion_$external_class_get_41' : () => MediaQueryListEvent,
        'org.w3c.dom.MediaQueryListEvent_$external_class_instanceof' : (x) => x instanceof MediaQueryListEvent,
        'org.w3c.dom.MediaQueryListEvent_$external_class_get' : () => MediaQueryListEvent,
        'org.w3c.dom.HTMLTextAreaElement_$external_fun' : () => new HTMLTextAreaElement(),
        'org.w3c.dom.autocomplete_$external_prop_getter_1' : (_this) => _this.autocomplete,
        'org.w3c.dom.autocomplete_$external_prop_setter_1' : (_this, v) => _this.autocomplete = v,
        'org.w3c.dom.autofocus_$external_prop_getter' : (_this) => _this.autofocus,
        'org.w3c.dom.autofocus_$external_prop_setter' : (_this, v) => _this.autofocus = v,
        'org.w3c.dom.cols_$external_prop_getter' : (_this) => _this.cols,
        'org.w3c.dom.cols_$external_prop_setter' : (_this, v) => _this.cols = v,
        'org.w3c.dom.dirName_$external_prop_getter' : (_this) => _this.dirName,
        'org.w3c.dom.dirName_$external_prop_setter' : (_this, v) => _this.dirName = v,
        'org.w3c.dom.disabled_$external_prop_getter' : (_this) => _this.disabled,
        'org.w3c.dom.disabled_$external_prop_setter' : (_this, v) => _this.disabled = v,
        'org.w3c.dom.form_$external_prop_getter' : (_this) => _this.form,
        'org.w3c.dom.inputMode_$external_prop_getter' : (_this) => _this.inputMode,
        'org.w3c.dom.inputMode_$external_prop_setter' : (_this, v) => _this.inputMode = v,
        'org.w3c.dom.maxLength_$external_prop_getter' : (_this) => _this.maxLength,
        'org.w3c.dom.maxLength_$external_prop_setter' : (_this, v) => _this.maxLength = v,
        'org.w3c.dom.minLength_$external_prop_getter' : (_this) => _this.minLength,
        'org.w3c.dom.minLength_$external_prop_setter' : (_this, v) => _this.minLength = v,
        'org.w3c.dom.name_$external_prop_getter_6' : (_this) => _this.name,
        'org.w3c.dom.name_$external_prop_setter_3' : (_this, v) => _this.name = v,
        'org.w3c.dom.placeholder_$external_prop_getter' : (_this) => _this.placeholder,
        'org.w3c.dom.placeholder_$external_prop_setter' : (_this, v) => _this.placeholder = v,
        'org.w3c.dom.readOnly_$external_prop_getter' : (_this) => _this.readOnly,
        'org.w3c.dom.readOnly_$external_prop_setter' : (_this, v) => _this.readOnly = v,
        'org.w3c.dom.required_$external_prop_getter' : (_this) => _this.required,
        'org.w3c.dom.required_$external_prop_setter' : (_this, v) => _this.required = v,
        'org.w3c.dom.rows_$external_prop_getter' : (_this) => _this.rows,
        'org.w3c.dom.rows_$external_prop_setter' : (_this, v) => _this.rows = v,
        'org.w3c.dom.wrap_$external_prop_getter' : (_this) => _this.wrap,
        'org.w3c.dom.wrap_$external_prop_setter' : (_this, v) => _this.wrap = v,
        'org.w3c.dom.type_$external_prop_getter_3' : (_this) => _this.type,
        'org.w3c.dom.defaultValue_$external_prop_getter' : (_this) => _this.defaultValue,
        'org.w3c.dom.defaultValue_$external_prop_setter' : (_this, v) => _this.defaultValue = v,
        'org.w3c.dom.value_$external_prop_getter_2' : (_this) => _this.value,
        'org.w3c.dom.value_$external_prop_setter_2' : (_this, v) => _this.value = v,
        'org.w3c.dom.textLength_$external_prop_getter' : (_this) => _this.textLength,
        'org.w3c.dom.willValidate_$external_prop_getter' : (_this) => _this.willValidate,
        'org.w3c.dom.validity_$external_prop_getter' : (_this) => _this.validity,
        'org.w3c.dom.validationMessage_$external_prop_getter' : (_this) => _this.validationMessage,
        'org.w3c.dom.labels_$external_prop_getter' : (_this) => _this.labels,
        'org.w3c.dom.selectionStart_$external_prop_getter' : (_this) => _this.selectionStart,
        'org.w3c.dom.selectionStart_$external_prop_setter' : (_this, v) => _this.selectionStart = v,
        'org.w3c.dom.selectionEnd_$external_prop_getter' : (_this) => _this.selectionEnd,
        'org.w3c.dom.selectionEnd_$external_prop_setter' : (_this, v) => _this.selectionEnd = v,
        'org.w3c.dom.selectionDirection_$external_prop_getter' : (_this) => _this.selectionDirection,
        'org.w3c.dom.selectionDirection_$external_prop_setter' : (_this, v) => _this.selectionDirection = v,
        'org.w3c.dom.checkValidity_$external_fun_1' : (_this, ) => _this.checkValidity(),
        'org.w3c.dom.reportValidity_$external_fun_1' : (_this, ) => _this.reportValidity(),
        'org.w3c.dom.setCustomValidity_$external_fun' : (_this, p0) => _this.setCustomValidity(p0),
        'org.w3c.dom.select_$external_fun' : (_this, ) => _this.select(),
        'org.w3c.dom.setRangeText_$external_fun' : (_this, p0) => _this.setRangeText(p0),
        'org.w3c.dom.setRangeText_$external_fun_1' : (_this, p0, p1, p2, p3, isDefault0) => _this.setRangeText(p0, p1, p2, isDefault0 ? undefined : p3, ),
        'org.w3c.dom.setSelectionRange_$external_fun' : (_this, p0, p1, p2, isDefault0) => _this.setSelectionRange(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.dom.Companion_$external_fun_42' : () => new HTMLTextAreaElement(),
        'org.w3c.dom.ELEMENT_NODE_$external_prop_getter_19' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.ATTRIBUTE_NODE_$external_prop_getter_19' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.TEXT_NODE_$external_prop_getter_19' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.CDATA_SECTION_NODE_$external_prop_getter_19' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.ENTITY_REFERENCE_NODE_$external_prop_getter_19' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.ENTITY_NODE_$external_prop_getter_19' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_19' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.COMMENT_NODE_$external_prop_getter_19' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.DOCUMENT_NODE_$external_prop_getter_19' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.DOCUMENT_TYPE_NODE_$external_prop_getter_19' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_19' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.NOTATION_NODE_$external_prop_getter_19' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_19' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_19' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_19' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_19' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_19' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_19' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.Companion_$external_object_getInstance_42' : () => HTMLTextAreaElement,
        'org.w3c.dom.Companion_$external_class_instanceof_42' : (x) => x instanceof HTMLTextAreaElement,
        'org.w3c.dom.Companion_$external_class_get_42' : () => HTMLTextAreaElement,
        'org.w3c.dom.HTMLTextAreaElement_$external_class_instanceof' : (x) => x instanceof HTMLTextAreaElement,
        'org.w3c.dom.HTMLTextAreaElement_$external_class_get' : () => HTMLTextAreaElement,
        'org.w3c.dom.media_$external_prop_getter_2' : (_this) => _this.media,
        'org.w3c.dom.media_$external_prop_setter' : (_this, v) => _this.media = v,
        'org.w3c.dom.matches_$external_prop_getter_2' : (_this) => _this.matches,
        'org.w3c.dom.matches_$external_prop_setter' : (_this, v) => _this.matches = v,
        'org.w3c.dom.ValidityState_$external_fun' : () => new ValidityState(),
        'org.w3c.dom.valueMissing_$external_prop_getter' : (_this) => _this.valueMissing,
        'org.w3c.dom.typeMismatch_$external_prop_getter' : (_this) => _this.typeMismatch,
        'org.w3c.dom.patternMismatch_$external_prop_getter' : (_this) => _this.patternMismatch,
        'org.w3c.dom.tooLong_$external_prop_getter' : (_this) => _this.tooLong,
        'org.w3c.dom.tooShort_$external_prop_getter' : (_this) => _this.tooShort,
        'org.w3c.dom.rangeUnderflow_$external_prop_getter' : (_this) => _this.rangeUnderflow,
        'org.w3c.dom.rangeOverflow_$external_prop_getter' : (_this) => _this.rangeOverflow,
        'org.w3c.dom.stepMismatch_$external_prop_getter' : (_this) => _this.stepMismatch,
        'org.w3c.dom.badInput_$external_prop_getter' : (_this) => _this.badInput,
        'org.w3c.dom.customError_$external_prop_getter' : (_this) => _this.customError,
        'org.w3c.dom.valid_$external_prop_getter' : (_this) => _this.valid,
        'org.w3c.dom.ValidityState_$external_class_instanceof' : (x) => x instanceof ValidityState,
        'org.w3c.dom.ValidityState_$external_class_get' : () => ValidityState,
        'org.w3c.dom.Companion_$external_fun_43' : () => new ({})(),
        'org.w3c.dom.Companion_$external_object_getInstance_43' : () => ({}),
        'org.w3c.dom.Companion_$external_class_instanceof_43' : (x) => x instanceof ({}),
        'org.w3c.dom.Companion_$external_class_get_43' : () => ({}),
        'org.w3c.dom.mediacapture.MediaDevices_$external_fun' : () => new MediaDevices(),
        'org.w3c.dom.mediacapture.ondevicechange_$external_prop_getter' : (_this) => _this.ondevicechange,
        'org.w3c.dom.mediacapture.ondevicechange_$external_prop_setter' : (_this, v) => _this.ondevicechange = v,
        'org.w3c.dom.mediacapture.enumerateDevices_$external_fun' : (_this, ) => _this.enumerateDevices(),
        'org.w3c.dom.mediacapture.getSupportedConstraints_$external_fun' : (_this, ) => _this.getSupportedConstraints(),
        'org.w3c.dom.mediacapture.getUserMedia_$external_fun' : (_this, p0, isDefault0) => _this.getUserMedia(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.mediacapture.MediaDevices_$external_class_instanceof' : (x) => x instanceof MediaDevices,
        'org.w3c.dom.mediacapture.MediaDevices_$external_class_get' : () => MediaDevices,
        'org.w3c.dom.mediacapture.video_$external_prop_getter' : (_this) => _this.video,
        'org.w3c.dom.mediacapture.video_$external_prop_setter' : (_this, v) => _this.video = v,
        'org.w3c.dom.mediacapture.audio_$external_prop_getter' : (_this) => _this.audio,
        'org.w3c.dom.mediacapture.audio_$external_prop_setter' : (_this, v) => _this.audio = v,
        'org.w3c.dom.mediacapture.MediaStream_$external_fun' : () => new MediaStream(),
        'org.w3c.dom.mediacapture.MediaStream_$external_fun_1' : (p0) => new MediaStream(p0),
        'org.w3c.dom.mediacapture.MediaStream_$external_fun_2' : (p0) => new MediaStream(p0),
        'org.w3c.dom.mediacapture.id_$external_prop_getter' : (_this) => _this.id,
        'org.w3c.dom.mediacapture.active_$external_prop_getter' : (_this) => _this.active,
        'org.w3c.dom.mediacapture.onaddtrack_$external_prop_getter' : (_this) => _this.onaddtrack,
        'org.w3c.dom.mediacapture.onaddtrack_$external_prop_setter' : (_this, v) => _this.onaddtrack = v,
        'org.w3c.dom.mediacapture.onremovetrack_$external_prop_getter' : (_this) => _this.onremovetrack,
        'org.w3c.dom.mediacapture.onremovetrack_$external_prop_setter' : (_this, v) => _this.onremovetrack = v,
        'org.w3c.dom.mediacapture.getAudioTracks_$external_fun' : (_this, ) => _this.getAudioTracks(),
        'org.w3c.dom.mediacapture.getVideoTracks_$external_fun' : (_this, ) => _this.getVideoTracks(),
        'org.w3c.dom.mediacapture.getTracks_$external_fun' : (_this, ) => _this.getTracks(),
        'org.w3c.dom.mediacapture.getTrackById_$external_fun' : (_this, p0) => _this.getTrackById(p0),
        'org.w3c.dom.mediacapture.addTrack_$external_fun' : (_this, p0) => _this.addTrack(p0),
        'org.w3c.dom.mediacapture.removeTrack_$external_fun' : (_this, p0) => _this.removeTrack(p0),
        'org.w3c.dom.mediacapture.clone_$external_fun' : (_this, ) => _this.clone(),
        'org.w3c.dom.mediacapture.MediaStream_$external_class_instanceof' : (x) => x instanceof MediaStream,
        'org.w3c.dom.mediacapture.MediaStream_$external_class_get' : () => MediaStream,
        'org.w3c.dom.mediacapture.MediaDeviceInfo_$external_fun' : () => new MediaDeviceInfo(),
        'org.w3c.dom.mediacapture.deviceId_$external_prop_getter' : (_this) => _this.deviceId,
        'org.w3c.dom.mediacapture.kind_$external_prop_getter' : (_this) => _this.kind,
        'org.w3c.dom.mediacapture.label_$external_prop_getter' : (_this) => _this.label,
        'org.w3c.dom.mediacapture.groupId_$external_prop_getter' : (_this) => _this.groupId,
        'org.w3c.dom.mediacapture.toJSON_$external_fun' : (_this, ) => _this.toJSON(),
        'org.w3c.dom.mediacapture.MediaDeviceInfo_$external_class_instanceof' : (x) => x instanceof MediaDeviceInfo,
        'org.w3c.dom.mediacapture.MediaDeviceInfo_$external_class_get' : () => MediaDeviceInfo,
        'org.w3c.dom.mediacapture.width_$external_prop_getter' : (_this) => _this.width,
        'org.w3c.dom.mediacapture.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.w3c.dom.mediacapture.height_$external_prop_getter' : (_this) => _this.height,
        'org.w3c.dom.mediacapture.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_getter' : (_this) => _this.aspectRatio,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_setter' : (_this, v) => _this.aspectRatio = v,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_getter' : (_this) => _this.frameRate,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_setter' : (_this, v) => _this.frameRate = v,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_getter' : (_this) => _this.facingMode,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_setter' : (_this, v) => _this.facingMode = v,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_getter' : (_this) => _this.resizeMode,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_setter' : (_this, v) => _this.resizeMode = v,
        'org.w3c.dom.mediacapture.volume_$external_prop_getter' : (_this) => _this.volume,
        'org.w3c.dom.mediacapture.volume_$external_prop_setter' : (_this, v) => _this.volume = v,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_getter' : (_this) => _this.sampleRate,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_setter' : (_this, v) => _this.sampleRate = v,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_getter' : (_this) => _this.sampleSize,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_setter' : (_this, v) => _this.sampleSize = v,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_getter' : (_this) => _this.echoCancellation,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_setter' : (_this, v) => _this.echoCancellation = v,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_getter' : (_this) => _this.autoGainControl,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_setter' : (_this, v) => _this.autoGainControl = v,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_getter' : (_this) => _this.noiseSuppression,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_setter' : (_this, v) => _this.noiseSuppression = v,
        'org.w3c.dom.mediacapture.latency_$external_prop_getter' : (_this) => _this.latency,
        'org.w3c.dom.mediacapture.latency_$external_prop_setter' : (_this, v) => _this.latency = v,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_getter' : (_this) => _this.channelCount,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_setter' : (_this, v) => _this.channelCount = v,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_getter_1' : (_this) => _this.deviceId,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_setter' : (_this, v) => _this.deviceId = v,
        'org.w3c.dom.mediacapture.groupId_$external_prop_getter_1' : (_this) => _this.groupId,
        'org.w3c.dom.mediacapture.groupId_$external_prop_setter' : (_this, v) => _this.groupId = v,
        'org.w3c.dom.mediacapture.MediaStreamTrack_$external_fun' : () => new MediaStreamTrack(),
        'org.w3c.dom.mediacapture.kind_$external_prop_getter_1' : (_this) => _this.kind,
        'org.w3c.dom.mediacapture.id_$external_prop_getter_1' : (_this) => _this.id,
        'org.w3c.dom.mediacapture.label_$external_prop_getter_1' : (_this) => _this.label,
        'org.w3c.dom.mediacapture.enabled_$external_prop_getter' : (_this) => _this.enabled,
        'org.w3c.dom.mediacapture.enabled_$external_prop_setter' : (_this, v) => _this.enabled = v,
        'org.w3c.dom.mediacapture.muted_$external_prop_getter' : (_this) => _this.muted,
        'org.w3c.dom.mediacapture.onmute_$external_prop_getter' : (_this) => _this.onmute,
        'org.w3c.dom.mediacapture.onmute_$external_prop_setter' : (_this, v) => _this.onmute = v,
        'org.w3c.dom.mediacapture.onunmute_$external_prop_getter' : (_this) => _this.onunmute,
        'org.w3c.dom.mediacapture.onunmute_$external_prop_setter' : (_this, v) => _this.onunmute = v,
        'org.w3c.dom.mediacapture.readyState_$external_prop_getter' : (_this) => _this.readyState,
        'org.w3c.dom.mediacapture.onended_$external_prop_getter' : (_this) => _this.onended,
        'org.w3c.dom.mediacapture.onended_$external_prop_setter' : (_this, v) => _this.onended = v,
        'org.w3c.dom.mediacapture.onoverconstrained_$external_prop_getter' : (_this) => _this.onoverconstrained,
        'org.w3c.dom.mediacapture.onoverconstrained_$external_prop_setter' : (_this, v) => _this.onoverconstrained = v,
        'org.w3c.dom.mediacapture.clone_$external_fun_1' : (_this, ) => _this.clone(),
        'org.w3c.dom.mediacapture.stop_$external_fun' : (_this, ) => _this.stop(),
        'org.w3c.dom.mediacapture.getCapabilities_$external_fun' : (_this, ) => _this.getCapabilities(),
        'org.w3c.dom.mediacapture.getConstraints_$external_fun' : (_this, ) => _this.getConstraints(),
        'org.w3c.dom.mediacapture.getSettings_$external_fun' : (_this, ) => _this.getSettings(),
        'org.w3c.dom.mediacapture.applyConstraints_$external_fun' : (_this, p0, isDefault0) => _this.applyConstraints(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.mediacapture.MediaStreamTrack_$external_class_instanceof' : (x) => x instanceof MediaStreamTrack,
        'org.w3c.dom.mediacapture.MediaStreamTrack_$external_class_get' : () => MediaStreamTrack,
        'org.w3c.dom.mediacapture.MediaStreamTrackEvent_$external_fun' : (p0, p1) => new MediaStreamTrackEvent(p0, p1),
        'org.w3c.dom.mediacapture.track_$external_prop_getter' : (_this) => _this.track,
        'org.w3c.dom.mediacapture.Companion_$external_fun' : () => new MediaStreamTrackEvent(),
        'org.w3c.dom.mediacapture.NONE_$external_prop_getter' : (_this) => _this.NONE,
        'org.w3c.dom.mediacapture.CAPTURING_PHASE_$external_prop_getter' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.mediacapture.AT_TARGET_$external_prop_getter' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.mediacapture.BUBBLING_PHASE_$external_prop_getter' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.mediacapture.Companion_$external_object_getInstance' : () => MediaStreamTrackEvent,
        'org.w3c.dom.mediacapture.Companion_$external_class_instanceof' : (x) => x instanceof MediaStreamTrackEvent,
        'org.w3c.dom.mediacapture.Companion_$external_class_get' : () => MediaStreamTrackEvent,
        'org.w3c.dom.mediacapture.MediaStreamTrackEvent_$external_class_instanceof' : (x) => x instanceof MediaStreamTrackEvent,
        'org.w3c.dom.mediacapture.MediaStreamTrackEvent_$external_class_get' : () => MediaStreamTrackEvent,
        'org.w3c.dom.mediacapture.Companion_$external_fun_1' : () => new ({})(),
        'org.w3c.dom.mediacapture.Companion_$external_object_getInstance_1' : () => ({}),
        'org.w3c.dom.mediacapture.Companion_$external_class_instanceof_1' : (x) => x instanceof ({}),
        'org.w3c.dom.mediacapture.Companion_$external_class_get_1' : () => ({}),
        'org.w3c.dom.mediacapture.Companion_$external_fun_2' : () => new ({})(),
        'org.w3c.dom.mediacapture.Companion_$external_object_getInstance_2' : () => ({}),
        'org.w3c.dom.mediacapture.Companion_$external_class_instanceof_2' : (x) => x instanceof ({}),
        'org.w3c.dom.mediacapture.Companion_$external_class_get_2' : () => ({}),
        'org.w3c.dom.mediacapture.width_$external_prop_getter_1' : (_this) => _this.width,
        'org.w3c.dom.mediacapture.width_$external_prop_setter_1' : (_this, v) => _this.width = v,
        'org.w3c.dom.mediacapture.height_$external_prop_getter_1' : (_this) => _this.height,
        'org.w3c.dom.mediacapture.height_$external_prop_setter_1' : (_this, v) => _this.height = v,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_getter_1' : (_this) => _this.aspectRatio,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_setter_1' : (_this, v) => _this.aspectRatio = v,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_getter_1' : (_this) => _this.frameRate,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_setter_1' : (_this, v) => _this.frameRate = v,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_getter_1' : (_this) => _this.facingMode,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_setter_1' : (_this, v) => _this.facingMode = v,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_getter_1' : (_this) => _this.resizeMode,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_setter_1' : (_this, v) => _this.resizeMode = v,
        'org.w3c.dom.mediacapture.volume_$external_prop_getter_1' : (_this) => _this.volume,
        'org.w3c.dom.mediacapture.volume_$external_prop_setter_1' : (_this, v) => _this.volume = v,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_getter_1' : (_this) => _this.sampleRate,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_setter_1' : (_this, v) => _this.sampleRate = v,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_getter_1' : (_this) => _this.sampleSize,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_setter_1' : (_this, v) => _this.sampleSize = v,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_getter_1' : (_this) => _this.echoCancellation,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_setter_1' : (_this, v) => _this.echoCancellation = v,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_getter_1' : (_this) => _this.autoGainControl,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_setter_1' : (_this, v) => _this.autoGainControl = v,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_getter_1' : (_this) => _this.noiseSuppression,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_setter_1' : (_this, v) => _this.noiseSuppression = v,
        'org.w3c.dom.mediacapture.latency_$external_prop_getter_1' : (_this) => _this.latency,
        'org.w3c.dom.mediacapture.latency_$external_prop_setter_1' : (_this, v) => _this.latency = v,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_getter_1' : (_this) => _this.channelCount,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_setter_1' : (_this, v) => _this.channelCount = v,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_getter_2' : (_this) => _this.deviceId,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_setter_1' : (_this, v) => _this.deviceId = v,
        'org.w3c.dom.mediacapture.groupId_$external_prop_getter_2' : (_this) => _this.groupId,
        'org.w3c.dom.mediacapture.groupId_$external_prop_setter_1' : (_this, v) => _this.groupId = v,
        'org.w3c.dom.mediacapture.advanced_$external_prop_getter' : (_this) => _this.advanced,
        'org.w3c.dom.mediacapture.advanced_$external_prop_setter' : (_this, v) => _this.advanced = v,
        'org.w3c.dom.mediacapture.width_$external_prop_getter_2' : (_this) => _this.width,
        'org.w3c.dom.mediacapture.width_$external_prop_setter_2' : (_this, v) => _this.width = v,
        'org.w3c.dom.mediacapture.height_$external_prop_getter_2' : (_this) => _this.height,
        'org.w3c.dom.mediacapture.height_$external_prop_setter_2' : (_this, v) => _this.height = v,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_getter_2' : (_this) => _this.aspectRatio,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_setter_2' : (_this, v) => _this.aspectRatio = v,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_getter_2' : (_this) => _this.frameRate,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_setter_2' : (_this, v) => _this.frameRate = v,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_getter_2' : (_this) => _this.facingMode,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_setter_2' : (_this, v) => _this.facingMode = v,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_getter_2' : (_this) => _this.resizeMode,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_setter_2' : (_this, v) => _this.resizeMode = v,
        'org.w3c.dom.mediacapture.volume_$external_prop_getter_2' : (_this) => _this.volume,
        'org.w3c.dom.mediacapture.volume_$external_prop_setter_2' : (_this, v) => _this.volume = v,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_getter_2' : (_this) => _this.sampleRate,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_setter_2' : (_this, v) => _this.sampleRate = v,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_getter_2' : (_this) => _this.sampleSize,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_setter_2' : (_this, v) => _this.sampleSize = v,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_getter_2' : (_this) => _this.echoCancellation,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_setter_2' : (_this, v) => _this.echoCancellation = v,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_getter_2' : (_this) => _this.autoGainControl,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_setter_2' : (_this, v) => _this.autoGainControl = v,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_getter_2' : (_this) => _this.noiseSuppression,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_setter_2' : (_this, v) => _this.noiseSuppression = v,
        'org.w3c.dom.mediacapture.latency_$external_prop_getter_2' : (_this) => _this.latency,
        'org.w3c.dom.mediacapture.latency_$external_prop_setter_2' : (_this, v) => _this.latency = v,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_getter_2' : (_this) => _this.channelCount,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_setter_2' : (_this, v) => _this.channelCount = v,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_getter_3' : (_this) => _this.deviceId,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_setter_2' : (_this, v) => _this.deviceId = v,
        'org.w3c.dom.mediacapture.groupId_$external_prop_getter_3' : (_this) => _this.groupId,
        'org.w3c.dom.mediacapture.groupId_$external_prop_setter_2' : (_this, v) => _this.groupId = v,
        'org.w3c.dom.mediacapture.track_$external_prop_getter_1' : (_this) => _this.track,
        'org.w3c.dom.mediacapture.track_$external_prop_setter' : (_this, v) => _this.track = v,
        'org.w3c.dom.mediacapture.max_$external_prop_getter' : (_this) => _this.max,
        'org.w3c.dom.mediacapture.max_$external_prop_setter' : (_this, v) => _this.max = v,
        'org.w3c.dom.mediacapture.min_$external_prop_getter' : (_this) => _this.min,
        'org.w3c.dom.mediacapture.min_$external_prop_setter' : (_this, v) => _this.min = v,
        'org.w3c.dom.mediacapture.max_$external_prop_getter_1' : (_this) => _this.max,
        'org.w3c.dom.mediacapture.max_$external_prop_setter_1' : (_this, v) => _this.max = v,
        'org.w3c.dom.mediacapture.min_$external_prop_getter_1' : (_this) => _this.min,
        'org.w3c.dom.mediacapture.min_$external_prop_setter_1' : (_this, v) => _this.min = v,
        'org.w3c.dom.mediacapture.width_$external_prop_getter_3' : (_this) => _this.width,
        'org.w3c.dom.mediacapture.width_$external_prop_setter_3' : (_this, v) => _this.width = v,
        'org.w3c.dom.mediacapture.height_$external_prop_getter_3' : (_this) => _this.height,
        'org.w3c.dom.mediacapture.height_$external_prop_setter_3' : (_this, v) => _this.height = v,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_getter_3' : (_this) => _this.aspectRatio,
        'org.w3c.dom.mediacapture.aspectRatio_$external_prop_setter_3' : (_this, v) => _this.aspectRatio = v,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_getter_3' : (_this) => _this.frameRate,
        'org.w3c.dom.mediacapture.frameRate_$external_prop_setter_3' : (_this, v) => _this.frameRate = v,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_getter_3' : (_this) => _this.facingMode,
        'org.w3c.dom.mediacapture.facingMode_$external_prop_setter_3' : (_this, v) => _this.facingMode = v,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_getter_3' : (_this) => _this.resizeMode,
        'org.w3c.dom.mediacapture.resizeMode_$external_prop_setter_3' : (_this, v) => _this.resizeMode = v,
        'org.w3c.dom.mediacapture.volume_$external_prop_getter_3' : (_this) => _this.volume,
        'org.w3c.dom.mediacapture.volume_$external_prop_setter_3' : (_this, v) => _this.volume = v,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_getter_3' : (_this) => _this.sampleRate,
        'org.w3c.dom.mediacapture.sampleRate_$external_prop_setter_3' : (_this, v) => _this.sampleRate = v,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_getter_3' : (_this) => _this.sampleSize,
        'org.w3c.dom.mediacapture.sampleSize_$external_prop_setter_3' : (_this, v) => _this.sampleSize = v,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_getter_3' : (_this) => _this.echoCancellation,
        'org.w3c.dom.mediacapture.echoCancellation_$external_prop_setter_3' : (_this, v) => _this.echoCancellation = v,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_getter_3' : (_this) => _this.autoGainControl,
        'org.w3c.dom.mediacapture.autoGainControl_$external_prop_setter_3' : (_this, v) => _this.autoGainControl = v,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_getter_3' : (_this) => _this.noiseSuppression,
        'org.w3c.dom.mediacapture.noiseSuppression_$external_prop_setter_3' : (_this, v) => _this.noiseSuppression = v,
        'org.w3c.dom.mediacapture.latency_$external_prop_getter_3' : (_this) => _this.latency,
        'org.w3c.dom.mediacapture.latency_$external_prop_setter_3' : (_this, v) => _this.latency = v,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_getter_3' : (_this) => _this.channelCount,
        'org.w3c.dom.mediacapture.channelCount_$external_prop_setter_3' : (_this, v) => _this.channelCount = v,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_getter_4' : (_this) => _this.deviceId,
        'org.w3c.dom.mediacapture.deviceId_$external_prop_setter_3' : (_this, v) => _this.deviceId = v,
        'org.w3c.dom.mediacapture.groupId_$external_prop_getter_4' : (_this) => _this.groupId,
        'org.w3c.dom.mediacapture.groupId_$external_prop_setter_3' : (_this, v) => _this.groupId = v,
        'org.w3c.dom.pointerevents.PointerEvent_$external_fun' : (p0, p1, isDefault0) => new PointerEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.dom.pointerevents.pointerId_$external_prop_getter' : (_this) => _this.pointerId,
        'org.w3c.dom.pointerevents.width_$external_prop_getter' : (_this) => _this.width,
        'org.w3c.dom.pointerevents.height_$external_prop_getter' : (_this) => _this.height,
        'org.w3c.dom.pointerevents.pressure_$external_prop_getter' : (_this) => _this.pressure,
        'org.w3c.dom.pointerevents.tangentialPressure_$external_prop_getter' : (_this) => _this.tangentialPressure,
        'org.w3c.dom.pointerevents.tiltX_$external_prop_getter' : (_this) => _this.tiltX,
        'org.w3c.dom.pointerevents.tiltY_$external_prop_getter' : (_this) => _this.tiltY,
        'org.w3c.dom.pointerevents.twist_$external_prop_getter' : (_this) => _this.twist,
        'org.w3c.dom.pointerevents.pointerType_$external_prop_getter' : (_this) => _this.pointerType,
        'org.w3c.dom.pointerevents.isPrimary_$external_prop_getter' : (_this) => _this.isPrimary,
        'org.w3c.dom.pointerevents.Companion_$external_fun' : () => new PointerEvent(),
        'org.w3c.dom.pointerevents.NONE_$external_prop_getter' : (_this) => _this.NONE,
        'org.w3c.dom.pointerevents.CAPTURING_PHASE_$external_prop_getter' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.dom.pointerevents.AT_TARGET_$external_prop_getter' : (_this) => _this.AT_TARGET,
        'org.w3c.dom.pointerevents.BUBBLING_PHASE_$external_prop_getter' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.dom.pointerevents.Companion_$external_object_getInstance' : () => PointerEvent,
        'org.w3c.dom.pointerevents.Companion_$external_class_instanceof' : (x) => x instanceof PointerEvent,
        'org.w3c.dom.pointerevents.Companion_$external_class_get' : () => PointerEvent,
        'org.w3c.dom.pointerevents.PointerEvent_$external_class_instanceof' : (x) => x instanceof PointerEvent,
        'org.w3c.dom.pointerevents.PointerEvent_$external_class_get' : () => PointerEvent,
        'org.w3c.dom.pointerevents.pointerId_$external_prop_getter_1' : (_this) => _this.pointerId,
        'org.w3c.dom.pointerevents.pointerId_$external_prop_setter' : (_this, v) => _this.pointerId = v,
        'org.w3c.dom.pointerevents.width_$external_prop_getter_1' : (_this) => _this.width,
        'org.w3c.dom.pointerevents.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.w3c.dom.pointerevents.height_$external_prop_getter_1' : (_this) => _this.height,
        'org.w3c.dom.pointerevents.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.w3c.dom.pointerevents.pressure_$external_prop_getter_1' : (_this) => _this.pressure,
        'org.w3c.dom.pointerevents.pressure_$external_prop_setter' : (_this, v) => _this.pressure = v,
        'org.w3c.dom.pointerevents.tangentialPressure_$external_prop_getter_1' : (_this) => _this.tangentialPressure,
        'org.w3c.dom.pointerevents.tangentialPressure_$external_prop_setter' : (_this, v) => _this.tangentialPressure = v,
        'org.w3c.dom.pointerevents.tiltX_$external_prop_getter_1' : (_this) => _this.tiltX,
        'org.w3c.dom.pointerevents.tiltX_$external_prop_setter' : (_this, v) => _this.tiltX = v,
        'org.w3c.dom.pointerevents.tiltY_$external_prop_getter_1' : (_this) => _this.tiltY,
        'org.w3c.dom.pointerevents.tiltY_$external_prop_setter' : (_this, v) => _this.tiltY = v,
        'org.w3c.dom.pointerevents.twist_$external_prop_getter_1' : (_this) => _this.twist,
        'org.w3c.dom.pointerevents.twist_$external_prop_setter' : (_this, v) => _this.twist = v,
        'org.w3c.dom.pointerevents.pointerType_$external_prop_getter_1' : (_this) => _this.pointerType,
        'org.w3c.dom.pointerevents.pointerType_$external_prop_setter' : (_this, v) => _this.pointerType = v,
        'org.w3c.dom.pointerevents.isPrimary_$external_prop_getter_1' : (_this) => _this.isPrimary,
        'org.w3c.dom.pointerevents.isPrimary_$external_prop_setter' : (_this, v) => _this.isPrimary = v,
        'org.w3c.dom.svg.SVGSVGElement_$external_fun' : () => new SVGSVGElement(),
        'org.w3c.dom.svg.x_$external_prop_getter' : (_this) => _this.x,
        'org.w3c.dom.svg.y_$external_prop_getter' : (_this) => _this.y,
        'org.w3c.dom.svg.width_$external_prop_getter' : (_this) => _this.width,
        'org.w3c.dom.svg.height_$external_prop_getter' : (_this) => _this.height,
        'org.w3c.dom.svg.currentScale_$external_prop_getter' : (_this) => _this.currentScale,
        'org.w3c.dom.svg.currentScale_$external_prop_setter' : (_this, v) => _this.currentScale = v,
        'org.w3c.dom.svg.currentTranslate_$external_prop_getter' : (_this) => _this.currentTranslate,
        'org.w3c.dom.svg.getIntersectionList_$external_fun' : (_this, p0, p1) => _this.getIntersectionList(p0, p1),
        'org.w3c.dom.svg.getEnclosureList_$external_fun' : (_this, p0, p1) => _this.getEnclosureList(p0, p1),
        'org.w3c.dom.svg.checkIntersection_$external_fun' : (_this, p0, p1) => _this.checkIntersection(p0, p1),
        'org.w3c.dom.svg.checkEnclosure_$external_fun' : (_this, p0, p1) => _this.checkEnclosure(p0, p1),
        'org.w3c.dom.svg.deselectAll_$external_fun' : (_this, ) => _this.deselectAll(),
        'org.w3c.dom.svg.createSVGNumber_$external_fun' : (_this, ) => _this.createSVGNumber(),
        'org.w3c.dom.svg.createSVGLength_$external_fun' : (_this, ) => _this.createSVGLength(),
        'org.w3c.dom.svg.createSVGAngle_$external_fun' : (_this, ) => _this.createSVGAngle(),
        'org.w3c.dom.svg.createSVGPoint_$external_fun' : (_this, ) => _this.createSVGPoint(),
        'org.w3c.dom.svg.createSVGMatrix_$external_fun' : (_this, ) => _this.createSVGMatrix(),
        'org.w3c.dom.svg.createSVGRect_$external_fun' : (_this, ) => _this.createSVGRect(),
        'org.w3c.dom.svg.createSVGTransform_$external_fun' : (_this, ) => _this.createSVGTransform(),
        'org.w3c.dom.svg.createSVGTransformFromMatrix_$external_fun' : (_this, p0) => _this.createSVGTransformFromMatrix(p0),
        'org.w3c.dom.svg.getElementById_$external_fun' : (_this, p0) => _this.getElementById(p0),
        'org.w3c.dom.svg.suspendRedraw_$external_fun' : (_this, p0) => _this.suspendRedraw(p0),
        'org.w3c.dom.svg.unsuspendRedraw_$external_fun' : (_this, p0) => _this.unsuspendRedraw(p0),
        'org.w3c.dom.svg.unsuspendRedrawAll_$external_fun' : (_this, ) => _this.unsuspendRedrawAll(),
        'org.w3c.dom.svg.forceRedraw_$external_fun' : (_this, ) => _this.forceRedraw(),
        'org.w3c.dom.svg.Companion_$external_fun' : () => new SVGSVGElement(),
        'org.w3c.dom.svg.SVG_ZOOMANDPAN_UNKNOWN_$external_prop_getter' : (_this) => _this.SVG_ZOOMANDPAN_UNKNOWN,
        'org.w3c.dom.svg.SVG_ZOOMANDPAN_DISABLE_$external_prop_getter' : (_this) => _this.SVG_ZOOMANDPAN_DISABLE,
        'org.w3c.dom.svg.SVG_ZOOMANDPAN_MAGNIFY_$external_prop_getter' : (_this) => _this.SVG_ZOOMANDPAN_MAGNIFY,
        'org.w3c.dom.svg.ELEMENT_NODE_$external_prop_getter' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.svg.ATTRIBUTE_NODE_$external_prop_getter' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.svg.TEXT_NODE_$external_prop_getter' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.svg.CDATA_SECTION_NODE_$external_prop_getter' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.svg.ENTITY_REFERENCE_NODE_$external_prop_getter' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.svg.ENTITY_NODE_$external_prop_getter' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.svg.PROCESSING_INSTRUCTION_NODE_$external_prop_getter' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.svg.COMMENT_NODE_$external_prop_getter' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_NODE_$external_prop_getter' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_TYPE_NODE_$external_prop_getter' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.svg.DOCUMENT_FRAGMENT_NODE_$external_prop_getter' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.svg.NOTATION_NODE_$external_prop_getter' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.svg.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.svg.DOCUMENT_POSITION_PRECEDING_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINS_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.svg.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.svg.Companion_$external_object_getInstance' : () => SVGSVGElement,
        'org.w3c.dom.svg.Companion_$external_class_instanceof' : (x) => x instanceof SVGSVGElement,
        'org.w3c.dom.svg.Companion_$external_class_get' : () => SVGSVGElement,
        'org.w3c.dom.svg.SVGSVGElement_$external_class_instanceof' : (x) => x instanceof SVGSVGElement,
        'org.w3c.dom.svg.SVGSVGElement_$external_class_get' : () => SVGSVGElement,
        'org.w3c.dom.svg.SVGGraphicsElement_$external_fun' : () => new SVGGraphicsElement(),
        'org.w3c.dom.svg.transform_$external_prop_getter' : (_this) => _this.transform,
        'org.w3c.dom.svg.getBBox_$external_fun' : (_this, p0, isDefault0) => _this.getBBox(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.svg.getCTM_$external_fun' : (_this, ) => _this.getCTM(),
        'org.w3c.dom.svg.getScreenCTM_$external_fun' : (_this, ) => _this.getScreenCTM(),
        'org.w3c.dom.svg.Companion_$external_fun_1' : () => new SVGGraphicsElement(),
        'org.w3c.dom.svg.ELEMENT_NODE_$external_prop_getter_1' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.svg.ATTRIBUTE_NODE_$external_prop_getter_1' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.svg.TEXT_NODE_$external_prop_getter_1' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.svg.CDATA_SECTION_NODE_$external_prop_getter_1' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.svg.ENTITY_REFERENCE_NODE_$external_prop_getter_1' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.svg.ENTITY_NODE_$external_prop_getter_1' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.svg.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_1' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.svg.COMMENT_NODE_$external_prop_getter_1' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_NODE_$external_prop_getter_1' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_TYPE_NODE_$external_prop_getter_1' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.svg.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_1' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.svg.NOTATION_NODE_$external_prop_getter_1' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.svg.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.svg.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.svg.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_1' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_1' : () => SVGGraphicsElement,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_1' : (x) => x instanceof SVGGraphicsElement,
        'org.w3c.dom.svg.Companion_$external_class_get_1' : () => SVGGraphicsElement,
        'org.w3c.dom.svg.SVGGraphicsElement_$external_class_instanceof' : (x) => x instanceof SVGGraphicsElement,
        'org.w3c.dom.svg.SVGGraphicsElement_$external_class_get' : () => SVGGraphicsElement,
        'org.w3c.dom.svg.viewBox_$external_prop_getter' : (_this) => _this.viewBox,
        'org.w3c.dom.svg.preserveAspectRatio_$external_prop_getter' : (_this) => _this.preserveAspectRatio,
        'org.w3c.dom.svg.zoomAndPan_$external_prop_getter' : (_this) => _this.zoomAndPan,
        'org.w3c.dom.svg.zoomAndPan_$external_prop_setter' : (_this, v) => _this.zoomAndPan = v,
        'org.w3c.dom.svg.Companion_$external_fun_2' : () => new ({})(),
        'org.w3c.dom.svg.SVG_ZOOMANDPAN_UNKNOWN_$external_prop_getter_1' : (_this) => _this.SVG_ZOOMANDPAN_UNKNOWN,
        'org.w3c.dom.svg.SVG_ZOOMANDPAN_DISABLE_$external_prop_getter_1' : (_this) => _this.SVG_ZOOMANDPAN_DISABLE,
        'org.w3c.dom.svg.SVG_ZOOMANDPAN_MAGNIFY_$external_prop_getter_1' : (_this) => _this.SVG_ZOOMANDPAN_MAGNIFY,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_2' : () => ({}),
        'org.w3c.dom.svg.Companion_$external_class_instanceof_2' : (x) => x instanceof ({}),
        'org.w3c.dom.svg.Companion_$external_class_get_2' : () => ({}),
        'org.w3c.dom.svg.SVGAnimatedLength_$external_fun' : () => new SVGAnimatedLength(),
        'org.w3c.dom.svg.baseVal_$external_prop_getter' : (_this) => _this.baseVal,
        'org.w3c.dom.svg.animVal_$external_prop_getter' : (_this) => _this.animVal,
        'org.w3c.dom.svg.SVGAnimatedLength_$external_class_instanceof' : (x) => x instanceof SVGAnimatedLength,
        'org.w3c.dom.svg.SVGAnimatedLength_$external_class_get' : () => SVGAnimatedLength,
        'org.w3c.dom.svg.SVGElement_$external_fun' : () => new SVGElement(),
        'org.w3c.dom.svg.dataset_$external_prop_getter' : (_this) => _this.dataset,
        'org.w3c.dom.svg.ownerSVGElement_$external_prop_getter' : (_this) => _this.ownerSVGElement,
        'org.w3c.dom.svg.viewportElement_$external_prop_getter' : (_this) => _this.viewportElement,
        'org.w3c.dom.svg.tabIndex_$external_prop_getter' : (_this) => _this.tabIndex,
        'org.w3c.dom.svg.tabIndex_$external_prop_setter' : (_this, v) => _this.tabIndex = v,
        'org.w3c.dom.svg.focus_$external_fun' : (_this, ) => _this.focus(),
        'org.w3c.dom.svg.blur_$external_fun' : (_this, ) => _this.blur(),
        'org.w3c.dom.svg.Companion_$external_fun_3' : () => new SVGElement(),
        'org.w3c.dom.svg.ELEMENT_NODE_$external_prop_getter_2' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.svg.ATTRIBUTE_NODE_$external_prop_getter_2' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.svg.TEXT_NODE_$external_prop_getter_2' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.svg.CDATA_SECTION_NODE_$external_prop_getter_2' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.svg.ENTITY_REFERENCE_NODE_$external_prop_getter_2' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.svg.ENTITY_NODE_$external_prop_getter_2' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.svg.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_2' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.svg.COMMENT_NODE_$external_prop_getter_2' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_NODE_$external_prop_getter_2' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_TYPE_NODE_$external_prop_getter_2' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.svg.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_2' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.svg.NOTATION_NODE_$external_prop_getter_2' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.svg.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.svg.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.svg.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_2' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_3' : () => SVGElement,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_3' : (x) => x instanceof SVGElement,
        'org.w3c.dom.svg.Companion_$external_class_get_3' : () => SVGElement,
        'org.w3c.dom.svg.SVGElement_$external_class_instanceof' : (x) => x instanceof SVGElement,
        'org.w3c.dom.svg.SVGElement_$external_class_get' : () => SVGElement,
        'org.w3c.dom.svg.SVGNumber_$external_fun' : () => new SVGNumber(),
        'org.w3c.dom.svg.value_$external_prop_getter' : (_this) => _this.value,
        'org.w3c.dom.svg.value_$external_prop_setter' : (_this, v) => _this.value = v,
        'org.w3c.dom.svg.SVGNumber_$external_class_instanceof' : (x) => x instanceof SVGNumber,
        'org.w3c.dom.svg.SVGNumber_$external_class_get' : () => SVGNumber,
        'org.w3c.dom.svg.SVGLength_$external_fun' : () => new SVGLength(),
        'org.w3c.dom.svg.unitType_$external_prop_getter' : (_this) => _this.unitType,
        'org.w3c.dom.svg.value_$external_prop_getter_1' : (_this) => _this.value,
        'org.w3c.dom.svg.value_$external_prop_setter_1' : (_this, v) => _this.value = v,
        'org.w3c.dom.svg.valueInSpecifiedUnits_$external_prop_getter' : (_this) => _this.valueInSpecifiedUnits,
        'org.w3c.dom.svg.valueInSpecifiedUnits_$external_prop_setter' : (_this, v) => _this.valueInSpecifiedUnits = v,
        'org.w3c.dom.svg.valueAsString_$external_prop_getter' : (_this) => _this.valueAsString,
        'org.w3c.dom.svg.valueAsString_$external_prop_setter' : (_this, v) => _this.valueAsString = v,
        'org.w3c.dom.svg.newValueSpecifiedUnits_$external_fun' : (_this, p0, p1) => _this.newValueSpecifiedUnits(p0, p1),
        'org.w3c.dom.svg.convertToSpecifiedUnits_$external_fun' : (_this, p0) => _this.convertToSpecifiedUnits(p0),
        'org.w3c.dom.svg.Companion_$external_fun_4' : () => new SVGLength(),
        'org.w3c.dom.svg.SVG_LENGTHTYPE_UNKNOWN_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_UNKNOWN,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_NUMBER_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_NUMBER,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_PERCENTAGE_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_PERCENTAGE,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_EMS_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_EMS,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_EXS_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_EXS,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_PX_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_PX,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_CM_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_CM,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_MM_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_MM,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_IN_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_IN,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_PT_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_PT,
        'org.w3c.dom.svg.SVG_LENGTHTYPE_PC_$external_prop_getter' : (_this) => _this.SVG_LENGTHTYPE_PC,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_4' : () => SVGLength,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_4' : (x) => x instanceof SVGLength,
        'org.w3c.dom.svg.Companion_$external_class_get_4' : () => SVGLength,
        'org.w3c.dom.svg.SVGLength_$external_class_instanceof' : (x) => x instanceof SVGLength,
        'org.w3c.dom.svg.SVGLength_$external_class_get' : () => SVGLength,
        'org.w3c.dom.svg.SVGAngle_$external_fun' : () => new SVGAngle(),
        'org.w3c.dom.svg.unitType_$external_prop_getter_1' : (_this) => _this.unitType,
        'org.w3c.dom.svg.value_$external_prop_getter_2' : (_this) => _this.value,
        'org.w3c.dom.svg.value_$external_prop_setter_2' : (_this, v) => _this.value = v,
        'org.w3c.dom.svg.valueInSpecifiedUnits_$external_prop_getter_1' : (_this) => _this.valueInSpecifiedUnits,
        'org.w3c.dom.svg.valueInSpecifiedUnits_$external_prop_setter_1' : (_this, v) => _this.valueInSpecifiedUnits = v,
        'org.w3c.dom.svg.valueAsString_$external_prop_getter_1' : (_this) => _this.valueAsString,
        'org.w3c.dom.svg.valueAsString_$external_prop_setter_1' : (_this, v) => _this.valueAsString = v,
        'org.w3c.dom.svg.newValueSpecifiedUnits_$external_fun_1' : (_this, p0, p1) => _this.newValueSpecifiedUnits(p0, p1),
        'org.w3c.dom.svg.convertToSpecifiedUnits_$external_fun_1' : (_this, p0) => _this.convertToSpecifiedUnits(p0),
        'org.w3c.dom.svg.Companion_$external_fun_5' : () => new SVGAngle(),
        'org.w3c.dom.svg.SVG_ANGLETYPE_UNKNOWN_$external_prop_getter' : (_this) => _this.SVG_ANGLETYPE_UNKNOWN,
        'org.w3c.dom.svg.SVG_ANGLETYPE_UNSPECIFIED_$external_prop_getter' : (_this) => _this.SVG_ANGLETYPE_UNSPECIFIED,
        'org.w3c.dom.svg.SVG_ANGLETYPE_DEG_$external_prop_getter' : (_this) => _this.SVG_ANGLETYPE_DEG,
        'org.w3c.dom.svg.SVG_ANGLETYPE_RAD_$external_prop_getter' : (_this) => _this.SVG_ANGLETYPE_RAD,
        'org.w3c.dom.svg.SVG_ANGLETYPE_GRAD_$external_prop_getter' : (_this) => _this.SVG_ANGLETYPE_GRAD,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_5' : () => SVGAngle,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_5' : (x) => x instanceof SVGAngle,
        'org.w3c.dom.svg.Companion_$external_class_get_5' : () => SVGAngle,
        'org.w3c.dom.svg.SVGAngle_$external_class_instanceof' : (x) => x instanceof SVGAngle,
        'org.w3c.dom.svg.SVGAngle_$external_class_get' : () => SVGAngle,
        'org.w3c.dom.svg.SVGTransform_$external_fun' : () => new SVGTransform(),
        'org.w3c.dom.svg.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.dom.svg.matrix_$external_prop_getter' : (_this) => _this.matrix,
        'org.w3c.dom.svg.angle_$external_prop_getter' : (_this) => _this.angle,
        'org.w3c.dom.svg.setMatrix_$external_fun' : (_this, p0) => _this.setMatrix(p0),
        'org.w3c.dom.svg.setTranslate_$external_fun' : (_this, p0, p1) => _this.setTranslate(p0, p1),
        'org.w3c.dom.svg.setScale_$external_fun' : (_this, p0, p1) => _this.setScale(p0, p1),
        'org.w3c.dom.svg.setRotate_$external_fun' : (_this, p0, p1, p2) => _this.setRotate(p0, p1, p2),
        'org.w3c.dom.svg.setSkewX_$external_fun' : (_this, p0) => _this.setSkewX(p0),
        'org.w3c.dom.svg.setSkewY_$external_fun' : (_this, p0) => _this.setSkewY(p0),
        'org.w3c.dom.svg.Companion_$external_fun_6' : () => new SVGTransform(),
        'org.w3c.dom.svg.SVG_TRANSFORM_UNKNOWN_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_UNKNOWN,
        'org.w3c.dom.svg.SVG_TRANSFORM_MATRIX_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_MATRIX,
        'org.w3c.dom.svg.SVG_TRANSFORM_TRANSLATE_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_TRANSLATE,
        'org.w3c.dom.svg.SVG_TRANSFORM_SCALE_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_SCALE,
        'org.w3c.dom.svg.SVG_TRANSFORM_ROTATE_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_ROTATE,
        'org.w3c.dom.svg.SVG_TRANSFORM_SKEWX_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_SKEWX,
        'org.w3c.dom.svg.SVG_TRANSFORM_SKEWY_$external_prop_getter' : (_this) => _this.SVG_TRANSFORM_SKEWY,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_6' : () => SVGTransform,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_6' : (x) => x instanceof SVGTransform,
        'org.w3c.dom.svg.Companion_$external_class_get_6' : () => SVGTransform,
        'org.w3c.dom.svg.SVGTransform_$external_class_instanceof' : (x) => x instanceof SVGTransform,
        'org.w3c.dom.svg.SVGTransform_$external_class_get' : () => SVGTransform,
        'org.w3c.dom.svg.requiredExtensions_$external_prop_getter' : (_this) => _this.requiredExtensions,
        'org.w3c.dom.svg.systemLanguage_$external_prop_getter' : (_this) => _this.systemLanguage,
        'org.w3c.dom.svg.SVGAnimatedTransformList_$external_fun' : () => new SVGAnimatedTransformList(),
        'org.w3c.dom.svg.baseVal_$external_prop_getter_1' : (_this) => _this.baseVal,
        'org.w3c.dom.svg.animVal_$external_prop_getter_1' : (_this) => _this.animVal,
        'org.w3c.dom.svg.SVGAnimatedTransformList_$external_class_instanceof' : (x) => x instanceof SVGAnimatedTransformList,
        'org.w3c.dom.svg.SVGAnimatedTransformList_$external_class_get' : () => SVGAnimatedTransformList,
        'org.w3c.dom.svg.fill_$external_prop_getter' : (_this) => _this.fill,
        'org.w3c.dom.svg.fill_$external_prop_setter' : (_this, v) => _this.fill = v,
        'org.w3c.dom.svg.stroke_$external_prop_getter' : (_this) => _this.stroke,
        'org.w3c.dom.svg.stroke_$external_prop_setter' : (_this, v) => _this.stroke = v,
        'org.w3c.dom.svg.markers_$external_prop_getter' : (_this) => _this.markers,
        'org.w3c.dom.svg.markers_$external_prop_setter' : (_this, v) => _this.markers = v,
        'org.w3c.dom.svg.clipped_$external_prop_getter' : (_this) => _this.clipped,
        'org.w3c.dom.svg.clipped_$external_prop_setter' : (_this, v) => _this.clipped = v,
        'org.w3c.dom.svg.SVGAnimatedRect_$external_fun' : () => new SVGAnimatedRect(),
        'org.w3c.dom.svg.baseVal_$external_prop_getter_2' : (_this) => _this.baseVal,
        'org.w3c.dom.svg.animVal_$external_prop_getter_2' : (_this) => _this.animVal,
        'org.w3c.dom.svg.SVGAnimatedRect_$external_class_instanceof' : (x) => x instanceof SVGAnimatedRect,
        'org.w3c.dom.svg.SVGAnimatedRect_$external_class_get' : () => SVGAnimatedRect,
        'org.w3c.dom.svg.SVGAnimatedPreserveAspectRatio_$external_fun' : () => new SVGAnimatedPreserveAspectRatio(),
        'org.w3c.dom.svg.baseVal_$external_prop_getter_3' : (_this) => _this.baseVal,
        'org.w3c.dom.svg.animVal_$external_prop_getter_3' : (_this) => _this.animVal,
        'org.w3c.dom.svg.SVGAnimatedPreserveAspectRatio_$external_class_instanceof' : (x) => x instanceof SVGAnimatedPreserveAspectRatio,
        'org.w3c.dom.svg.SVGAnimatedPreserveAspectRatio_$external_class_get' : () => SVGAnimatedPreserveAspectRatio,
        'org.w3c.dom.svg.correspondingElement_$external_prop_getter' : (_this) => _this.correspondingElement,
        'org.w3c.dom.svg.correspondingUseElement_$external_prop_getter' : (_this) => _this.correspondingUseElement,
        'org.w3c.dom.svg.SVGStringList_$external_fun' : () => new SVGStringList(),
        'org.w3c.dom.svg.length_$external_prop_getter' : (_this) => _this.length,
        'org.w3c.dom.svg.numberOfItems_$external_prop_getter' : (_this) => _this.numberOfItems,
        'org.w3c.dom.svg.clear_$external_fun' : (_this, ) => _this.clear(),
        'org.w3c.dom.svg.initialize_$external_fun' : (_this, p0) => _this.initialize(p0),
        'org.w3c.dom.svg.insertItemBefore_$external_fun' : (_this, p0, p1) => _this.insertItemBefore(p0, p1),
        'org.w3c.dom.svg.replaceItem_$external_fun' : (_this, p0, p1) => _this.replaceItem(p0, p1),
        'org.w3c.dom.svg.removeItem_$external_fun' : (_this, p0) => _this.removeItem(p0),
        'org.w3c.dom.svg.appendItem_$external_fun' : (_this, p0) => _this.appendItem(p0),
        'org.w3c.dom.svg.getItem_$external_fun' : (_this, p0) => _this.getItem(p0),
        'org.w3c.dom.svg.SVGStringList_$external_class_instanceof' : (x) => x instanceof SVGStringList,
        'org.w3c.dom.svg.SVGStringList_$external_class_get' : () => SVGStringList,
        'org.w3c.dom.svg.SVGTransformList_$external_fun' : () => new SVGTransformList(),
        'org.w3c.dom.svg.length_$external_prop_getter_1' : (_this) => _this.length,
        'org.w3c.dom.svg.numberOfItems_$external_prop_getter_1' : (_this) => _this.numberOfItems,
        'org.w3c.dom.svg.clear_$external_fun_1' : (_this, ) => _this.clear(),
        'org.w3c.dom.svg.initialize_$external_fun_1' : (_this, p0) => _this.initialize(p0),
        'org.w3c.dom.svg.insertItemBefore_$external_fun_1' : (_this, p0, p1) => _this.insertItemBefore(p0, p1),
        'org.w3c.dom.svg.replaceItem_$external_fun_1' : (_this, p0, p1) => _this.replaceItem(p0, p1),
        'org.w3c.dom.svg.removeItem_$external_fun_1' : (_this, p0) => _this.removeItem(p0),
        'org.w3c.dom.svg.appendItem_$external_fun_1' : (_this, p0) => _this.appendItem(p0),
        'org.w3c.dom.svg.createSVGTransformFromMatrix_$external_fun_1' : (_this, p0) => _this.createSVGTransformFromMatrix(p0),
        'org.w3c.dom.svg.consolidate_$external_fun' : (_this, ) => _this.consolidate(),
        'org.w3c.dom.svg.getItem_$external_fun_1' : (_this, p0) => _this.getItem(p0),
        'org.w3c.dom.svg.SVGTransformList_$external_class_instanceof' : (x) => x instanceof SVGTransformList,
        'org.w3c.dom.svg.SVGTransformList_$external_class_get' : () => SVGTransformList,
        'org.w3c.dom.svg.SVGPreserveAspectRatio_$external_fun' : () => new SVGPreserveAspectRatio(),
        'org.w3c.dom.svg.align_$external_prop_getter' : (_this) => _this.align,
        'org.w3c.dom.svg.align_$external_prop_setter' : (_this, v) => _this.align = v,
        'org.w3c.dom.svg.meetOrSlice_$external_prop_getter' : (_this) => _this.meetOrSlice,
        'org.w3c.dom.svg.meetOrSlice_$external_prop_setter' : (_this, v) => _this.meetOrSlice = v,
        'org.w3c.dom.svg.Companion_$external_fun_7' : () => new SVGPreserveAspectRatio(),
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_UNKNOWN_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_UNKNOWN,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_NONE_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_NONE,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMINYMIN_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMINYMIN,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMIDYMIN_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMIDYMIN,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMAXYMIN_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMAXYMIN,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMINYMID_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMINYMID,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMIDYMID_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMIDYMID,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMAXYMID_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMAXYMID,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMINYMAX_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMINYMAX,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMIDYMAX_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMIDYMAX,
        'org.w3c.dom.svg.SVG_PRESERVEASPECTRATIO_XMAXYMAX_$external_prop_getter' : (_this) => _this.SVG_PRESERVEASPECTRATIO_XMAXYMAX,
        'org.w3c.dom.svg.SVG_MEETORSLICE_UNKNOWN_$external_prop_getter' : (_this) => _this.SVG_MEETORSLICE_UNKNOWN,
        'org.w3c.dom.svg.SVG_MEETORSLICE_MEET_$external_prop_getter' : (_this) => _this.SVG_MEETORSLICE_MEET,
        'org.w3c.dom.svg.SVG_MEETORSLICE_SLICE_$external_prop_getter' : (_this) => _this.SVG_MEETORSLICE_SLICE,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_7' : () => SVGPreserveAspectRatio,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_7' : (x) => x instanceof SVGPreserveAspectRatio,
        'org.w3c.dom.svg.Companion_$external_class_get_7' : () => SVGPreserveAspectRatio,
        'org.w3c.dom.svg.SVGPreserveAspectRatio_$external_class_instanceof' : (x) => x instanceof SVGPreserveAspectRatio,
        'org.w3c.dom.svg.SVGPreserveAspectRatio_$external_class_get' : () => SVGPreserveAspectRatio,
        'org.w3c.dom.svg.SVGUseElement_$external_fun' : () => new SVGUseElement(),
        'org.w3c.dom.svg.x_$external_prop_getter_1' : (_this) => _this.x,
        'org.w3c.dom.svg.y_$external_prop_getter_1' : (_this) => _this.y,
        'org.w3c.dom.svg.width_$external_prop_getter_1' : (_this) => _this.width,
        'org.w3c.dom.svg.height_$external_prop_getter_1' : (_this) => _this.height,
        'org.w3c.dom.svg.instanceRoot_$external_prop_getter' : (_this) => _this.instanceRoot,
        'org.w3c.dom.svg.animatedInstanceRoot_$external_prop_getter' : (_this) => _this.animatedInstanceRoot,
        'org.w3c.dom.svg.Companion_$external_fun_8' : () => new SVGUseElement(),
        'org.w3c.dom.svg.ELEMENT_NODE_$external_prop_getter_3' : (_this) => _this.ELEMENT_NODE,
        'org.w3c.dom.svg.ATTRIBUTE_NODE_$external_prop_getter_3' : (_this) => _this.ATTRIBUTE_NODE,
        'org.w3c.dom.svg.TEXT_NODE_$external_prop_getter_3' : (_this) => _this.TEXT_NODE,
        'org.w3c.dom.svg.CDATA_SECTION_NODE_$external_prop_getter_3' : (_this) => _this.CDATA_SECTION_NODE,
        'org.w3c.dom.svg.ENTITY_REFERENCE_NODE_$external_prop_getter_3' : (_this) => _this.ENTITY_REFERENCE_NODE,
        'org.w3c.dom.svg.ENTITY_NODE_$external_prop_getter_3' : (_this) => _this.ENTITY_NODE,
        'org.w3c.dom.svg.PROCESSING_INSTRUCTION_NODE_$external_prop_getter_3' : (_this) => _this.PROCESSING_INSTRUCTION_NODE,
        'org.w3c.dom.svg.COMMENT_NODE_$external_prop_getter_3' : (_this) => _this.COMMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_NODE_$external_prop_getter_3' : (_this) => _this.DOCUMENT_NODE,
        'org.w3c.dom.svg.DOCUMENT_TYPE_NODE_$external_prop_getter_3' : (_this) => _this.DOCUMENT_TYPE_NODE,
        'org.w3c.dom.svg.DOCUMENT_FRAGMENT_NODE_$external_prop_getter_3' : (_this) => _this.DOCUMENT_FRAGMENT_NODE,
        'org.w3c.dom.svg.NOTATION_NODE_$external_prop_getter_3' : (_this) => _this.NOTATION_NODE,
        'org.w3c.dom.svg.DOCUMENT_POSITION_DISCONNECTED_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_DISCONNECTED,
        'org.w3c.dom.svg.DOCUMENT_POSITION_PRECEDING_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_PRECEDING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_FOLLOWING_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_FOLLOWING,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINS_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_CONTAINS,
        'org.w3c.dom.svg.DOCUMENT_POSITION_CONTAINED_BY_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_CONTAINED_BY,
        'org.w3c.dom.svg.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC_$external_prop_getter_3' : (_this) => _this.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC,
        'org.w3c.dom.svg.Companion_$external_object_getInstance_8' : () => SVGUseElement,
        'org.w3c.dom.svg.Companion_$external_class_instanceof_8' : (x) => x instanceof SVGUseElement,
        'org.w3c.dom.svg.Companion_$external_class_get_8' : () => SVGUseElement,
        'org.w3c.dom.svg.SVGUseElement_$external_class_instanceof' : (x) => x instanceof SVGUseElement,
        'org.w3c.dom.svg.SVGUseElement_$external_class_get' : () => SVGUseElement,
        'org.w3c.dom.svg.href_$external_prop_getter' : (_this) => _this.href,
        'org.w3c.dom.svg.SVGAnimatedString_$external_fun' : () => new SVGAnimatedString(),
        'org.w3c.dom.svg.baseVal_$external_prop_getter_4' : (_this) => _this.baseVal,
        'org.w3c.dom.svg.baseVal_$external_prop_setter' : (_this, v) => _this.baseVal = v,
        'org.w3c.dom.svg.animVal_$external_prop_getter_4' : (_this) => _this.animVal,
        'org.w3c.dom.svg.SVGAnimatedString_$external_class_instanceof' : (x) => x instanceof SVGAnimatedString,
        'org.w3c.dom.svg.SVGAnimatedString_$external_class_get' : () => SVGAnimatedString,
        'org.w3c.fetch.Response_$external_fun' : (p0, p1, isDefault0, isDefault1) => new Response(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.fetch.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.fetch.url_$external_prop_getter' : (_this) => _this.url,
        'org.w3c.fetch.redirected_$external_prop_getter' : (_this) => _this.redirected,
        'org.w3c.fetch.status_$external_prop_getter' : (_this) => _this.status,
        'org.w3c.fetch.ok_$external_prop_getter' : (_this) => _this.ok,
        'org.w3c.fetch.statusText_$external_prop_getter' : (_this) => _this.statusText,
        'org.w3c.fetch.headers_$external_prop_getter' : (_this) => _this.headers,
        'org.w3c.fetch.body_$external_prop_getter' : (_this) => _this.body,
        'org.w3c.fetch.trailer_$external_prop_getter' : (_this) => _this.trailer,
        'org.w3c.fetch.bodyUsed_$external_prop_getter' : (_this) => _this.bodyUsed,
        'org.w3c.fetch.clone_$external_fun' : (_this, ) => _this.clone(),
        'org.w3c.fetch.arrayBuffer_$external_fun' : (_this, ) => _this.arrayBuffer(),
        'org.w3c.fetch.blob_$external_fun' : (_this, ) => _this.blob(),
        'org.w3c.fetch.formData_$external_fun' : (_this, ) => _this.formData(),
        'org.w3c.fetch.json_$external_fun' : (_this, ) => _this.json(),
        'org.w3c.fetch.text_$external_fun' : (_this, ) => _this.text(),
        'org.w3c.fetch.Companion_$external_fun' : () => new Response(),
        'org.w3c.fetch.error_$external_fun' : (_this, ) => _this.error(),
        'org.w3c.fetch.redirect_$external_fun' : (_this, p0, p1, isDefault0) => _this.redirect(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.fetch.Companion_$external_object_getInstance' : () => Response,
        'org.w3c.fetch.Companion_$external_class_instanceof' : (x) => x instanceof Response,
        'org.w3c.fetch.Companion_$external_class_get' : () => Response,
        'org.w3c.fetch.Response_$external_class_instanceof' : (x) => x instanceof Response,
        'org.w3c.fetch.Response_$external_class_get' : () => Response,
        'org.w3c.fetch.Request_$external_fun' : (p0, p1, isDefault0) => new Request(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.fetch.method_$external_prop_getter' : (_this) => _this.method,
        'org.w3c.fetch.url_$external_prop_getter_1' : (_this) => _this.url,
        'org.w3c.fetch.headers_$external_prop_getter_1' : (_this) => _this.headers,
        'org.w3c.fetch.type_$external_prop_getter_1' : (_this) => _this.type,
        'org.w3c.fetch.destination_$external_prop_getter' : (_this) => _this.destination,
        'org.w3c.fetch.referrer_$external_prop_getter' : (_this) => _this.referrer,
        'org.w3c.fetch.referrerPolicy_$external_prop_getter' : (_this) => _this.referrerPolicy,
        'org.w3c.fetch.mode_$external_prop_getter' : (_this) => _this.mode,
        'org.w3c.fetch.credentials_$external_prop_getter' : (_this) => _this.credentials,
        'org.w3c.fetch.cache_$external_prop_getter' : (_this) => _this.cache,
        'org.w3c.fetch.redirect_$external_prop_getter' : (_this) => _this.redirect,
        'org.w3c.fetch.integrity_$external_prop_getter' : (_this) => _this.integrity,
        'org.w3c.fetch.keepalive_$external_prop_getter' : (_this) => _this.keepalive,
        'org.w3c.fetch.bodyUsed_$external_prop_getter_1' : (_this) => _this.bodyUsed,
        'org.w3c.fetch.clone_$external_fun_1' : (_this, ) => _this.clone(),
        'org.w3c.fetch.arrayBuffer_$external_fun_1' : (_this, ) => _this.arrayBuffer(),
        'org.w3c.fetch.blob_$external_fun_1' : (_this, ) => _this.blob(),
        'org.w3c.fetch.formData_$external_fun_1' : (_this, ) => _this.formData(),
        'org.w3c.fetch.json_$external_fun_1' : (_this, ) => _this.json(),
        'org.w3c.fetch.text_$external_fun_1' : (_this, ) => _this.text(),
        'org.w3c.fetch.Request_$external_class_instanceof' : (x) => x instanceof Request,
        'org.w3c.fetch.Request_$external_class_get' : () => Request,
        'org.w3c.fetch.method_$external_prop_getter_1' : (_this) => _this.method,
        'org.w3c.fetch.method_$external_prop_setter' : (_this, v) => _this.method = v,
        'org.w3c.fetch.headers_$external_prop_getter_2' : (_this) => _this.headers,
        'org.w3c.fetch.headers_$external_prop_setter' : (_this, v) => _this.headers = v,
        'org.w3c.fetch.body_$external_prop_getter_1' : (_this) => _this.body,
        'org.w3c.fetch.body_$external_prop_setter' : (_this, v) => _this.body = v,
        'org.w3c.fetch.referrer_$external_prop_getter_1' : (_this) => _this.referrer,
        'org.w3c.fetch.referrer_$external_prop_setter' : (_this, v) => _this.referrer = v,
        'org.w3c.fetch.referrerPolicy_$external_prop_getter_1' : (_this) => _this.referrerPolicy,
        'org.w3c.fetch.referrerPolicy_$external_prop_setter' : (_this, v) => _this.referrerPolicy = v,
        'org.w3c.fetch.mode_$external_prop_getter_1' : (_this) => _this.mode,
        'org.w3c.fetch.mode_$external_prop_setter' : (_this, v) => _this.mode = v,
        'org.w3c.fetch.credentials_$external_prop_getter_1' : (_this) => _this.credentials,
        'org.w3c.fetch.credentials_$external_prop_setter' : (_this, v) => _this.credentials = v,
        'org.w3c.fetch.cache_$external_prop_getter_1' : (_this) => _this.cache,
        'org.w3c.fetch.cache_$external_prop_setter' : (_this, v) => _this.cache = v,
        'org.w3c.fetch.redirect_$external_prop_getter_1' : (_this) => _this.redirect,
        'org.w3c.fetch.redirect_$external_prop_setter' : (_this, v) => _this.redirect = v,
        'org.w3c.fetch.integrity_$external_prop_getter_1' : (_this) => _this.integrity,
        'org.w3c.fetch.integrity_$external_prop_setter' : (_this, v) => _this.integrity = v,
        'org.w3c.fetch.keepalive_$external_prop_getter_1' : (_this) => _this.keepalive,
        'org.w3c.fetch.keepalive_$external_prop_setter' : (_this, v) => _this.keepalive = v,
        'org.w3c.fetch.window_$external_prop_getter' : (_this) => _this.window,
        'org.w3c.fetch.window_$external_prop_setter' : (_this, v) => _this.window = v,
        'org.w3c.fetch.bodyUsed_$external_prop_getter_2' : (_this) => _this.bodyUsed,
        'org.w3c.fetch.arrayBuffer_$external_fun_2' : (_this, ) => _this.arrayBuffer(),
        'org.w3c.fetch.blob_$external_fun_2' : (_this, ) => _this.blob(),
        'org.w3c.fetch.formData_$external_fun_2' : (_this, ) => _this.formData(),
        'org.w3c.fetch.json_$external_fun_2' : (_this, ) => _this.json(),
        'org.w3c.fetch.text_$external_fun_2' : (_this, ) => _this.text(),
        'org.w3c.fetch.status_$external_prop_getter_1' : (_this) => _this.status,
        'org.w3c.fetch.status_$external_prop_setter' : (_this, v) => _this.status = v,
        'org.w3c.fetch.statusText_$external_prop_getter_1' : (_this) => _this.statusText,
        'org.w3c.fetch.statusText_$external_prop_setter' : (_this, v) => _this.statusText = v,
        'org.w3c.fetch.headers_$external_prop_getter_3' : (_this) => _this.headers,
        'org.w3c.fetch.headers_$external_prop_setter_1' : (_this, v) => _this.headers = v,
        'org.w3c.fetch.Companion_$external_fun_1' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_1' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_1' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_1' : () => ({}),
        'org.w3c.fetch.Headers_$external_fun' : (p0, isDefault0) => new Headers(isDefault0 ? undefined : p0, ),
        'org.w3c.fetch.append_$external_fun' : (_this, p0, p1) => _this.append(p0, p1),
        'org.w3c.fetch.delete_$external_fun' : (_this, p0) => _this.delete(p0),
        'org.w3c.fetch.get_$external_fun' : (_this, p0) => _this.get(p0),
        'org.w3c.fetch.has_$external_fun' : (_this, p0) => _this.has(p0),
        'org.w3c.fetch.set_$external_fun' : (_this, p0, p1) => _this.set(p0, p1),
        'org.w3c.fetch.Headers_$external_class_instanceof' : (x) => x instanceof Headers,
        'org.w3c.fetch.Headers_$external_class_get' : () => Headers,
        'org.w3c.fetch.Companion_$external_fun_2' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_2' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_2' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_2' : () => ({}),
        'org.w3c.fetch.Companion_$external_fun_3' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_3' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_3' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_3' : () => ({}),
        'org.w3c.fetch.Companion_$external_fun_4' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_4' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_4' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_4' : () => ({}),
        'org.w3c.fetch.Companion_$external_fun_5' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_5' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_5' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_5' : () => ({}),
        'org.w3c.fetch.Companion_$external_fun_6' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_6' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_6' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_6' : () => ({}),
        'org.w3c.fetch.Companion_$external_fun_7' : () => new ({})(),
        'org.w3c.fetch.Companion_$external_object_getInstance_7' : () => ({}),
        'org.w3c.fetch.Companion_$external_class_instanceof_7' : (x) => x instanceof ({}),
        'org.w3c.fetch.Companion_$external_class_get_7' : () => ({}),
        'org.w3c.files.FileList_$external_fun' : () => new FileList(),
        'org.w3c.files.item_$external_fun' : (_this, p0) => _this.item(p0),
        'org.w3c.files.FileList_$external_class_instanceof' : (x) => x instanceof FileList,
        'org.w3c.files.FileList_$external_class_get' : () => FileList,
        'org.w3c.files.File_$external_fun' : (p0, p1, p2, isDefault0) => new File(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.files.name_$external_prop_getter' : (_this) => _this.name,
        'org.w3c.files.lastModified_$external_prop_getter' : (_this) => _this.lastModified,
        'org.w3c.files.File_$external_class_instanceof' : (x) => x instanceof File,
        'org.w3c.files.File_$external_class_get' : () => File,
        'org.w3c.files.Blob_$external_fun' : (p0, p1, isDefault0, isDefault1) => new Blob(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.files.size_$external_prop_getter' : (_this) => _this.size,
        'org.w3c.files.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.files.isClosed_$external_prop_getter' : (_this) => _this.isClosed,
        'org.w3c.files.slice_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1, isDefault2) => _this.slice(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, isDefault2 ? undefined : p2, ),
        'org.w3c.files.close_$external_fun' : (_this, ) => _this.close(),
        'org.w3c.files.Blob_$external_class_instanceof' : (x) => x instanceof Blob,
        'org.w3c.files.Blob_$external_class_get' : () => Blob,
        'org.w3c.files.lastModified_$external_prop_getter_1' : (_this) => _this.lastModified,
        'org.w3c.files.lastModified_$external_prop_setter' : (_this, v) => _this.lastModified = v,
        'org.w3c.files.type_$external_prop_getter_1' : (_this) => _this.type,
        'org.w3c.files.type_$external_prop_setter' : (_this, v) => _this.type = v,
        'org.w3c.notifications.dir_$external_prop_getter' : (_this) => _this.dir,
        'org.w3c.notifications.dir_$external_prop_setter' : (_this, v) => _this.dir = v,
        'org.w3c.notifications.lang_$external_prop_getter' : (_this) => _this.lang,
        'org.w3c.notifications.lang_$external_prop_setter' : (_this, v) => _this.lang = v,
        'org.w3c.notifications.body_$external_prop_getter' : (_this) => _this.body,
        'org.w3c.notifications.body_$external_prop_setter' : (_this, v) => _this.body = v,
        'org.w3c.notifications.tag_$external_prop_getter' : (_this) => _this.tag,
        'org.w3c.notifications.tag_$external_prop_setter' : (_this, v) => _this.tag = v,
        'org.w3c.notifications.image_$external_prop_getter' : (_this) => _this.image,
        'org.w3c.notifications.image_$external_prop_setter' : (_this, v) => _this.image = v,
        'org.w3c.notifications.icon_$external_prop_getter' : (_this) => _this.icon,
        'org.w3c.notifications.icon_$external_prop_setter' : (_this, v) => _this.icon = v,
        'org.w3c.notifications.badge_$external_prop_getter' : (_this) => _this.badge,
        'org.w3c.notifications.badge_$external_prop_setter' : (_this, v) => _this.badge = v,
        'org.w3c.notifications.sound_$external_prop_getter' : (_this) => _this.sound,
        'org.w3c.notifications.sound_$external_prop_setter' : (_this, v) => _this.sound = v,
        'org.w3c.notifications.vibrate_$external_prop_getter' : (_this) => _this.vibrate,
        'org.w3c.notifications.vibrate_$external_prop_setter' : (_this, v) => _this.vibrate = v,
        'org.w3c.notifications.timestamp_$external_prop_getter' : (_this) => _this.timestamp,
        'org.w3c.notifications.timestamp_$external_prop_setter' : (_this, v) => _this.timestamp = v,
        'org.w3c.notifications.renotify_$external_prop_getter' : (_this) => _this.renotify,
        'org.w3c.notifications.renotify_$external_prop_setter' : (_this, v) => _this.renotify = v,
        'org.w3c.notifications.silent_$external_prop_getter' : (_this) => _this.silent,
        'org.w3c.notifications.silent_$external_prop_setter' : (_this, v) => _this.silent = v,
        'org.w3c.notifications.noscreen_$external_prop_getter' : (_this) => _this.noscreen,
        'org.w3c.notifications.noscreen_$external_prop_setter' : (_this, v) => _this.noscreen = v,
        'org.w3c.notifications.requireInteraction_$external_prop_getter' : (_this) => _this.requireInteraction,
        'org.w3c.notifications.requireInteraction_$external_prop_setter' : (_this, v) => _this.requireInteraction = v,
        'org.w3c.notifications.sticky_$external_prop_getter' : (_this) => _this.sticky,
        'org.w3c.notifications.sticky_$external_prop_setter' : (_this, v) => _this.sticky = v,
        'org.w3c.notifications.data_$external_prop_getter' : (_this) => _this.data,
        'org.w3c.notifications.data_$external_prop_setter' : (_this, v) => _this.data = v,
        'org.w3c.notifications.actions_$external_prop_getter' : (_this) => _this.actions,
        'org.w3c.notifications.actions_$external_prop_setter' : (_this, v) => _this.actions = v,
        'org.w3c.notifications.Notification_$external_fun' : (p0, p1, isDefault0) => new Notification(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.notifications.onclick_$external_prop_getter' : (_this) => _this.onclick,
        'org.w3c.notifications.onclick_$external_prop_setter' : (_this, v) => _this.onclick = v,
        'org.w3c.notifications.onerror_$external_prop_getter' : (_this) => _this.onerror,
        'org.w3c.notifications.onerror_$external_prop_setter' : (_this, v) => _this.onerror = v,
        'org.w3c.notifications.title_$external_prop_getter' : (_this) => _this.title,
        'org.w3c.notifications.dir_$external_prop_getter_1' : (_this) => _this.dir,
        'org.w3c.notifications.lang_$external_prop_getter_1' : (_this) => _this.lang,
        'org.w3c.notifications.body_$external_prop_getter_1' : (_this) => _this.body,
        'org.w3c.notifications.tag_$external_prop_getter_1' : (_this) => _this.tag,
        'org.w3c.notifications.image_$external_prop_getter_1' : (_this) => _this.image,
        'org.w3c.notifications.icon_$external_prop_getter_1' : (_this) => _this.icon,
        'org.w3c.notifications.badge_$external_prop_getter_1' : (_this) => _this.badge,
        'org.w3c.notifications.sound_$external_prop_getter_1' : (_this) => _this.sound,
        'org.w3c.notifications.vibrate_$external_prop_getter_1' : (_this) => _this.vibrate,
        'org.w3c.notifications.timestamp_$external_prop_getter_1' : (_this) => _this.timestamp,
        'org.w3c.notifications.renotify_$external_prop_getter_1' : (_this) => _this.renotify,
        'org.w3c.notifications.silent_$external_prop_getter_1' : (_this) => _this.silent,
        'org.w3c.notifications.noscreen_$external_prop_getter_1' : (_this) => _this.noscreen,
        'org.w3c.notifications.requireInteraction_$external_prop_getter_1' : (_this) => _this.requireInteraction,
        'org.w3c.notifications.sticky_$external_prop_getter_1' : (_this) => _this.sticky,
        'org.w3c.notifications.data_$external_prop_getter_1' : (_this) => _this.data,
        'org.w3c.notifications.actions_$external_prop_getter_1' : (_this) => _this.actions,
        'org.w3c.notifications.close_$external_fun' : (_this, ) => _this.close(),
        'org.w3c.notifications.Companion_$external_fun' : () => new Notification(),
        'org.w3c.notifications.permission_$external_prop_getter' : (_this) => _this.permission,
        'org.w3c.notifications.maxActions_$external_prop_getter' : (_this) => _this.maxActions,
        'org.w3c.notifications.requestPermission_$external_fun' : (_this, p0, isDefault0) => _this.requestPermission(isDefault0 ? undefined : p0, ),
        'org.w3c.notifications.Companion_$external_object_getInstance' : () => Notification,
        'org.w3c.notifications.Companion_$external_class_instanceof' : (x) => x instanceof Notification,
        'org.w3c.notifications.Companion_$external_class_get' : () => Notification,
        'org.w3c.notifications.Notification_$external_class_instanceof' : (x) => x instanceof Notification,
        'org.w3c.notifications.Notification_$external_class_get' : () => Notification,
        'org.w3c.notifications.tag_$external_prop_getter_2' : (_this) => _this.tag,
        'org.w3c.notifications.tag_$external_prop_setter_1' : (_this, v) => _this.tag = v,
        'org.w3c.notifications.Companion_$external_fun_1' : () => new ({})(),
        'org.w3c.notifications.Companion_$external_object_getInstance_1' : () => ({}),
        'org.w3c.notifications.Companion_$external_class_instanceof_1' : (x) => x instanceof ({}),
        'org.w3c.notifications.Companion_$external_class_get_1' : () => ({}),
        'org.w3c.notifications.action_$external_prop_getter' : (_this) => _this.action,
        'org.w3c.notifications.action_$external_prop_setter' : (_this, v) => _this.action = v,
        'org.w3c.notifications.title_$external_prop_getter_1' : (_this) => _this.title,
        'org.w3c.notifications.title_$external_prop_setter' : (_this, v) => _this.title = v,
        'org.w3c.notifications.icon_$external_prop_getter_2' : (_this) => _this.icon,
        'org.w3c.notifications.icon_$external_prop_setter_1' : (_this, v) => _this.icon = v,
        'org.w3c.notifications.Companion_$external_fun_2' : () => new ({})(),
        'org.w3c.notifications.Companion_$external_object_getInstance_2' : () => ({}),
        'org.w3c.notifications.Companion_$external_class_instanceof_2' : (x) => x instanceof ({}),
        'org.w3c.notifications.Companion_$external_class_get_2' : () => ({}),
        'org.w3c.performance.performance_$external_prop_getter' : (_this) => _this.performance,
        'org.w3c.performance.Performance_$external_fun' : () => new Performance(),
        'org.w3c.performance.timing_$external_prop_getter' : (_this) => _this.timing,
        'org.w3c.performance.navigation_$external_prop_getter' : (_this) => _this.navigation,
        'org.w3c.performance.now_$external_fun' : (_this, ) => _this.now(),
        'org.w3c.performance.Performance_$external_class_instanceof' : (x) => x instanceof Performance,
        'org.w3c.performance.Performance_$external_class_get' : () => Performance,
        'org.w3c.performance.PerformanceTiming_$external_fun' : () => new PerformanceTiming(),
        'org.w3c.performance.navigationStart_$external_prop_getter' : (_this) => _this.navigationStart,
        'org.w3c.performance.unloadEventStart_$external_prop_getter' : (_this) => _this.unloadEventStart,
        'org.w3c.performance.unloadEventEnd_$external_prop_getter' : (_this) => _this.unloadEventEnd,
        'org.w3c.performance.redirectStart_$external_prop_getter' : (_this) => _this.redirectStart,
        'org.w3c.performance.redirectEnd_$external_prop_getter' : (_this) => _this.redirectEnd,
        'org.w3c.performance.fetchStart_$external_prop_getter' : (_this) => _this.fetchStart,
        'org.w3c.performance.domainLookupStart_$external_prop_getter' : (_this) => _this.domainLookupStart,
        'org.w3c.performance.domainLookupEnd_$external_prop_getter' : (_this) => _this.domainLookupEnd,
        'org.w3c.performance.connectStart_$external_prop_getter' : (_this) => _this.connectStart,
        'org.w3c.performance.connectEnd_$external_prop_getter' : (_this) => _this.connectEnd,
        'org.w3c.performance.secureConnectionStart_$external_prop_getter' : (_this) => _this.secureConnectionStart,
        'org.w3c.performance.requestStart_$external_prop_getter' : (_this) => _this.requestStart,
        'org.w3c.performance.responseStart_$external_prop_getter' : (_this) => _this.responseStart,
        'org.w3c.performance.responseEnd_$external_prop_getter' : (_this) => _this.responseEnd,
        'org.w3c.performance.domLoading_$external_prop_getter' : (_this) => _this.domLoading,
        'org.w3c.performance.domInteractive_$external_prop_getter' : (_this) => _this.domInteractive,
        'org.w3c.performance.domContentLoadedEventStart_$external_prop_getter' : (_this) => _this.domContentLoadedEventStart,
        'org.w3c.performance.domContentLoadedEventEnd_$external_prop_getter' : (_this) => _this.domContentLoadedEventEnd,
        'org.w3c.performance.domComplete_$external_prop_getter' : (_this) => _this.domComplete,
        'org.w3c.performance.loadEventStart_$external_prop_getter' : (_this) => _this.loadEventStart,
        'org.w3c.performance.loadEventEnd_$external_prop_getter' : (_this) => _this.loadEventEnd,
        'org.w3c.performance.PerformanceTiming_$external_class_instanceof' : (x) => x instanceof PerformanceTiming,
        'org.w3c.performance.PerformanceTiming_$external_class_get' : () => PerformanceTiming,
        'org.w3c.performance.PerformanceNavigation_$external_fun' : () => new PerformanceNavigation(),
        'org.w3c.performance.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.performance.redirectCount_$external_prop_getter' : (_this) => _this.redirectCount,
        'org.w3c.performance.Companion_$external_fun' : () => new PerformanceNavigation(),
        'org.w3c.performance.TYPE_NAVIGATE_$external_prop_getter' : (_this) => _this.TYPE_NAVIGATE,
        'org.w3c.performance.TYPE_RELOAD_$external_prop_getter' : (_this) => _this.TYPE_RELOAD,
        'org.w3c.performance.TYPE_BACK_FORWARD_$external_prop_getter' : (_this) => _this.TYPE_BACK_FORWARD,
        'org.w3c.performance.TYPE_RESERVED_$external_prop_getter' : (_this) => _this.TYPE_RESERVED,
        'org.w3c.performance.Companion_$external_object_getInstance' : () => PerformanceNavigation,
        'org.w3c.performance.Companion_$external_class_instanceof' : (x) => x instanceof PerformanceNavigation,
        'org.w3c.performance.Companion_$external_class_get' : () => PerformanceNavigation,
        'org.w3c.performance.PerformanceNavigation_$external_class_instanceof' : (x) => x instanceof PerformanceNavigation,
        'org.w3c.performance.PerformanceNavigation_$external_class_get' : () => PerformanceNavigation,
        'org.w3c.workers.CacheStorage_$external_fun' : () => new CacheStorage(),
        'org.w3c.workers.match_$external_fun' : (_this, p0, p1, isDefault0) => _this.match(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.match_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.match(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.has_$external_fun' : (_this, p0) => _this.has(p0),
        'org.w3c.workers.open_$external_fun' : (_this, p0) => _this.open(p0),
        'org.w3c.workers.delete_$external_fun' : (_this, p0) => _this.delete(p0),
        'org.w3c.workers.keys_$external_fun' : (_this, ) => _this.keys(),
        'org.w3c.workers.CacheStorage_$external_class_instanceof' : (x) => x instanceof CacheStorage,
        'org.w3c.workers.CacheStorage_$external_class_get' : () => CacheStorage,
        'org.w3c.workers.ServiceWorkerContainer_$external_fun' : () => new ServiceWorkerContainer(),
        'org.w3c.workers.controller_$external_prop_getter' : (_this) => _this.controller,
        'org.w3c.workers.ready_$external_prop_getter' : (_this) => _this.ready,
        'org.w3c.workers.oncontrollerchange_$external_prop_getter' : (_this) => _this.oncontrollerchange,
        'org.w3c.workers.oncontrollerchange_$external_prop_setter' : (_this, v) => _this.oncontrollerchange = v,
        'org.w3c.workers.onmessage_$external_prop_getter' : (_this) => _this.onmessage,
        'org.w3c.workers.onmessage_$external_prop_setter' : (_this, v) => _this.onmessage = v,
        'org.w3c.workers.register_$external_fun' : (_this, p0, p1, isDefault0) => _this.register(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.getRegistration_$external_fun' : (_this, p0, isDefault0) => _this.getRegistration(isDefault0 ? undefined : p0, ),
        'org.w3c.workers.getRegistrations_$external_fun' : (_this, ) => _this.getRegistrations(),
        'org.w3c.workers.startMessages_$external_fun' : (_this, ) => _this.startMessages(),
        'org.w3c.workers.ServiceWorkerContainer_$external_class_instanceof' : (x) => x instanceof ServiceWorkerContainer,
        'org.w3c.workers.ServiceWorkerContainer_$external_class_get' : () => ServiceWorkerContainer,
        'org.w3c.workers.ignoreSearch_$external_prop_getter' : (_this) => _this.ignoreSearch,
        'org.w3c.workers.ignoreSearch_$external_prop_setter' : (_this, v) => _this.ignoreSearch = v,
        'org.w3c.workers.ignoreMethod_$external_prop_getter' : (_this) => _this.ignoreMethod,
        'org.w3c.workers.ignoreMethod_$external_prop_setter' : (_this, v) => _this.ignoreMethod = v,
        'org.w3c.workers.ignoreVary_$external_prop_getter' : (_this) => _this.ignoreVary,
        'org.w3c.workers.ignoreVary_$external_prop_setter' : (_this, v) => _this.ignoreVary = v,
        'org.w3c.workers.cacheName_$external_prop_getter' : (_this) => _this.cacheName,
        'org.w3c.workers.cacheName_$external_prop_setter' : (_this, v) => _this.cacheName = v,
        'org.w3c.workers.Cache_$external_fun' : () => new Cache(),
        'org.w3c.workers.match_$external_fun_2' : (_this, p0, p1, isDefault0) => _this.match(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.match_$external_fun_3' : (_this, p0, p1, isDefault0) => _this.match(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.matchAll_$external_fun' : (_this, p0, p1, isDefault0) => _this.matchAll(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.matchAll_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.matchAll(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.matchAll_$external_fun_2' : (_this, ) => _this.matchAll(),
        'org.w3c.workers.add_$external_fun' : (_this, p0) => _this.add(p0),
        'org.w3c.workers.add_$external_fun_1' : (_this, p0) => _this.add(p0),
        'org.w3c.workers.addAll_$external_fun' : (_this, p0) => _this.addAll(p0),
        'org.w3c.workers.put_$external_fun' : (_this, p0, p1) => _this.put(p0, p1),
        'org.w3c.workers.put_$external_fun_1' : (_this, p0, p1) => _this.put(p0, p1),
        'org.w3c.workers.delete_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.delete(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.delete_$external_fun_2' : (_this, p0, p1, isDefault0) => _this.delete(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.keys_$external_fun_1' : (_this, p0, p1, isDefault0) => _this.keys(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.keys_$external_fun_2' : (_this, p0, p1, isDefault0) => _this.keys(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.keys_$external_fun_3' : (_this, ) => _this.keys(),
        'org.w3c.workers.Cache_$external_class_instanceof' : (x) => x instanceof Cache,
        'org.w3c.workers.Cache_$external_class_get' : () => Cache,
        'org.w3c.workers.ServiceWorker_$external_fun' : () => new ServiceWorker(),
        'org.w3c.workers.scriptURL_$external_prop_getter' : (_this) => _this.scriptURL,
        'org.w3c.workers.state_$external_prop_getter' : (_this) => _this.state,
        'org.w3c.workers.onstatechange_$external_prop_getter' : (_this) => _this.onstatechange,
        'org.w3c.workers.onstatechange_$external_prop_setter' : (_this, v) => _this.onstatechange = v,
        'org.w3c.workers.postMessage_$external_fun' : (_this, p0, p1, isDefault0) => _this.postMessage(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.ServiceWorker_$external_class_instanceof' : (x) => x instanceof ServiceWorker,
        'org.w3c.workers.ServiceWorker_$external_class_get' : () => ServiceWorker,
        'org.w3c.workers.ServiceWorkerRegistration_$external_fun' : () => new ServiceWorkerRegistration(),
        'org.w3c.workers.installing_$external_prop_getter' : (_this) => _this.installing,
        'org.w3c.workers.waiting_$external_prop_getter' : (_this) => _this.waiting,
        'org.w3c.workers.active_$external_prop_getter' : (_this) => _this.active,
        'org.w3c.workers.scope_$external_prop_getter' : (_this) => _this.scope,
        'org.w3c.workers.onupdatefound_$external_prop_getter' : (_this) => _this.onupdatefound,
        'org.w3c.workers.onupdatefound_$external_prop_setter' : (_this, v) => _this.onupdatefound = v,
        'org.w3c.workers.APISpace_$external_prop_getter' : (_this) => _this.APISpace,
        'org.w3c.workers.update_$external_fun' : (_this, ) => _this.update(),
        'org.w3c.workers.unregister_$external_fun' : (_this, ) => _this.unregister(),
        'org.w3c.workers.showNotification_$external_fun' : (_this, p0, p1, isDefault0) => _this.showNotification(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.workers.getNotifications_$external_fun' : (_this, p0, isDefault0) => _this.getNotifications(isDefault0 ? undefined : p0, ),
        'org.w3c.workers.methodName_$external_fun' : (_this, ) => _this.methodName(),
        'org.w3c.workers.ServiceWorkerRegistration_$external_class_instanceof' : (x) => x instanceof ServiceWorkerRegistration,
        'org.w3c.workers.ServiceWorkerRegistration_$external_class_get' : () => ServiceWorkerRegistration,
        'org.w3c.workers.scope_$external_prop_getter_1' : (_this) => _this.scope,
        'org.w3c.workers.scope_$external_prop_setter' : (_this, v) => _this.scope = v,
        'org.w3c.workers.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.workers.type_$external_prop_setter' : (_this, v) => _this.type = v,
        'org.w3c.workers.Companion_$external_fun' : () => new ({})(),
        'org.w3c.workers.Companion_$external_object_getInstance' : () => ({}),
        'org.w3c.workers.Companion_$external_class_instanceof' : (x) => x instanceof ({}),
        'org.w3c.workers.Companion_$external_class_get' : () => ({}),
        'org.w3c.xhr.ProgressEvent_$external_fun' : (p0, p1, isDefault0) => new ProgressEvent(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.xhr.lengthComputable_$external_prop_getter' : (_this) => _this.lengthComputable,
        'org.w3c.xhr.loaded_$external_prop_getter' : (_this) => _this.loaded,
        'org.w3c.xhr.total_$external_prop_getter' : (_this) => _this.total,
        'org.w3c.xhr.Companion_$external_fun' : () => new ProgressEvent(),
        'org.w3c.xhr.NONE_$external_prop_getter' : (_this) => _this.NONE,
        'org.w3c.xhr.CAPTURING_PHASE_$external_prop_getter' : (_this) => _this.CAPTURING_PHASE,
        'org.w3c.xhr.AT_TARGET_$external_prop_getter' : (_this) => _this.AT_TARGET,
        'org.w3c.xhr.BUBBLING_PHASE_$external_prop_getter' : (_this) => _this.BUBBLING_PHASE,
        'org.w3c.xhr.Companion_$external_object_getInstance' : () => ProgressEvent,
        'org.w3c.xhr.Companion_$external_class_instanceof' : (x) => x instanceof ProgressEvent,
        'org.w3c.xhr.Companion_$external_class_get' : () => ProgressEvent,
        'org.w3c.xhr.ProgressEvent_$external_class_instanceof' : (x) => x instanceof ProgressEvent,
        'org.w3c.xhr.ProgressEvent_$external_class_get' : () => ProgressEvent,
        'org.w3c.xhr.FormData_$external_fun' : (p0, isDefault0) => new FormData(isDefault0 ? undefined : p0, ),
        'org.w3c.xhr.append_$external_fun' : (_this, p0, p1) => _this.append(p0, p1),
        'org.w3c.xhr.append_$external_fun_1' : (_this, p0, p1, p2, isDefault0) => _this.append(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.xhr.delete_$external_fun' : (_this, p0) => _this.delete(p0),
        'org.w3c.xhr.get_$external_fun' : (_this, p0) => _this.get(p0),
        'org.w3c.xhr.getAll_$external_fun' : (_this, p0) => _this.getAll(p0),
        'org.w3c.xhr.has_$external_fun' : (_this, p0) => _this.has(p0),
        'org.w3c.xhr.set_$external_fun' : (_this, p0, p1) => _this.set(p0, p1),
        'org.w3c.xhr.set_$external_fun_1' : (_this, p0, p1, p2, isDefault0) => _this.set(p0, p1, isDefault0 ? undefined : p2, ),
        'org.w3c.xhr.FormData_$external_class_instanceof' : (x) => x instanceof FormData,
        'org.w3c.xhr.FormData_$external_class_get' : () => FormData,
        'org.w3c.xhr.lengthComputable_$external_prop_getter_1' : (_this) => _this.lengthComputable,
        'org.w3c.xhr.lengthComputable_$external_prop_setter' : (_this, v) => _this.lengthComputable = v,
        'org.w3c.xhr.loaded_$external_prop_getter_1' : (_this) => _this.loaded,
        'org.w3c.xhr.loaded_$external_prop_setter' : (_this, v) => _this.loaded = v,
        'org.w3c.xhr.total_$external_prop_getter_1' : (_this) => _this.total,
        'org.w3c.xhr.total_$external_prop_setter' : (_this, v) => _this.total = v,
        'org.jetbrains.skiko.w3c.language_$external_prop_getter' : (_this) => _this.language,
        'org.jetbrains.skiko.w3c.clipboard_$external_prop_getter' : (_this) => _this.clipboard,
        'org.jetbrains.skiko.w3c.userAgent_$external_prop_getter' : (_this) => _this.userAgent,
        'org.jetbrains.skiko.w3c.window_$external_fun' : () => new window(),
        'org.jetbrains.skiko.w3c.devicePixelRatio_$external_prop_getter' : (_this) => _this.devicePixelRatio,
        'org.jetbrains.skiko.w3c.navigator_$external_prop_getter' : (_this) => _this.navigator,
        'org.jetbrains.skiko.w3c.performance_$external_prop_getter' : (_this) => _this.performance,
        'org.jetbrains.skiko.w3c.requestAnimationFrame_$external_fun' : (_this, p0) => _this.requestAnimationFrame(p0),
        'org.jetbrains.skiko.w3c.open_$external_fun' : (_this, p0, p1) => _this.open(p0, p1),
        'org.jetbrains.skiko.w3c.window_$external_object_getInstance' : () => window,
        'org.jetbrains.skiko.w3c.window_$external_class_instanceof' : (x) => x instanceof window,
        'org.jetbrains.skiko.w3c.window_$external_class_get' : () => window,
        'org.jetbrains.skiko.w3c.writeText_$external_fun' : (_this, p0) => _this.writeText(p0),
        'org.jetbrains.skiko.w3c.now_$external_fun' : (_this, ) => _this.now(),
        'org.jetbrains.skiko.w3c.HTMLCanvasElement_$external_fun' : () => new HTMLCanvasElement(),
        'org.jetbrains.skiko.w3c.width_$external_prop_getter' : (_this) => _this.width,
        'org.jetbrains.skiko.w3c.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.jetbrains.skiko.w3c.height_$external_prop_getter' : (_this) => _this.height,
        'org.jetbrains.skiko.w3c.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.jetbrains.skiko.w3c.style_$external_prop_getter' : (_this) => _this.style,
        'org.jetbrains.skiko.w3c.HTMLCanvasElement_$external_class_instanceof' : (x) => x instanceof HTMLCanvasElement,
        'org.jetbrains.skiko.w3c.HTMLCanvasElement_$external_class_get' : () => HTMLCanvasElement,
        'org.jetbrains.skiko.w3c.CSSStyleDeclaration_$external_fun' : () => new CSSStyleDeclaration(),
        'org.jetbrains.skiko.w3c.width_$external_prop_getter_1' : (_this) => _this.width,
        'org.jetbrains.skiko.w3c.width_$external_prop_setter_1' : (_this, v) => _this.width = v,
        'org.jetbrains.skiko.w3c.height_$external_prop_getter_1' : (_this) => _this.height,
        'org.jetbrains.skiko.w3c.height_$external_prop_setter_1' : (_this, v) => _this.height = v,
        'org.jetbrains.skiko.w3c.CSSStyleDeclaration_$external_class_instanceof' : (x) => x instanceof CSSStyleDeclaration,
        'org.jetbrains.skiko.w3c.CSSStyleDeclaration_$external_class_get' : () => CSSStyleDeclaration,
        'org.jetbrains.skiko.wasm.alpha_$external_prop_getter' : (_this) => _this.alpha,
        'org.jetbrains.skiko.wasm.depth_$external_prop_getter' : (_this) => _this.depth,
        'org.jetbrains.skiko.wasm.stencil_$external_prop_getter' : (_this) => _this.stencil,
        'org.jetbrains.skiko.wasm.antialias_$external_prop_getter' : (_this) => _this.antialias,
        'org.jetbrains.skiko.wasm.premultipliedAlpha_$external_prop_getter' : (_this) => _this.premultipliedAlpha,
        'org.jetbrains.skiko.wasm.preserveDrawingBuffer_$external_prop_getter' : (_this) => _this.preserveDrawingBuffer,
        'org.jetbrains.skiko.wasm.preferLowPowerToHighPerformance_$external_prop_getter' : (_this) => _this.preferLowPowerToHighPerformance,
        'org.jetbrains.skiko.wasm.failIfMajorPerformanceCaveat_$external_prop_getter' : (_this) => _this.failIfMajorPerformanceCaveat,
        'org.jetbrains.skiko.wasm.enableExtensionsByDefault_$external_prop_getter' : (_this) => _this.enableExtensionsByDefault,
        'org.jetbrains.skiko.wasm.explicitSwapControl_$external_prop_getter' : (_this) => _this.explicitSwapControl,
        'org.jetbrains.skiko.wasm.renderViaOffscreenBackBuffer_$external_prop_getter' : (_this) => _this.renderViaOffscreenBackBuffer,
        'org.jetbrains.skiko.wasm.majorVersion_$external_prop_getter' : (_this) => _this.majorVersion,
        'org.jetbrains.skia.impl.FinalizationRegistry_$external_fun' : (p0) => new FinalizationRegistry(p0),
        'org.jetbrains.skia.impl.register_$external_fun' : (_this, p0, p1) => _this.register(p0, p1),
        'org.jetbrains.skia.impl.unregister_$external_fun' : (_this, p0) => _this.unregister(p0),
        'org.jetbrains.skia.impl.FinalizationRegistry_$external_class_instanceof' : (x) => x instanceof FinalizationRegistry,
        'org.jetbrains.skia.impl.FinalizationRegistry_$external_class_get' : () => FinalizationRegistry,
        'org.jetbrains.skia.impl.crateCallbackObj' : () => { return {} },
        'org.jetbrains.skia.impl.value_$external_prop_getter' : (_this) => _this.value,
        'org.jetbrains.skia.impl.value_$external_prop_setter' : (_this, v) => _this.value = v,
        'org.jetbrains.skia.impl.value_$external_prop_getter_1' : (_this) => _this.value,
        'org.jetbrains.skia.impl.value_$external_prop_setter_1' : (_this, v) => _this.value = v,
        'org.jetbrains.skia.impl.value_$external_prop_getter_2' : (_this) => _this.value,
        'org.jetbrains.skia.impl.value_$external_prop_setter_2' : (_this, v) => _this.value = v,
        'org.jetbrains.skia.impl.value_$external_prop_getter_3' : (_this) => _this.value,
        'org.jetbrains.skia.impl.value_$external_prop_setter_3' : (_this, v) => _this.value = v,
        'org.jetbrains.skia.impl._registerCallback_$external_fun' : (p0, p1, p2) => _ref_Li9za2lrby5tanM_._registerCallback(p0, p1, p2),
        'org.jetbrains.skia.impl._createLocalCallbackScope_$external_fun' : () => _ref_Li9za2lrby5tanM_._createLocalCallbackScope(),
        'org.jetbrains.skia.impl._releaseLocalCallbackScope_$external_fun' : () => _ref_Li9za2lrby5tanM_._releaseLocalCallbackScope(),
        'org.jetbrains.skiko.getNavigatorInfo' : () => navigator.userAgentData ? navigator.userAgentData.platform : navigator.platform,
        'org.jetbrains.skiko.wasm.GL_$external_fun' : () => new _ref_Li9za2lrby5tanM_.GL(),
        'org.jetbrains.skiko.wasm.createContext_$external_fun' : (_this, p0, p1) => _this.createContext(p0, p1),
        'org.jetbrains.skiko.wasm.makeContextCurrent_$external_fun' : (_this, p0) => _this.makeContextCurrent(p0),
        'org.jetbrains.skiko.wasm.GL_$external_object_getInstance' : () => _ref_Li9za2lrby5tanM_.GL,
        'org.jetbrains.skiko.wasm.GL_$external_class_instanceof' : (x) => x instanceof _ref_Li9za2lrby5tanM_.GL,
        'org.jetbrains.skiko.wasm.GL_$external_class_get' : () => _ref_Li9za2lrby5tanM_.GL,
        'org.jetbrains.skiko.wasm.createContext_$external_fun_1' : (_this, p0, p1) => _this.createContext(p0, p1),
        'org.jetbrains.skiko.wasm.makeContextCurrent_$external_fun_1' : (_this, p0) => _this.makeContextCurrent(p0),
        'org.jetbrains.skiko.wasm.createDefaultContextAttributes' : () => {
            return {
                alpha: 1,
                depth: 1,
                stencil: 8,
                antialias: 0,
                premultipliedAlpha: 1,
                preserveDrawingBuffer: 0,
                preferLowPowerToHighPerformance: 0,
                failIfMajorPerformanceCaveat: 0,
                enableExtensionsByDefault: 1,
                explicitSwapControl: 0,
                renderViaOffscreenBackBuffer: 0,
                majorVersion: 2,
            }
        }
        ,
        'androidx.compose.ui.text.intl.getUserPreferredLanguagesAsArray' : () => window.navigator.languages,
        'androidx.compose.ui.text.intl.parseLanguageTagToIntlLocale' : (languageTag) => new Intl.Locale(languageTag),
        'androidx.compose.ui.text.intl.PlatformLocale_$external_fun' : () => new PlatformLocale(),
        'androidx.compose.ui.text.intl._language_$external_prop_getter' : (_this) => _this.language,
        'androidx.compose.ui.text.intl._script_$external_prop_getter' : (_this) => _this.script,
        'androidx.compose.ui.text.intl._region_$external_prop_getter' : (_this) => _this.region,
        'androidx.compose.ui.text.intl._baseName_$external_prop_getter' : (_this) => _this.baseName,
        'androidx.compose.ui.text.intl.PlatformLocale_$external_class_instanceof' : (x) => x instanceof PlatformLocale,
        'androidx.compose.ui.text.intl.PlatformLocale_$external_class_get' : () => PlatformLocale,
        'androidx.compose.ui.text.platform.toLocaleUpperCase' : (text, locale) => text.toLocaleUpperCase(locale),
        'androidx.compose.ui.text.platform.toLocaleLowerCase' : (text, locale) => text.toLocaleLowerCase(locale),
        'androidx.compose.ui.window.isMatchMediaSupported' : () => window.matchMedia != undefined,
        'androidx.compose.ui.platform.inputType_$external_prop_getter' : (_this) => _this.inputType,
        'androidx.compose.ui.platform.data_$external_prop_getter' : (_this) => _this.data,
        'androidx.compose.ui.platform.keyCode_$external_prop_getter' : (_this) => _this.keyCode,
        'androidx.compose.ui.platform.keyCode_$external_prop_setter' : (_this, v) => _this.keyCode = v,
        'androidx.compose.ui.window.ExtendedTouchEvent_$external_fun' : () => new ExtendedTouchEvent(),
        'androidx.compose.ui.window.force_$external_prop_getter' : (_this) => _this.force,
        'androidx.compose.ui.window.ExtendedTouchEvent_$external_class_instanceof' : (x) => x instanceof ExtendedTouchEvent,
        'androidx.compose.ui.window.ExtendedTouchEvent_$external_class_get' : () => ExtendedTouchEvent,
        'org.jetbrains.compose.resources.Intl_$external_fun' : () => new Intl(),
        'org.jetbrains.compose.resources.Locale_$external_fun' : (p0) => new Intl.Locale(p0),
        'org.jetbrains.compose.resources.language_$external_prop_getter' : (_this) => _this.language,
        'org.jetbrains.compose.resources.region_$external_prop_getter' : (_this) => _this.region,
        'org.jetbrains.compose.resources.Locale_$external_class_instanceof' : (x) => x instanceof Intl.Locale,
        'org.jetbrains.compose.resources.Locale_$external_class_get' : () => Intl.Locale,
        'org.jetbrains.compose.resources.Intl_$external_class_instanceof' : (x) => x instanceof Intl,
        'org.jetbrains.compose.resources.Intl_$external_class_get' : () => Intl,
        'org.jetbrains.compose.resources.jsExportBlobAsArrayBuffer' : (blob) => blob.arrayBuffer(),
        'org.jetbrains.compose.resources.jsExportInt8ArrayToWasm' :  (src, size, dstAddr) => {
                const mem8 = new Int8Array(wasmExports.memory.buffer, dstAddr, size);
                mem8.set(src);
            }
        
    }
    
    // Placed here to give access to it from externals (js_code)
    let wasmInstance;
    let require; 
    let wasmExports;

    const isNodeJs = (typeof process !== 'undefined') && (process.release.name === 'node');
    const isDeno = !isNodeJs && (typeof Deno !== 'undefined')
    const isStandaloneJsVM =
        !isDeno && !isNodeJs && (
            typeof d8 !== 'undefined' // V8
            || typeof inIon !== 'undefined' // SpiderMonkey
            || typeof jscOptions !== 'undefined' // JavaScriptCore
        );
    const isBrowser = !isNodeJs && !isDeno && !isStandaloneJsVM && (typeof window !== 'undefined' || typeof self !== 'undefined');
    
    if (!isNodeJs && !isDeno && !isStandaloneJsVM && !isBrowser) {
      throw "Supported JS engine not detected";
    }
    
    const wasmFilePath = './composeApp.wasm';
    const importObject = {
        js_code,
        intrinsics: {
            
        },
        './skiko.mjs': imports['./skiko.mjs'],

    };
    
    try {
      if (isNodeJs) {
        const module = await import(/* webpackIgnore: true */'node:module');
        const importMeta = import.meta;
        require = module.default.createRequire(importMeta.url);
        const fs = require('fs');
        const url = require('url');
        const filepath = import.meta.resolve(wasmFilePath);
        const wasmBuffer = fs.readFileSync(url.fileURLToPath(filepath));
        const wasmModule = new WebAssembly.Module(wasmBuffer);
        wasmInstance = new WebAssembly.Instance(wasmModule, importObject);
      }
      
      if (isDeno) {
        const path = await import(/* webpackIgnore: true */'https://deno.land/std/path/mod.ts');
        const binary = Deno.readFileSync(path.fromFileUrl(import.meta.resolve(wasmFilePath)));
        const module = await WebAssembly.compile(binary);
        wasmInstance = await WebAssembly.instantiate(module, importObject);
      }
      
      if (isStandaloneJsVM) {
        const wasmBuffer = read(wasmFilePath, 'binary');
        const wasmModule = new WebAssembly.Module(wasmBuffer);
        wasmInstance = new WebAssembly.Instance(wasmModule, importObject);
      }
      
      if (isBrowser) {
        wasmInstance = (await WebAssembly.instantiateStreaming(fetch(new URL('./composeApp.wasm',import.meta.url).href), importObject)).instance;
      }
    } catch (e) {
      if (e instanceof WebAssembly.CompileError) {
        let text = `Please make sure that your runtime environment supports the latest version of Wasm GC and Exception-Handling proposals.
For more information, see https://kotl.in/wasm-help
`;
        if (isBrowser) {
          console.error(text);
        } else {
          const t = "\n" + text;
          if (typeof console !== "undefined" && console.log !== void 0) 
            console.log(t);
          else 
            print(t);
        }
      }
      throw e;
    }
    
    wasmExports = wasmInstance.exports;
    if (runInitializer) {
        wasmExports._initialize();
    }

    return { instance: wasmInstance,  exports: wasmExports };
}
